import React, { useEffect, useState } from 'react';
import $ from 'jquery';
import './css/deliverydetails.css';
import { useNavigate, useLocation } from 'react-router-dom';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

const DeliveryHome = () => {
    return (
        <>
        <div class="container">
            <div class="row">
                <h1>In Progress</h1>
            </div>
        </div>        
        </>
    )
}

export default DeliveryHome;