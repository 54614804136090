import React, { useEffect, useState, useMemo } from 'react';
import './report.css';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../../utils/Common');

export default function BankReport() {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);

  useEffect(() => {
    getReportData();
  }, []);

  const getReportData = async () => {
    var parameters = {
      'seller_id': localStorage.getItem('sellerId'),
      'report_name': 'bank_reco_reports'
    };
    let response = await common.apiRequest('post', '/reports/get-reports', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    
    // Set column definitions
    const cols = [
      { 
        field: 'slNo', 
        headerName: 'Sl No.', 
        valueGetter: (params) => params.node.rowIndex + 1,
        checkboxSelection: true,
        headerCheckboxSelection: true
      },
      ...data.keys.map(key => ({
        field: key,
        headerName: key.replace(/_/g, ' ').replace(/\b\w/g, l => l.toUpperCase()),
        filter: key === 'price' ? 'agNumberColumnFilter' : 'agTextColumnFilter',
        editable: true
      }))
    ];
    setColumnDefs(cols);

    // Set row data
    setRowData(data.reportData);
  }

  const { CSVDownloader } = useCSVDownloader();

  const exportPDF = () => {
    const doc = new jsPDF('landscape', 'pt', 'a4');
    doc.text("Bank Report", 40, 40);
    
    const columns = columnDefs.map(col => ({ header: col.headerName, dataKey: col.field }));
    
    doc.autoTable({
      columns: columns,
      body: rowData,
      startY: 50,
    });

    doc.save("bank_report.pdf");
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    editable: true,
  }), []);

  return (
    <>
      <div className="row report-margin">
        <div className='col-md-10'>
          <h3>Bank Reports</h3>
        </div>
        <CSVDownloader className="csvDownload"
          filename={'Bank report'}
          data={rowData}
        >
          Download CSV
        </CSVDownloader>
        <button className="btn csvDownload" onClick={exportPDF}>Download PDF</button>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className="ag-theme-quartz" style={{height: 600, width: '100%'}}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50]}
            />
          </div>
        </div>
      </div>
    </>
  )
}