import React, { useState,useEffect} from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './service.css'
import Table from 'react-bootstrap/Table';
import $ from 'jquery';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');


function ServiceView() {
    const [serviceCategories, setServiceCategories] = useState([]);
    const navigate = useNavigate();

    const fetchServiceCategories = async () => {
        const data = {
          action: "read",
          source: "web"
        }
        try {
          let response = await common.apiRequest('post', '/admin/crud-seller-service-category' , data);
          if (response.data.code == "success") {
            setServiceCategories(response.data.data);
          } else {
            common.alert(response.data.error, 'error');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }

    const searchService = () => {
        let searchString = $('#service_search').val().trim();
        if (searchString.length == 0) {
            common.alert("Enter Something To Search", 'error');
            return;
        }
        navigate("/service/0", {state: searchString});
    }

    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            searchService();
        }
    }

    useEffect(() => {
        fetchServiceCategories();   
    }, []);

    return (
       <React.Fragment>
           <div className="container-fluid px-0">
                <div className="col-12 servicep1">
                        <div className="col-md-12 text-box">
                            <h1>Home Services</h1>
                            <div className="form-inline justify-content-center">
                                <input type="text" className="form-control" size="70" id="service_search" placeholder="Search For Services" onKeyDown={handleKeyDown}/>
                                    <div className="input-group-append">
                                        <button type="button" className="btn btn-success" onClick={() => searchService()}>
                                            <i className="fas fa-search"></i>
                                        </button>
                                    </div>
                            </div>
                        </div>  
                </div>

     <div className="container">
        <div className="d-flex justify-content-center">
            <div className="card homescard1 shadow p-3 mb-5 bg-white rounded">
                <div className="card-body">

                    <div className="row">
                        {
                            serviceCategories.length > 0 ?
                            serviceCategories.map((category) => {
                                return (
                                    <>
                                        <div className="grid_item">
                                            <Link to={`/service/${category.id}`} className="btn">
                                                <div className="card cardhs1" style={{border: "none"}} >
                                                    <div className="card-body">
                                                        <div className="card-title">
                                                        <img src={envConfig.api_url + category.service_categories_image} className="img1"/>
                                                            <h5>{category.category_name}</h5>
                                                        </div>                           
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </>
                                );
                            })
                            : ''
                        }
                    </div>
                </div>
            </div> 
        </div>
     </div>

    </div>

       </React.Fragment>
    )
}
export default ServiceView;