import React, { useEffect, useState } from 'react';
import { Link, Navigate } from 'react-router-dom';
import './address.css';
const common = require('../../utils/Common');

function Address() {
  const [myAddresses, setMyAddresses] = useState([]);

  useEffect(() => {
    const fetchAddress = async () => {
      try {
        const data = {
          user_id: localStorage.getItem('userId'),
        };
        const response = await common.apiRequest('post', '/users/get-address', data);
        if (response.status === 200) {
          if (response.data.code === 'success') {
            setMyAddresses(response.data.data);
          } else {
            common.alert(response.data.error, 'error');
          }
        } else {
          common.alert('Failed to fetch addresses', 'error');
        }
      } catch (error) {
        console.error('Error fetching addresses:', error);
        common.alert('An error occurred while fetching addresses', 'error');
      }
    };

    fetchAddress();
  }, []);

  const deleteAddress = async (id) => {
    try {
      const data = {
        id: id,
      };
      const response = await common.apiRequest('post', '/users/delete-address', data);
      if (response.status === 200) {
        if (response.data.code === 'success') {
          common.alert(response.data.message);
          // Re-fetch addresses after deletion
          const updatedAddresses = myAddresses.filter((address) => address.id !== id);
          setMyAddresses(updatedAddresses);
        } else {
          common.alert(response.data.error, 'error');
        }
      } else {
        common.alert('Failed to delete address', 'error');
      }
    } catch (error) {
      console.error('Error deleting address:', error);
      common.alert('An error occurred while deleting the address', 'error');
    }
  };

  return (
    <React.Fragment>
      {/* bread crumb */}
      <div className="container-fluid">
        <div className="row">
          <ol className="col-12 breadcrumb bg-transparent">
            <li className="breadcrumb-item "><Link to="/home">Home</Link></li>
            <li className="breadcrumb-item "><Link to="/my-account">My Account</Link></li>
            <li className="breadcrumb-item ">My Addresses</li>
          </ol>  
        </div>
      </div>
      {/* bread crumb ends*/}

      <div className="container addselpage">
        <h2>My Addresses</h2>
        <div className="row offset-md-1">
          <div className="col-md-4">           
            <Link to="/my-account/add-edit-address" state={{ editAddress: "" }} className="btn bg-transparent m-0 p-0">
              <div className="card addselpage2">
                <div className="card-body mtcard">
                  <i className="fas fa-plus-square fa-3x"></i>
                  <h5 className="card-title">Add address</h5>
                </div>
              </div>
            </Link>
          </div>

          {myAddresses.map((address) => (
            <div key={address.id} className="col-md-4">
              <div className="card addselpage1">
                <div className="card-body">
                  <h5 className="card-title font-weight-bold">{address.address_type.toUpperCase()}</h5>
                  <p className="my-0 py-0">{address.address}</p>
                  <p className="my-0 py-0">{address.city}</p>
                  <p className="my-0 py-0">{address.state}</p>
                  <p className="my-0 py-0">{address.country}</p>
                  <p className="my-0 py-0">{address.pincode}</p>
                  <div className="card-footer bg-transparent">
                    <Link to="/my-account/add-edit-address" state={{ editAddress: address }} className="btn btn-outline-primary btn-sm"><i className="fas fa-pencil-alt"></i></Link>
                    <button onClick={() => deleteAddress(address.id)} className="btn btn-outline-danger btn-sm"><i className="fas fa-trash-alt"></i></button>
                  </div>
                </div>                
              </div>
            </div>
          ))}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Address;
