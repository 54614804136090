import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import {Button,CardBody} from 'reactstrap';
import { Navigate } from 'react-router-dom';

class Main extends Component {

	componentDidMount() {
		document.title = "TKG";
	}

	render() {
		if (localStorage.getItem('token')) {
			return <Navigate to='/home' />
		}
		const MyBackgroundImage ="assets/img/Back.jpeg";
		
		return (
			<div>
				<div className='mainPage' style={{backgroundImage: `url(${MyBackgroundImage})`}}>
	                <div className='buttons'>
	                    <div className='btn-1'>
	                        <Link to="/login">
	                        	<Button className='btnMainPage'><i className="main-page-icon fa fa-sign-in"></i>Login</Button>
	                    	</Link>
	                    </div>
	                    <div className='btn-2'>
	                        <Link to="/signup" >
	                        	<Button className='btnMainPage'><i className="main-page-icon fa fa-user-plus"></i>SignUp</Button>
	                    	</Link>
	                    </div>
	                  </div>                   
	            </div>
            </div>
		);
	}
}

export default Main;