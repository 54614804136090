import React from 'react';
import "./ssidebar.css";
import { Link } from "react-router-dom";
  
export default function Sidebar() {
  const[reportArrow,setReportsArrow]=React.useState(false)
  const[arrow,satArrow]=React.useState(false)
  const[arrow1,satArrow1]=React.useState(false)
  const[arrow2,satArrow2]=React.useState(false)

  return (
    <div className="sidebars">
      <div className="sidebarWrapper "  >
        <div className="sidebarMenu">
          <h3 className="sidebarTitle" ><u>Admin Panel</u></h3>
          <ul className="sidebarList">
            <Link to="/SHome" className="link">
            <li className="sidebarListItem active ">
              <img src="/assets/img/business-report.png" className="sidenavicons"/>
              Dashbord
            </li>
            </Link>
            
            <Link to="seller/products" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>Products</span>
              </li> 
            </Link>
            <Link to="seller/StockInfo" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>stock Info</span>
              </li> 
            </Link>
            <Link to="seller/offline-sale" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>Offline Sales</span>
              </li> 
            </Link>
            <Link to="seller/orders" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>Orders</span>
              </li> 
            </Link>
            <Link to="seller/add-services" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>Add services</span>
              </li>
            </Link>
            <Link to="seller/services" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>services</span>
              </li>
            </Link>
            <Link to="seller/delivery-users" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>delivery users</span>
              </li>
            </Link>
            <Link to="seller/Seller_Address" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>Seller address</span>
              </li>
            </Link>
            <Link to="seller/SellerInfo" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>Seller info</span>
              </li>
            </Link>
            <Link to="seller/SellerReplyFeedback" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>Seller feedback reply</span>
              </li>
            </Link>
            <Link to="seller/SellerReplyQuery" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>Seller Query reply</span>
              </li>
            </Link>
            <Link to="seller/SellerQr" className="link">
              <li className="sidebarListItem">
              <img src="/assets/img/product.png" className="sidenavicons"/>
                <span>Seller Qr</span>
              </li>
            </Link>
                      <Link>
                          <div className="drop">
                            {
                              arrow2? <span></span>:null
                            }
                            
                          </div>
                      </Link> 
                      <Link>
                        <div className="drop">
                          {
                            arrow2? <span></span>:null
                          }
                          
                        </div> 
                      </Link>
            
            
          </ul>
        </div>
        
        <div className="sidebarMenu">
          <h3 className="sidebarTitle"><u>Report</u></h3>
          <ul className="sidebarList">
           
            <li className="sidebarListItem">
            <img src="/assets/img/report.png" className="sidenavicons"/>
              <span onClick={() => setReportsArrow(!reportArrow)}>Reports<i class="fas fa-caret-down"></i></span>   
              
            </li>
 
            {
              reportArrow ? 
              <ul className='sidebarList'>
                <li className='sidebarListItem'>
                <Link to="seller/reports/tds-report">
                  <img src="/assets/img/report.png" className="sidenavicons"/>
                  <span>TDS Report</span>
                </Link> 
                </li>
                <li className='sidebarListItem'>
                <Link to="seller/reports/payment-report">
                  <img src="/assets/img/report.png" className="sidenavicons"/>
                  <span>Payment Report</span>
                </Link> 
                </li>
                <li className='sidebarListItem'>
                <Link to="seller/reports/bank-report">
                  <img src="/assets/img/report.png" className="sidenavicons"/>
                  <span>Bank Report</span>
                </Link> 
                </li>
                <li className='sidebarListItem'>
                <Link to="seller/reports/receipt-report">
                  <img src="/assets/img/report.png" className="sidenavicons"/>
                  <span>Receipt Report</span>
                </Link> 
                </li>
                <li className='sidebarListItem'>
                <Link to="seller/reports/vendor-services-details">
                  <img src="/assets/img/report.png" className="sidenavicons"/>
                  <span>Service Report</span>
                </Link> 
                </li>
                
                {/* <li className='sidebarListItem'>
                <Link to="seller/reports/vendor-profile">
                  <img src="/assets/img/report.png" className="sidenavicons"/>
                  <span>Vendor Profile</span>
                </Link> 
                </li> */}
                <li className='sidebarListItem'>
                <Link to="seller/reports/vendor-report">
                  <img src="/assets/img/report.png" className="sidenavicons"/>
                  <span>Vendor Report</span>
                </Link> 
                </li>
                {/* <li className='sidebarListItem'>
                <Link to="seller/reports/srrr-report">
                  <img src="/assets/img/report.png" className="sidenavicons"/>
                  <span>SRRR Report</span>
                </Link> 
                </li> */}
                <li className='sidebarListItem'>
                <Link to="seller/reports/logistic-report">
                  <img src="/assets/img/report.png" className="sidenavicons"/>
                  <span>Logistics Report</span>
                </Link> 
                </li>
              </ul>
              : ''
            }
          </ul>
        </div>
      </div>
    </div>
  );
}