import React from 'react';
const axios = require('axios');

const envConfig = require('../../config/envConfig');

function SellerQr() {
    const userId = localStorage.getItem('userId');
    const qrImageUrl = `${envConfig.api_url}/images/users/${userId}_seller_qr.jpeg`;

    const download = async (e) => {
        console.log(e.target.href);
        try {
          const response = await axios({
            url: e.target.href,
            responseType: 'arraybuffer'
          });
      
          const buffer = response.data;
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "image.png"); // or any other extension
          document.body.appendChild(link);
          link.click();
        } catch (err) {
          console.log(err);
        }
      };

    return (
           <div className="app">
                <div className="App">
      <a
        href={qrImageUrl}
        download
        onClick={e => download(e)}
      >
        <i className="fa fa-download" />
        download
      </a>
    </div>
            <h1>Here is your QR code</h1>
            
            <img 
                src={qrImageUrl}
                alt="User QR Code"
            />
            
            <a href={qrImageUrl} download={`${userId}_qr.png`} className='QRbutton'>
                Download
            </a>
            </div>
    );
}

export default SellerQr;
