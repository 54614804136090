import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './filterpage.css'
const common = require('../../../utils/Common');
const Filterpage = () => {
    const [products, setProducts] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                let parameters = {
                    "user_id": localStorage.getItem("userId")
                }
                const response = await common.apiRequest('post', '/products/get-products', []);
                const data = (response.data && response.data.data) ? response.data.data : {};
                setProducts(data.products || []);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);
    return (

        <div class="super_container">
            <div class="container-fluid product_section_container">
                <div class="row">
                    <div class="col product_section clearfix">



                        <div class="breadcrumbs d-flex flex-row align-items-center">
                            <ul>
                                <li><Link to="/home">Home</Link></li>
                                <li><Link to="index.html">Women</Link></li>
                            </ul>
                        </div>


                        <div class="sidebar_filter">
                            <div class="sidebar_section">
                                <div class="sidebar_title">
                                    <h5>Product Category</h5>
                                </div>
                                <ul class="sidebar_categories">
                                    <li><Link to="#">Men</Link></li>
                                    <li><Link to="#">Accessories</Link></li>
                                    <li><Link to="#">New Arrivals</Link></li>
                                    <li><Link to="#">Collection</Link></li>
                                    <li><Link to="#">Shop</Link></li>
                                </ul>
                            </div>
                            <div class="sidebar_section">
                                <div class="sidebar_title">
                                    <h5>Sizes</h5>
                                </div>
                                <ul class="checkboxes">
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>S</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>L</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>XL</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>XXL</span></li>
                                </ul>
                            </div>

                            <div class="sidebar_section">
                                <div class="sidebar_title">
                                    <h5>Color</h5>
                                </div>
                                <ul class="checkboxes">
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>Black</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>Pink</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>White</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>Blue</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>Orange</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>White</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>Blue</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>Orange</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>White</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>Blue</span></li>
                                    <li><i class="fa fa-square-o" aria-hidden="true"></i><span>Orange</span></li>
                                </ul>
                                <div class="show_more">
                                    <span><span>+</span>Show More</span>
                                </div>
                            </div>

                        </div>

                        <div class="main_content">


                            <div class="products_iso">
                                <div class="row">
                                    <div class="col">



                                        <>
                                            <div class="container-fluid bg-trasparent my-4 p-3" style={{ position: "relative", maxWidth: "1400px" }}>
                                                <div class="row row-cols-1 row-cols-xs-2 row-cols-sm-2 row-cols-lg-4 g-3">
                                                    {products.map((product) => {
                                                        return (
                                                            <div class="col hp mt-5">
                                                                <div class="card h-100 shadow-sm">
                                                                    <Link to={`/product/${product.id}/${product.product_items[0].id}`}>
                                                                        <img src={product.product_items[0].product_image} class="card-img-top" alt="product img" />
                                                                    </Link>
                                                                    <div class="label-top shadow-sm">

                                                                        <Link class="text-white" to={`/product/${product.id}/${product.product_items[0].id}`}>
                                                                            {product.name}
                                                                        </Link>
                                                                    </div>

                                                                    <div class="card-body">
                                                                        <div class="clearfix mb-3">
                                                                            <span class="float-start badge rounded-pill bg-warning">Rs. {product.product_items[0].price}</span>
                                                                        </div>
                                                                        <div class="d-grid gap-2 my-4">

                                                                            <a href="#" class="btn btn-warning bold-btn">add to cart</a>

                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })}
                                                </div>
                                            </div>
                                        </>


                                        <div class="pagination col-md-12 col-6">
                                            <button class="arrow" id="prevPage" disabled>← <span class="nav-text">PREV</span></button>
                                            <div class="pages">
                                                <div class="page-number activePage">1</div>
                                                <div class="page-number">2</div>
                                                <div class="page-number">3</div>
                                                <div class="page-number">4</div>
                                                <div class="page-number">5</div>
                                            </div>
                                            <button class="arrow" id="nextPage"><span class="nav-text">NEXT</span> →</button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Filterpage