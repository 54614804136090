import React, { useEffect, useState, useMemo } from 'react';
import jsPDF from "jspdf";
import "jspdf-autotable";
import { useCSVDownloader } from 'react-papaparse';
import { AgGridReact } from '@ag-grid-community/react';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const common = require('../../../utils/Common');

export default function TdsDetails() {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const { CSVDownloader } = useCSVDownloader();

  useEffect(() => {
    fetchTdsDetails();
  }, []);

 
  const fetchTdsDetails = async () => {
    let data = {
      'seller_id': localStorage.getItem('sellerId'),
      'action': 'read'
    };
    let response = await common.apiRequest('post', '/admin/crud-buyer-payment', data);
    if (response.status === 200) {
      if (response.data.code === 'success') {
        setRowData(response.data.data);
      } else {
        alert(response.data.error);
      }
    } else {
      alert(response.data.error);
    }
  };

  useEffect(() => {
    setColumnDefs([
      { headerName: 'Amount Of TDS', field: 'tds', sortable: true, filter: true },
      { headerName: 'Date Of Deduction', field: 'date_of_deduction', sortable: true, filter: true },
      { headerName: 'Rate Of Deduction', field: 'rate_of_deduction', sortable: true, filter: true },
      { headerName: 'Section', field: 'section', sortable: true, filter: true },
      { headerName: 'Challan No', field: 'challan_no', sortable: true, filter: true },
      { headerName: 'Challan Date', field: 'challan_date', sortable: true, filter: true },
      { headerName: 'BSR Code', field: 'bsr_code', sortable: true, filter: true },
    ]);
  }, []);

  const exportPDF = () => {
    const doc = new jsPDF('landscape', 'pt', 'a4');
    doc.text("TDS Details Report", 40, 40);

    const columns = columnDefs.map(col => ({ header: col.headerName, dataKey: col.field }));

    doc.autoTable({
      columns: columns,
      body: rowData,
      startY: 50,
    });

    doc.save("tds_details_report.pdf");
  };

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: 'agTextColumnFilter',
    floatingFilter: true,
    resizable: true,
    editable: false,
  }), []);

  return (
    <>
      <div className="row report-margin">
        <div className='col-md-6'>
          <h3>TDS Details</h3>
        </div>
        <div className='col-md-6 d-flex justify-content-end'>
          <CSVDownloader className="btn csvDownload" filename={'Tds Details Report'} data={rowData}>
            Download CSV
          </CSVDownloader>
          <button className="btn csvDownload ml-2" onClick={exportPDF}>Download PDF</button>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
            <AgGridReact
              rowData={rowData}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowSelection="multiple"
              suppressRowClickSelection={true}
              pagination={true}
              paginationPageSize={10}
              paginationPageSizeSelector={[10, 25, 50]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
