// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumbs
{
	width: 100%;
	height: 60px;
	border-bottom: solid 1px #ebebeb;
	/* margin-bottom: 79px; */
	/* margin-top: 7%; */
}
.breadcrumbs ul li
{
	display: inline-block;
	margin-right: 17px;
}
.breadcrumbs ul li.active a
{
	color: #b9b4c7;
}
.breadcrumbs ul li i
{
	margin-right: 17px;
}
.breadcrumbs ul li a
{
	color: #51545f;
}


.size-select{
	width: 60%;
	background-color: transparent;
	border-radius: 10px;
}

.btn1{
	width: 100%;
	padding: 1rem 2rem  1rem 2rem;
	/* padding-bottom: 0.5rem; */
	border-width: 0;
	border-color: none;
	border-radius: 0.8rem;
	margin-left: 20px;
	text-decoration: none !important;
	white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    max-width: 100%;
}

.star{
	margin-right: 15px !important;
}

.btn-check{
	width: 100%;
	padding: .5rem 2rem  .5rem 2rem;
	/* padding-bottom: 0.5rem; */
	border-width: 0;
	border-color: none;
	border-radius: 0.8rem;
	/* margin-left: 20px; */
	text-decoration: none !important;
}

.pan-image{
	margin: 10px 20px 10px 20px;
	height: 200px;
	width: 300px;
}`, "",{"version":3,"sources":["webpack://./src/components/mainproductview/mainproduct.css"],"names":[],"mappings":"AAAA;;CAEC,WAAW;CACX,YAAY;CACZ,gCAAgC;CAChC,yBAAyB;CACzB,oBAAoB;AACrB;AACA;;CAEC,qBAAqB;CACrB,kBAAkB;AACnB;AACA;;CAEC,cAAc;AACf;AACA;;CAEC,kBAAkB;AACnB;AACA;;CAEC,cAAc;AACf;;;AAGA;CACC,UAAU;CACV,6BAA6B;CAC7B,mBAAmB;AACpB;;AAEA;CACC,WAAW;CACX,6BAA6B;CAC7B,4BAA4B;CAC5B,eAAe;CACf,kBAAkB;CAClB,qBAAqB;CACrB,iBAAiB;CACjB,gCAAgC;CAChC,mBAAmB;IAChB,gBAAgB;IAChB,uBAAuB;IACvB,qBAAqB;IACrB,eAAe;AACnB;;AAEA;CACC,6BAA6B;AAC9B;;AAEA;CACC,WAAW;CACX,+BAA+B;CAC/B,4BAA4B;CAC5B,eAAe;CACf,kBAAkB;CAClB,qBAAqB;CACrB,uBAAuB;CACvB,gCAAgC;AACjC;;AAEA;CACC,2BAA2B;CAC3B,aAAa;CACb,YAAY;AACb","sourcesContent":[".breadcrumbs\n{\n\twidth: 100%;\n\theight: 60px;\n\tborder-bottom: solid 1px #ebebeb;\n\t/* margin-bottom: 79px; */\n\t/* margin-top: 7%; */\n}\n.breadcrumbs ul li\n{\n\tdisplay: inline-block;\n\tmargin-right: 17px;\n}\n.breadcrumbs ul li.active a\n{\n\tcolor: #b9b4c7;\n}\n.breadcrumbs ul li i\n{\n\tmargin-right: 17px;\n}\n.breadcrumbs ul li a\n{\n\tcolor: #51545f;\n}\n\n\n.size-select{\n\twidth: 60%;\n\tbackground-color: transparent;\n\tborder-radius: 10px;\n}\n\n.btn1{\n\twidth: 100%;\n\tpadding: 1rem 2rem  1rem 2rem;\n\t/* padding-bottom: 0.5rem; */\n\tborder-width: 0;\n\tborder-color: none;\n\tborder-radius: 0.8rem;\n\tmargin-left: 20px;\n\ttext-decoration: none !important;\n\twhite-space: nowrap;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    display: inline-block;\n    max-width: 100%;\n}\n\n.star{\n\tmargin-right: 15px !important;\n}\n\n.btn-check{\n\twidth: 100%;\n\tpadding: .5rem 2rem  .5rem 2rem;\n\t/* padding-bottom: 0.5rem; */\n\tborder-width: 0;\n\tborder-color: none;\n\tborder-radius: 0.8rem;\n\t/* margin-left: 20px; */\n\ttext-decoration: none !important;\n}\n\n.pan-image{\n\tmargin: 10px 20px 10px 20px;\n\theight: 200px;\n\twidth: 300px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
