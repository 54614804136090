import React,{useState,useEffect} from 'react'
import { Link, useParams, useNavigate,useLocation } from 'react-router-dom';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');




 function ServiceDetailsView() {
  let navigate = useNavigate();
  const location = useLocation();
  const attributes = useParams();
  const [inCart, setInCart] = useState([]);
  const [serviceData, setAllServiceData] = useState(); // Initialize state with null

  useEffect(() => {
    getService();
    checkCart(); // Check cart on component mount or when service_id changes
  }, [attributes.service_id]);

  const getService = async () => {
    const data = {
      id: attributes.service_id,
      action: "read"
    };
    
    let response = await common.apiRequest('post', '/seller/seller-service-details', data);
    console.log("API Response:", response);

    if (response.data.code === 'success') {
      console.log("Data received from API:", response.data.data);
      setAllServiceData(response.data.data[0]); 
    } else {
      common.alert(response.data.error, 'error');
    }
  };
  const addToCart = async (serviceId) => {
    let cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
    let newCartItem = {
        'product_id': 0,
        'product_item_id': 0,
        'quantity_purchased': 1,
        'user_id': localStorage.getItem('userId'),
        'supply_pincode': 0,
        'service_id': serviceId,
        'type': 'service',
        'action': 'create',
        'active': 0
    };
    let response = await common.apiRequest('post', '/products/add-remove-cart-items', newCartItem);
    let data = (response.data) ? response.data : [];
    newCartItem.cart_id = data.cart_id;
    delete newCartItem.user_id;
    delete newCartItem.action;
    cartItems.push(newCartItem);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));

    // Properly update the state
    setInCart((prevInCart) => [...prevInCart, serviceId]);
    common.alert(data.message, 'success');
};


const removeFromCart = async (serviceId) => {
  let cartItems = JSON.parse(localStorage.getItem('cartItems'));
  if (cartItems) {
      const cartItemToRemove = cartItems.find(o => o.service_id == serviceId);
      if (!cartItemToRemove) return;  // Avoid errors if item is not found
      cartItemToRemove.action = "delete";
      let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
      let data = (response.data) ? response.data : [];

      const updatedCartItems = cartItems.filter(item => item.service_id != serviceId);
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));

      // Properly update the state
      setInCart((prevInCart) => prevInCart.filter(id => id !== serviceId));
      common.alert(data.message, 'success');
  }
};

const checkCart = () => {
  const cartItems = JSON.parse(localStorage.getItem('cartItems')) || [];
  const serviceIdsInCart = cartItems.map(item => item.service_id);
  if (serviceIdsInCart.includes(parseInt(attributes.service_id))) {
    setInCart([parseInt(attributes.service_id)]);
  } else {
    setInCart([]);
  }
};
    return (
        <React.Fragment>
  <div class="container-fluid">
    <div class="row">
      <ol class="col-12 breadcrumb bg-transparent">
        <li class="breadcrumb-item"><Link to="/service" class="text-dark">Home</Link></li>
        <li class="breadcrumb-item active"><Link to="./index.html" class="text-dark">service details</Link></li>
      </ol>  
    </div>
  </div>


  <div className='py-5'>
        <div className='container-fluid'>
          <div className='row'>
          {serviceData && (
              <>
            <div className='col-lg-6'>
              
              <div className="border rounded-4 mb-3 d-flex justify-content-center">
                <Link data-fslightbox="mygalley" className="rounded-4" target="_blank" data-type="image" to="">
                  <img style={{ maxWidth: "100%", maxHeight: "60vh", margin: "auto" }} className="rounded-4 fit" src={envConfig.api_url + serviceData.service_image} />
                </Link>
              </div>
              
              
              
            </div>
            
            <div className='col-lg-6'>
              <div className="ps-lg-3">
                <h4 className="title text-dark">
                {serviceData.name}
                </h4>
                <div className="d-flex flex-row my-3">
                  <div className="text-warning star mb-1">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fas fa-star-half-alt"></i>
                    <span className="ms-1">
                      4.5
                    </span>
                  </div>
                  {/* <span className="text-muted star"><i className="fas fa-shopping-basket fa-sm mx-1"></i>154 orders</span> */}
                  {/* <span className="text-success ms-2">In stock</span> */}
                </div>

                <div className="mb-3">
                  <span className="h5"><i className="fas fa-rupee-sign"/>{serviceData.price}</span>
                  <span className="text-muted">/per visit</span>
                </div>
                <div className="mb-3">
                <dt className="col-3">Service description:</dt>
                <dd className="col-9">{serviceData.service_description}</dd>
                </div>
                <div className="mb-3">
                <dt className="col-3">Service provider:</dt>
                <dd className="col-9">{serviceData.service_by}</dd>
                </div>


                <div className="row">
                  <dt className="col-3">Deliverd By:</dt>
                  <dd className="col-9">{(new Date(new Date().getTime() + (5 * 24 * 60 * 60 * 1000)).toString().slice(0, 15))}</dd>

                  <dt className="col-3">Fastest Delivery:</dt>
                  <dd className="col-9">Tomorrow</dd>

                  {/* <dt className="col-3">Emi:</dt>
                  <dd className="col-9">Starts witch 5000/- Per month</dd> */}
                </div>

                <hr />
              <div className="row mb-5 ml-1">
                  {/* <div className="col-md-3 col-6">
                    <label className="mb-2 d-block">Size</label>
                    <select className="size-select border border-secondary" style={{ height: "35px" }}>
                      <option>Small</option>
                      <option>Medium</option>
                      <option>Large</option>
                    </select>
                  </div> */}
                {/* <div className="col-md-3 col-6">
                  <label className='mb-2 d-block' for="quantity">Quantity</label>
                  <select className="size-select border border-secondary" style={{ height: "35px" }} id="quantity">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div> */}
                {/* <div className='col-md-6 mt-4'>
                  <input className="col-md-3 size-select border border-secondary mr-1" style={{ height: "35px" }} type="text" id="pincode" placeholder='Enter Pincode'/>
                  <button className="col-md-3 btn-check btn-warning shadow-0" onClick={checkAvailability}>Check</button>
                </div> */}
                </div>
            
                <div className='row'>
                    <div className='col-md-4 mt-5'>
                        {
                                                    !inCart.includes(serviceData.id) ? 
                                                    <button className="btn1 btn-primary shadow-0" onClick={() => addToCart(serviceData.id)}>Add To Cart</button>
                                                    :
                                                    <button className="btn1 btn-primary shadow-0" onClick={() => removeFromCart(serviceData.id)}>Remove From Cart</button>
                                                }
                    </div>
                    <div className='col-md-4 mt-5'>
                        {/* <Link to="/cart" className="btn1 btn-warning shadow-0" onClick={buyNow}> Buy now </Link> */}
                    </div>
                    <div className='col-md-4 mt-5'>
                        <Link to="/" className="btn1 btn-warning shadow-0"> <i className="me-1 fa fa-shopping-basket"></i> More shopping </Link>
                    </div>
                </div>

                
              </div>
            </div>
            </>
            )}
          </div>
          
        </div>
      </div> 
        </React.Fragment>
    )
}
export default ServiceDetailsView;