import React from 'react';
import MainView from './MainView';

function MainPageProduct() {
    return (
        <>
            <MainView/>
        </>
    )
}

export default MainPageProduct;