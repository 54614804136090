import React, {useState,useEffect } from 'react';
import Table from 'react-bootstrap/Table';
import { Link, useNavigate } from 'react-router-dom';
const common = require('../utils/Common');
const moment = require('moment');

const UserWallet = () => {
    const [walletData, setWalletData] = useState([]);
    const navigate = useNavigate();
  
    useEffect(() => {
        getWalletData();
    }, []);

    const getWalletData = async () => {
        var parameters = {
            'user_id': localStorage.getItem('userId'),
        }
        let response = await common.apiRequest('post', '/users/get-wallet-data', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setWalletData(data);
    }

    return (
        <>
        <div className="container-fluid">
            <div className="row">
                <ol className="col-12 breadcrumb bg-transparent">
                    <li className="breadcrumb-item "><Link to="/" className="text-dark">Home</Link></li>
                    <li className="breadcrumb-item "><Link to="/my-account" className="text-dark">user-account</Link></li>
                    <li className="breadcrumb-item active"><Link to="#" className="text-dark">Wallet</Link></li>
                </ol>
            </div>
            <div className='row'>
            <div className='col-md-12 table-responsive table-container'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Transaction Date</th>
                        <th>Order Id</th>
                        <th>Credit</th>
                        <th>Debit</th>
                        <th>Balance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            walletData.map((data) => {
                                return (
                                    <tr>
                                        <td>{moment(data.created_at).format('YYYY-MM-DD HH:mm:ss')}</td>
                                        <td style={{ cursor: 'pointer', color: 'blue' }} onClick={() => navigate(`/orders/${data.order_id}`)}>
                                            {data.order_id}
                                        </td>
                                        <td>{data.credit}</td>
                                        <td>{data.debit}</td>
                                        <td>{data.running_balance}</td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>       
            </div>
            </div>
        </div>
        </>
    )
}

export default UserWallet;