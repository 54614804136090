import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation } from 'react-router-dom';
import $ from 'jquery';
import AsyncSelect from 'react-select/async';
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, ModalFooter } from 'reactstrap';
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
const common = require('../../../utils/Common');
const axios = require('axios');

const containerStyle = {
  width: "400px",
  height: "400px",
};

const center = {
  lat: 0,
  lng: 0,
};


 function Seller_AddEditAddress({ onMarkerClick }) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyBFMz_fA4nUiRZJDg5rzXed-l0SItaMXrg",
  });
  const [map, setMap] = useState(null);
  const [markerPosition, setMarkerPosition] = useState(null);
  const [useMobileNo,setUseMobileNo] = useState();
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const handleMapClick = (event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    });
    fetchLocationDetails(event.latLng.lat(), event.latLng.lng());
  };

  const location = useLocation()
  const { editAddress } = location.state ? location.state : {};
  const [address, setAddress] = useState(editAddress ? editAddress : {});
  const [userLocation, setUserLocation] = useState(null);
  let navigate = useNavigate();

  const handleChange = (selectedOption, name) => {
    if (selectedOption) {
        const { value, state, id } = selectedOption.value;
        setAddress(prevAddress => ({
            ...prevAddress,
            [name]: value,
            state: state,
            pin_id: id
        }));
    } else {
        setAddress(prevAddress => ({
            ...prevAddress,
            [name]: '',
            state: '',
            pin_id: ''
        }));
    }
  };
  
  const addEditAddress = async (e) => {
    e.preventDefault();
    if (!address.address || !address.state || !address.pincode || !address.village_locality_name || $('#gst_no').val() == "") {
      common.alert("Please fill mandatory fields marked with *", 'error');
      return;
    }
    let parameters = {
        address: address.address,
        village_locality_name: address.village_locality_name,
        sub_district: address.sub_district ? address.sub_district : '-',
        district: address.district ? address.district : '-',
        state: address.state,
        pincode: address.pincode,
        pin_id: address.pin_id,
        'gst_no': $('#gst_no').val(),
        type: editAddress ? 'update' : 'create',
        'seller_id': localStorage.getItem('userId'),
        active: $('#is_default').is(":checked") ? 1 : 0
      }
    if(editAddress) {
        parameters.id = editAddress.id;
    }

		let response = await common.apiRequest('post', '/users/seller-address', parameters);
        console.log(response)
		if (response.status == 200) {
			if (response.data.code == 'success') {
                common.alert(response.data.message);
                window.location.href ="/seller/Seller_Address";
			} else {
            common.alert(response.data.error, 'error');
			}
		} else {
             common.alert('Something went wrong', 'error');
			// $('#error').html(response);
		}
  }
  const fetchCurrentLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const {latitude, longitude} = position.coords;
        setUserLocation({latitude, longitude});
        fetchLocationDetails(latitude, longitude);
      },
      (error) => {
        console.error('Error getting user location : ', error);
      });
    } else {
      console.error('Geolocation is not supported');
    }
  }

  const fetchLocationDetails = async (latitude, longitude) => {
    try {
      const response = await axios.get(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyBFMz_fA4nUiRZJDg5rzXed-l0SItaMXrg`
      );
  
      if (response.data.results.length > 0) {
        // Default values
        let state = '';
        let city = '';
        let country = '';
        let pincode = '';
        let sub_district = '';
        let district = '';
        let village_locality_name = '';
  
        // Extract relevant address components
        response.data.results.forEach(result => {
          const addressComponents = result.address_components;
          addressComponents.forEach(component => {
            // Look for various types to ensure we get the needed information
            if (component.types.includes('postal_code')) {
              pincode = component.long_name || pincode;
            }
            if (component.types.includes('administrative_area_level_3')) {
              sub_district = component.long_name || sub_district;
            }
            if (component.types.includes('country')) {
              country = component.long_name || country;
            }
            if (component.types.includes('sublocality') || component.types.includes('locality')) {
              village_locality_name = component.long_name || village_locality_name;
            }
            if (component.types.includes('administrative_area_level_1')) {
              state = component.long_name || state;
            }
            if (component.types.includes('locality')) {
              city = component.long_name || city;
            }
            if (component.types.includes('administrative_area_level_2')) {
              district = component.long_name || district;
            }
          });
        })
  
        // Ensure we set all address fields, even if some might be empty
        setAddress(prevAddress => ({
          ...prevAddress,
          address: response.data.results[0]['formatted_address'] || '',
          state: state || '',
          city: city || '',
          country: country || '',
          pincode: pincode || '',
          sub_district: sub_district || '',
          district: district || '',
          village_locality_name: village_locality_name || '',
          pin_id: 0
        }));
  
      } else {
        console.warn('No results found for the given coordinates.');
      }
    } catch (error) {
      console.error('Error fetching address:', error);
      // Handle error appropriately, e.g., show an alert or log the error
    }
  };

  const fetchData = async (inputValue, columnName) => {
    if(!inputValue || inputValue.length < 3) return;
    var data = {
      'action': 'read',
      'source': 'web',
      'value': inputValue,
      'columnName': columnName
    }
    let response = await common.apiRequest('post', '/admin/crud-pincodes', data);
    if (response.data.code == 'success') {
      return response.data.data.map((resp) => ({ value: resp, label: resp.value }));
    } else {
      common.alert(response.data.error, 'error');
    }
  }
  
  const verifyOtp = async (type) => {
    if (type === "sms" && $('#mobile_otp').val() === "") {
        common.alert("Please enter OTP received on SMS", 'error');
        return false;
    }
    let otpData = {
        "phone": useMobileNo,
        "channel": type,
        "code": $('#mobile_otp').val()
    };
    let response = await common.apiRequest('post', '/users/verify-otp', otpData);
    if (response.data.code === 'success') {
        common.alert(response.data.message);
        setIsOtpVerified(true);
        return true;
    } else {
        common.alert(response.data.error, 'error');
        return false;
    }
}
const sandOTP = async () => {
    var parameters = {
        'action': 'read',
        'seller_id': localStorage.getItem('userId'),
    };
    let response = await common.apiRequest('post', '/users/UserAddesOTPverify', parameters);
    common.alert(response.data.message)
    let data = (response.data && response.data.data) ? response.data.data : [];
    setUseMobileNo(data);
    setIsOtpVerified(false);
} 
    return (
        <React.Fragment>
  {/* <!-- new address details --> */}
  <div className="container">
      <div className="row">
          <div className="col-12">
              <h2>{editAddress ? 'Edit' : 'Add' } Your seller Detail</h2>
          </div>
        <div className="col-md-10 mt-3 offset-md-1">
            <div className='location-icon'>
              <button className='btn btn-primary' onClick={fetchCurrentLocation}><i className='fas fa-map-marker'></i></button>
            </div>
            <div>
            { isLoaded ? (
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={3}
                onClick={handleMapClick}
                onLoad={(map) => setMap(map)}
              >
                {markerPosition && <Marker position={markerPosition} />}
              </GoogleMap>
            ) : (
              <></>
            )
            }
            </div>
            <form>
                <div className="form-group row">
                  
                    <label for="home address" className="col-md-12 col-form-label">
                      <h4><span className='text-danger'>*</span>Full Address:</h4>
                    </label>
                               
                  <div className="col-md-12 offset-md-1">
                      <input type="text" className="form-control" id="address" placeholder="House No 34A Greater Kailash" defaultValue={address.address ? address.address : ''} onChange={(e) => setAddress({ ...address, address: e.target.value })}/>
                  </div>
                </div>

                <div className="form-group row">
                  <label for="home address" className="col-md-12 col-form-label">
                    <h4><span className='text-danger'>*</span>Pincode:</h4>
                  </label>
                  <div className="col-md-12 offset-md-1">
                  <AsyncSelect cacheOptions defaultOptions
                    value={address.pincode ? { value: address.pincode, label: address.pincode } : null}
                    loadOptions={(inputValue) => fetchData(inputValue, 'pincode')}
                    placeholder="Enter First 3 Characters To Search pincode..."
                    onChange={(selectedOption) => handleChange(selectedOption, 'pincode')}
                  />
                </div>

                <div className="form-group row">
                  <label for="home address" className="col-md-12 col-form-label">
                    <h4><span className='text-danger'>*</span>State:</h4>
                  </label>
                  <div className="col-md-12 offset-md-1">
                    <input type="text" className="form-control" placeholder="Delhi" defaultValue={address.state ? address.state : ''} readOnly/> 
                  </div>
                </div>

                <div className="form-group row">
                  <label for="home address" className="col-md-12 col-form-label">
                    <h4><span className='text-danger'>*</span>Village Locality:</h4>
                  </label>
                  <div className="col-md-12 offset-md-1">
                    <input type="text" className="form-control" id="village_locality_name" placeholder="Palam Colony" defaultValue={address.village_locality_name ? address.village_locality_name : ''} onChange={(e) => setAddress({ ...address, village_locality_name: e.target.value })}/>
                  </div>
                </div>

                <div className="form-group row">
                  <label for="home address" className="col-md-12 col-form-label">
                    <h4>Sub District:</h4>
                  </label>
                  <div className="col-md-12 offset-md-1">
                    <input type="text" className="form-control" id="sub_district" placeholder="South West Delhi" defaultValue={address.sub_district ? address.sub_district : ''} onChange={(e) => setAddress({ ...address, sub_district: e.target.value })}/>
                  </div>
                </div>


                <div className="form-group row">
                  <label for="home address" className="col-md-12 col-form-label">
                    <h4>District:</h4>
                  </label>
                  <div className="col-md-12 offset-md-1">
                  <input type="text" className="form-control" id="district" placeholder="Delhi" defaultValue={address.district ? address.district : ''} onChange={(e) => setAddress({ ...address, district: e.target.value })}/>
                  </div>
                </div>
                
                </div>
                <div className="form-group row">
              <label htmlFor="gst_no" className="col-md-12 col-form-label">
                <h4><span className='text-danger'>*</span>GST Number:</h4>
              </label>
              <div className="col-md-12 offset-md-1">
                <input
                  type="text"
                  className="form-control"
                  id="gst_no"
                  placeholder="Enter GST Number"
                  value={address.gst_no || ''}
                  onChange={(e) => setAddress({ ...address, gst_no: e.target.value })}
                />
              </div>
            </div>
            <Button onClick={() => sandOTP(address)} className="btn btn-outline-primary btn-sm">
                                            send OTP
                            </Button>
                            {!isOtpVerified && (
                        <div className='otp_verify'>
                            
                            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                <Label className="me-sm-2" for="mobile_otp">Mobile OTP</Label>
                                <Input type="number" className='inputs' id="mobile_otp" placeholder="1234" />
                                <Button type='button' id="mobile_otp_verify_button" className='submit-button' onClick={() => verifyOtp('sms')}>Verify OTP</Button>
                            </FormGroup>
                        </div>
                    )}
                <div className="form-group">
                  <div className="col-md-12 offset-md-1"> 
                    <input type='checkbox' id="is_default" defaultChecked= {address.active ? true : false}/> Is Default ?
                  </div>                    
                </div>
                {isOtpVerified && (

                <div className="my-5 offset-md-1">
                  <button type="submit" className="btn btn-success shadow rounded" onClick={addEditAddress}>{editAddress ? 'Edit' : 'Add' } address</button>
                </div>    
                )}
            </form>
        </div>
    </div>
</div>
      
  {/* <!-- new address details end--> */}
        </React.Fragment>
    )
}
export default Seller_AddEditAddress;