import React from 'react';
import { Button,CardBody,Card,Form,FormGroup,Label,Input, CardHeader} from 'reactstrap';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
const common = require('../utils/Common');


const ForgotPass = (props) => {
	let navigate = useNavigate();
	const ForgetPass = async (e) => {
		e.preventDefault();
		let data = {
			"regId" : $('#reg_id').val(),
			"forgotPassword" : 1
		}
		if ($('#reg_id').val() == '') {
			$('#error').html('Please Fill Reg Id');
			return;
		}
		let response = await common.apiRequest('post', '/users/forgot-password-or-user-id', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
				common.alert(response.data.message)
				navigate("/login");
			} else {
				$('#error').html(response.data.error);
			}
		} else {
			$('#error').html(response);
		}
	}

	if (localStorage.getItem('token')) {
		navigate('/home');
	}

	return (
		<div className='container-fluid'>
	        <div className='cards'>
	            <Card className='cardbody col-md-3 col-sm-7'>
	                <CardBody>
	                    <CardHeader className='card-headers'>Forgot Password</CardHeader>
	                        <div className='btm-border'></div>
	                        <Form onSubmit={ForgetPass} className='forms'>
	                            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
	                                <Label className="me-sm-2" for="reg_id">Reg Id</Label>
	                                <Input type="text" className='inputs' id="reg_id" placeholder="Enter Reg Id" />
	                            </FormGroup>
	                            <p className="text-danger" id="error"></p>
	                            <Button type="submit" className='submit-button'>Submit</Button><br/>
	                            <Link to="/login">Login using user id</Link><br/>
								<Link to="/signup">Register as new user</Link><br/>
	                        </Form>
	                </CardBody>
	            </Card>
	        </div>
	    </div>
	);
}

export default ForgotPass;