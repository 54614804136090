import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import html2canvas from "html2canvas";
import jsPDF from 'jspdf';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import './business.css';

const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

function GenerateInvoice() {
    const [invoiceData, setInvoiceData] = useState([]);
    console.log(invoiceData,"koo")
    const location = useLocation();
    var counter = 0;

    useEffect(() => {
        setInvoiceData(location.state?.invoiceData || {});
        pdfMake.vfs = pdfFonts.pdfMake.vfs;
    }, [location.state]);

    const downloadPdfDocument = () => {
        const input = document.getElementById("testId");
        const pdfWidth = 1300;
        const pdfHeight = input.offsetHeight;

        html2canvas(input, { scale: 2 }).then((canvas) => {
            const imgData = canvas.toDataURL('image/jpeg', 0.3);
            const pdf = new jsPDF('p', 'pt', [pdfWidth, pdfHeight]);
            pdf.addImage(imgData, 'JPEG', 0, 0, pdfWidth, pdfHeight);
            pdf.save(`invoice.pdf`);
        });
    }
    const isReturned = invoiceData?.order?.order_items[0]?.order_status === 'process_amount_to_wallet';

    if (invoiceData?.order?.user) {
        return (
            <>
                <div className='bill-customer' id="testId">
                    <div className='invoice'>
                        <div className='invoice-head col-md-12'>
                        <h2>{isReturned ? "Tax Return Invoice / Bill of Return Supply" : "Tax Invoice / Bill of Supply"}</h2>
                        </div>
                        <div className='customer-details'>
                            <div className='customer-details-head'>
                                <h2>Customer Details:</h2>
                            </div>
                            <div className='bill-details row'>
                                <div className='billing-address col-md-6'>
                                    <div className='billing-address-head'><h4>Billing Address :</h4></div>
                                    <div className='billing-address-details'>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Name :</label>
                                            <div className='col-md-6'>{invoiceData.order.user.first_name + " " + invoiceData.order.user.last_name}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Address :</label>
                                            <div className='col-md-6'>{invoiceData.order.user_address?.address || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Pincode :</label>
                                            <div className='col-md-6'>{invoiceData.order.user_address?.pincode || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>State :</label>
                                            <div className='col-md-6'>{invoiceData.order.user_address?.state || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Pan No :</label>
                                            <div className='col-md-6'>{invoiceData.order.user?.pan_no || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Email :</label>
                                            <div className='col-md-6'>{invoiceData.order.user?.email || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Mobile No :</label>
                                            <div className='col-md-6'>{invoiceData.order.user?.mobile_phone || "N/A"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='shipping-address col-md-6'>
                                    <div className='shipping-address-head'><h4>Shipping Address :</h4></div>
                                    <div className='shipping-address-details'>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Name :</label>
                                            <div className='col-md-6'>{invoiceData.order.user.first_name + " " + invoiceData.order.user.last_name}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Address :</label>
                                            <div className='col-md-6'>{invoiceData.order.user_address?.address || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Pincode :</label>
                                            <div className='col-md-6'>{invoiceData.order.user_address?.pincode || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>State :</label>
                                            <div className='col-md-6'>{invoiceData.order.user_address?.state || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Email :</label>
                                            <div className='col-md-6'>{invoiceData.order.user?.email || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Mobile No :</label>
                                            <div className='col-md-6'>{invoiceData.order.user?.mobile_phone || "N/A"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='customer-details'>
                            <div className='customer-details-head'>
                                <h4>Supply by:</h4>
                            </div>
                            <div className='bill-details row'>
                                <div className='billing-address col-md-6'>
                                    <div className='billing-address-details'>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Name :</label>
                                            <div className='col-md-6'>{invoiceData.seller?.user_business?.business_name || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Address :</label>
                                            <div className='col-md-6'>{invoiceData.order.order_items[0]?.product_item?.product?.seller_address?.address || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Pincode :</label>
                                            <div className='col-md-6'>{invoiceData.order.order_items[0]?.product_item?.product?.seller_address?.pincode || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>GSTIN :</label>
                                            <div className='col-md-6'>{invoiceData.order.order_items[0]?.product_item?.product?.seller_address?.gst_no || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>State :</label>
                                            <div className='col-md-6'>{invoiceData.order.order_items[0]?.product_item?.product?.seller_address?.state || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Pan No :</label>
                                            <div className='col-md-6'>{invoiceData.seller?.user_business?.pan_no || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Place of supply :</label>
                                            <div className='col-md-6'>{invoiceData.order.order_items[0]?.product_item?.product?.seller_address?.state || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Place of delivery :</label>
                                            <div className='col-md-6'>{invoiceData.order.user_address?.state || "N/A"}</div>
                                        </div>
                                    </div>
                                </div>
                                <div className='shipping-address col-md-6'>
                                    <div className='shipping-address-details'>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Invoice Number :</label>
                                            <div className='col-md-6'>{invoiceData.invoice_no || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Invoice Date :</label>
                                            <div className='col-md-6'>{invoiceData.created_at || "N/A"}</div>
                                        </div>
                                            {isReturned && (
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Return Date :</label>
                                            <div className='col-md-6'>{invoiceData.order.order_items[0].rirn_date || "N/A"}</div>
                                            </div>
                                            )}
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Order ID (SRRR Number) :</label>
                                            <div className='col-md-6'>{invoiceData.order.order_id || "N/A"}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Mode of Payment  :</label>
                                            <div className='col-md-6'>Online</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Amount received  :</label>
                                            <div className='col-md-6'>Rs. {invoiceData.price || 0}</div>
                                        </div>
                                        <div className='user-address row'>
                                            <label className='col-md-6'>Date & Time :</label>
                                            <div className='col-md-6'>{invoiceData.created_at || "N/A"}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className='invoice-table'>
                            <div className='invoice-table-head'>
                                <h4>Goods wise Details:</h4>
                            </div>
                            <div className='invoice-table-body'>
                                <table className="orders-table report-margin table-responsive">
                                    <thead>
                                        <tr>
                                            <th>S. No.</th>
                                            <th>Product Description</th>
                                            <th>HSN / SAC Code</th>
                                            <th>Qty</th>
                                            <th>Rate</th>
                                            <th>Sale Amount</th>
                                            <th>Disc</th>
                                            <th>Taxable Supply</th>
                                            <th>IGST
                                                <th>Rate %</th>
                                                <th>Amount</th>
                                            </th>
                                            <th>CGST
                                                <th>Rate %</th>
                                                <th>Amount</th>
                                            </th>
                                            <th>SGST / UTGST
                                                <th>Rate %</th>
                                                <th>Amount</th>
                                            </th>
                                            <th>TOTAL
                                                <th>GST</th>
                                                <th>{isReturned ? 'Return Amount' : 'Invoice Amount'}</th>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            invoiceData.order.order_items.map((data) => {
                                                counter++;
                                                return (
                                                    <tr key={counter}>
                                                    <td>{counter}</td>
                                                    <td>{data.product_item ? data.product_item.product?.name || "N/A" : data.seller_service?.service_name || "N/A"}</td>
                                                    <td>{data.product_item?.hsn_code || data.seller_service?.hsn_code || "N/A"}</td>
                                                    <td>{data.order_quantity || "N/A"}</td>
                                                    <td>{data.product_item ? data.product_item?.price || 0 : data.seller_service?.price || 0}</td>
                                                    <td>{isReturned ? ` ${data.rnote_value || 0}` : data.order_price || 0}</td>
                                                    <td>0</td>
                                                    <td>{isReturned ? ` ${data.rtaxable_value || 0}` : data.order_price || 0}</td>
                                                    <td>
                                                         <td>{isReturned ? data.rigst_percent : data.igst_percent}</td>
                                                        <td>{isReturned ? ` ${data.rigst || 0}` : data.igst}</td>
                                                    </td>
                                                    <td>
                                                        <td>{isReturned ? data.rcgst_percent : data.cgst_percent}</td>
                                                        <td>{isReturned ? ` ${data.rcgst || 0}` : data.cgst}</td>
                                                    </td>
                                                    <td>
                                                        <td>{isReturned ? data.rsgst_percent : data.sgst_percent}</td>
                                                        <td>{isReturned ? ` ${data.rsgst || 0}` : data.sgst}</td>
                                                    </td>
                                                    <td>
                                                        <td>{isReturned ? data.rgst_rate : (data.product_item ? data.product_item?.gst_percent || 0 : data.seller_service?.gst_rate || 0)}</td>
                                                        <td>{isReturned ? ` ${data.rnote_value || 0}` : data.order_price || 0}</td>
                                                    </td>
                                                </tr>
                                                )
                                            })
                                        }
                                        <tr>
                                            <td colSpan="10"></td>
                                            <td colSpan="10">
                                                <tr>
                                                    <td>Delivery Charge</td>
                                                    <td>Rs. {isReturned ? '0' : (invoiceData.order.logistics_order?.order_price || 0)}</td>
                                                </tr>
                                                <tr>
                                                    <td><b>Total</b></td>
                                                    <td>Rs. {isReturned ? `- ${invoiceData.price || 0}` : invoiceData.price || 0}</td>
                                                </tr>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='footer-data row'>
                            <b className='col-md-6'>Whether GST payable under reverse charge  ?</b>
                            <h6 className='col-md-6'>No</h6>
                        </div>
                        <div className='footer-data row'>
                            <div className='col-md-12 row'>
                                <h5 className='col-md-6'>Remarks:</h5>
                                <div className='col-md-6 row'>
                                    <label className='col-md-2'>for</label>
                                    <div className='col-md-4'>
                                        <b>Name:</b>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='footer-data row'>
                            <div className='col-md-12 row'>
                                <h5 className='col-md-6'>Remarks:</h5>
                                <div className='col-md-6'>
                                    <div className='Sign-image-vendor'>Sign image of vendor AS</div>
                                    <div className='Authorised-Signatory'>Authorised Signatory</div>
                                </div>
                            </div>
                        </div>
                        <button onClick={downloadPdfDocument}>Download Pdf</button>
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
}

export default GenerateInvoice;
