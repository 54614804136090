import React, { useState, useEffect } from 'react';
import $ from 'jquery';
import { FormGroup, Button, Label, Form, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Table from 'react-bootstrap/Table';
import { Link } from 'react-router-dom';
import moment from 'moment';
import './userA.css';

const common = require('../../utils/Common');

const UserRefeanceDetails = () => {
    const [userData, setUserData] = useState([]);
    console.log(userData);
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);

    useEffect(() => {
        getReferal(0);
    }, []);

    const getReferal = async () => {
        var parameters = {
            'action': 'read',
            'start_date': $('#Date-from').val(),
            'end_date': $('#Date-to').val(),
            'user_id': localStorage.getItem('userId'),
        };
        let response = await common.apiRequest('post', '/users/refferal-info', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setUserData(data);
    }

    const toggleModal = (user) => {
        setSelectedUser(user);
        setModalOpen(!modalOpen);
    }

    const renderDetailTable = (data) => {
        // Combine all relevant data into a single array
        const allItems = [
            ...(data.offline || []).map(item => ({ ...item, type: 'Offline' })),
            ...(data.orders || []).map(item => ({ ...item, type: 'Order' })),
            ...(data.servicebuy || []).map(item => ({ ...item, type: 'Service' })),
            ...(data.soldItems || []).map(item => ({ ...item, type: 'Sold Item' }))
        ];

        return (
            <>
                <h4>User Information</h4>
                <Table striped bordered hover className="mb-4">
                    <tbody>
                        <tr>
                            <th>User ID</th>
                            <td>{data.reg_id}</td>
                            <th>User Name</th>
                            <td>{data.first_name} {data.last_name}</td>
                        </tr>
                        <tr>
                            <th>Email</th>
                            <td>{data.email}</td>
                            <th>Mobile Phone</th>
                            <td>{data.mobile_phone}</td>
                        </tr>
                        <tr>
                            <th>PAN No</th>
                            <td>{data.pan_no}</td>
                            <th>Referral Code</th>
                            <td>{data.referral_code}</td>
                        </tr>
                        <tr>
                            <th>Referrer</th>
                            <td>{data.referrerBY}</td>
                            <th>User Type</th>
                            <td>{data.user_type}</td>
                        </tr>
                        <tr>
                            <th>Total Service Amount</th>
                            <td>{data.totalService}</td>
                            <th>Total Product Amount</th>
                            <td>{data.totalPurchases}</td>
                        </tr>
                        <tr>
                            <th>Total Sale Amount</th>
                            <td>{data.totalSales}</td>
                            <th>Total Offline Sale</th>
                            <td>{data.totalOffline}</td>
                        </tr>
                    </tbody>
                </Table>

                <h4>Detailed Transactions</h4>
                <Table striped bordered hover responsive>
                    <thead>
                        <tr>
                            <th>Type</th>
                            <th>ID</th>
                            <th>Created At</th>
                            <th>Price</th>
                            <th>Quantity</th>
                            <th>Invoice No</th>
                            <th>Taxable Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        {allItems.map((item, index) => (
                            <tr key={`${item.type}-${item.id}-${index}`}>
                                <td>{item.type}</td>
                                <td>{item.id || item.order_id}</td>
                                <td>{item.created_at}</td>
                                <td>{item.order_price}</td>
                                <td>{item.order_quantity}</td>
                                <td>
                                    {item.invoices && item.invoices.map(invoice => invoice.invoice_no).join(', ')}
                                    {item.order && item.order.invoices && item.order.invoices.map(invoice => invoice.invoice_no).join(', ')}
                                </td>
                                <td>
                                    {item.product_item && item.product_item.taxable_value}
                                    {item.order_items && item.order_items.map(orderItem => orderItem.product_item && orderItem.product_item.taxable_value).filter(Boolean).join(', ')}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </>
        );
    }

    return (
        <>
            {/* Main component JSX remains the same */}
            <div className="container-fluid">
                <div className="row">
                    <ol className="col-12 breadcrumb bg-transparent">
                        <li className="breadcrumb-item "><Link to="/" className="text-dark">Home</Link></li>
                        <li className="breadcrumb-item "><Link to="/my-account" className="text-dark">user-account</Link></li>
                        <li className="breadcrumb-item active"><Link to="#" className="text-dark">reffer info</Link></li>
                    </ol>
                </div>
            </div>
    
            <div className='col-md-12'>
                <div className="col-md-12">
                    <Form className='row'>
                        <FormGroup className='col-md-4 row'>
                            <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                            <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getReferal} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                        </FormGroup>
                        <FormGroup className='col-md-4 row'>
                            <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                            <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getReferal} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                        </FormGroup>
                    </Form>      
                    <div>
                    <h2>Total shopping amount</h2>    
                    {/* <h2>{totalAmount}</h2> */}
                    </div>            
                </div>
                <div className='table-responsive table-container'>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                               <th>User Id</th>
                               <th>User Name</th>
                               <th>Total tax service amount</th>
                               <th>Total service amount</th>
                               <th>Total tax product amount</th>
                               <th>Total product amount</th>
                               <th>Total tax offline amount</th>
                               <th>Total offline sale</th>
                               <th>Total tax online sale amount</th>
                               <th>Total online sale</th>
                               <th>Total tds</th>
                               <th>Total commission_earned</th>
                               <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userData.map((data) => (
                                <tr key={data.reg_id}>
                                    <td>{data.reg_id}</td>
                                    <td>{data.user_name}</td>
                                    <td>{data.total_service_taxable}</td>
                                    <td>{data.total_service_amount}</td>
                                    <td>{data.total_online_taxable}</td>
                                    <td>{data.total_online_amount}</td>
                                    <td>{data.total_offline_taxable}</td>
                                    <td>{data.total_offline_amount}</td>
                                    <td>{data.total_sales_tax_value}</td>
                                    <td>{data.total_sales_amount}</td>
                                    <td>{data.tds}</td>
                                    <td>{data.commission_earned}</td>
                                    <td>
                                        <Button color="primary" onClick={() => toggleModal(data)}>More Details</Button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>       
                </div>
            </div>

            <Modal isOpen={modalOpen} toggle={() => toggleModal(null)} size="xl">
                <ModalHeader toggle={() => toggleModal(null)}>User Details</ModalHeader>
                <ModalBody>
                    {selectedUser && renderDetailTable(selectedUser)}
                </ModalBody>
                <ModalFooter>
                    <Button color="secondary" onClick={() => toggleModal(null)}>Close</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

export default UserRefeanceDetails;