import React from 'react';
const envConfig = require('../config/envConfig');

function QrCode() {
    const userId = localStorage.getItem('userId');
    const qrImageUrl = `${envConfig.api_url}/images/users/${userId}_qr.jpeg`;


    return (
        <div className="app">
            <h1>Here is your QR code</h1>
            
            <img 
                src={qrImageUrl}
                alt="User QR Code"
            />
            
            <a href={qrImageUrl} download={`${userId}_qr.jpeg`} className='QRbutton'>
                Download
            </a>
            </div>



    

    

    );
}

export default QrCode;
