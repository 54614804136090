const common = require('../utils/Common');

export const compressImage = async (file, maxSize = 1024 * 1024) => { // 1MB max size
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
            const img = new Image();
            img.onload = () => {
                let canvas = document.createElement('canvas');
                let width = img.width;
                let height = img.height;

                if (width > height) {
                    if (width > 1024) {
                        height *= 1024 / width;
                        width = 1024;
                    }
                } else {
                    if (height > 1024) {
                        width *= 1024 / height;
                        height = 1024;
                    }
                }

                canvas.width = width;
                canvas.height = height;
                const ctx = canvas.getContext('2d');
                ctx.drawImage(img, 0, 0, width, height);

                canvas.toBlob(
                    (blob) => {
                        if (blob.size > maxSize) {
                            common.alert('Image size exceeds the maximum allowed size of 1MB. Please choose a smaller image.');
                            resolve(file); // Return the original file if it's too large
                        } else {
                            resolve(blob);
                        }
                    },
                    file.type,
                    0.7
                );
            };
            img.src = reader.result;
        };
        reader.readAsDataURL(file);
    });
};