import React, { useEffect, useState } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom';
import $ from 'jquery';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

const DeliveryProduct = () => {
  
  let navigate = useNavigate();
  var [deliveryData, setDeliveryData] = useState([]);
  
  useEffect(() => {
    if (!localStorage.getItem('delivery_token')) {
			navigate('/delivery/login');
		}
    getDeliveryData();
  }, []);

  
  const getDeliveryData = async () => {
    var parameters = {
      "deliver_id": parseInt(localStorage.getItem("delivery_userId"))
    };
    let response = await common.apiRequest('post', '/delivery/get-delivery-orders', parameters, 'delivery');
    let data = (response.data && response.data.data) ? response.data.data : [];
    setDeliveryData(data);
  }
  
  return (
    <>  
    <section>
      <div class="container py-3">
        {
          deliveryData.map((data) => {
            return (
              <>
              <div class="card mb-5">
                <div class="row">
                  <div class="col-md-4 bg-intro">
                    <img className='product_image' src={envConfig.api_url + (data.product_item ? data.product_item.product_image.split(',')[0] : data.seller_service.service_image)} width="300" height="300"></img>
                  </div>
                  <div class="col-md-8 px-3">
                    <div class="card-block px-3">
                      <h4 class="card-title py-3">{data.product_item ? data.product_item.product.name : data.seller_service.service_name}</h4>
                      <div className="col-md-10 mt-3">
                        <div className="row picf">
                            <label for="User Name" className="col-md-3 ">
                                <h5>User Name :</h5>
                            </label>
                            <div className="col-md-4 " style={{ fontSize: "large" }}>
                                {data.order.user.first_name + data.order.user.last_name}
                            </div>
                        </div>

                        <div className="row picf">
                            <label for="Phone No" className="col-md-3 ">
                                <h5>Phone No :</h5>
                            </label>
                            <div className="col-md-4" style={{ fontSize: "large" }}>
                                {data.order.user.mobile_phone}
                            </div>

                        </div>

                        <div className="row picf">
                            <label for="Address" className="col-md-3 ">
                                <h5>Address :</h5>
                            </label>
                            <div className="col-md-4" style={{ fontSize: "large" }}>
                                {data.order.user_address.address}
                            </div>

                        </div>
                        <button className='btn btn-primary btn-sm' onClick={() => navigate('/delivery/product/details',{state:data})}>More details</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              </>
            )
          })
        }
        </div>
    </section>
    </>
  )
}

export default DeliveryProduct