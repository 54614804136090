import React, {useState, useEffect} from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import './yourorder.css'
import { Input, FormGroup, Button, Label } from 'reactstrap';
import Modal from 'react-bootstrap/Modal';
import $ from 'jquery';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function YourOrdersView() {
    const navigate = useNavigate();
    const attributes = useParams();
    const handleClose = () => setShow(false);
    const handleCloses = () => setShows(false);
    const [orders, setOrders] = useState([]);
    const [show, setShow] = useState(false);
    const [shows, setShows] = useState(false);
    const [orderId, setOrderId] = useState(false);
    const feedbackType =[
        "Order","Service","Logistics"
    ]
    const [orderProgressModal, setOrderProgressModal] = useState(false);
    const [orderItemsData, setOrderItemsData] = useState([]);
    useEffect(() => {
        getOrders();
    }, []);

    const getOrders = async(filterOrderId = null) => {
        let data = {
            "user_id": localStorage.getItem('userId'),
            "order_id": (filterOrderId == 0 ? filterOrderId : (attributes.order_id == "null" ? null : attributes.order_id))
        }
        let response = await common.apiRequest('post', '/orders/get-orders', data);
        if (response.data.code == "success") {
            let orders = response.data.data ? response.data.data : [];
            setOrders(orders);
        }
    };

    const changeOrderStatusOperations = async (orderId, orderStatus) => {
        let data = {
            "order_id": orderId,
            "order_status": orderStatus
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code == "success") {
            common.alert(response.data.message);
            getOrders();
            showOrderProgressModal([]);
        }
    }

    const generateInvoice = async (orderId) => {
        let response = await common.getInvoiceData(localStorage.getItem("userId"), orderId, 'product');
        navigate(
            '/generate-invoice',
            {
              state: {
                invoiceData: response
              }
            }
        )
    }

    const updateNoReturn = async (e, orderId) => {
        let data = {
            'no_return': parseInt(e.target.value),
            'id': orderId
        }
        let response = await common.apiRequest('post', '/orders/update-no-return', data);
        if (response.data.code == "success") {
            common.alert(response.data.message);
            getOrders();
            showOrderProgressModal([]);
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const handleShow = (order_items_id) =>{
        setShow(true);
        setOrderId(order_items_id)
    } 
    const handleShowQuery = (order_items_id) =>{
        setShows(true);
        setOrderId(order_items_id)
    }
    
    const showOrderProgressModal = (orderItemsData) =>{
        setOrderProgressModal(!orderProgressModal);
        setOrderItemsData(orderItemsData);
    }

    const sendFeedBack = async () => {
        var parameters = {
            'user_id': localStorage.getItem('userId'),
            'user_feedback':  $('#feedback').val(),
            'action': 'create',
            'order_id': orderId,
            'seller_id': localStorage.getItem('sellerId'),
            'type': $('#feedback_type').val(),
        };
        try {
            let response = await common.apiRequest('post', '/users/user-feedback', parameters);
            if (response.data.code === "success") {
                common.alert(response.data.message);
                setShow(false)

            } else {
                common.alert(response.data.error, 'error');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };
    
    const sendQuery = async () => {
        var parameters = {
            'user_id': localStorage.getItem('userId'),
            'user_query':  $('#query').val(),
            'action': 'create',
            'order_id': orderId,
            'seller_id': localStorage.getItem('sellerId'),
            'type': $('#Query_type').val(),
        };
        try {
            let response = await common.apiRequest('post', '/users/user-query', parameters);
            if (response.data.code === "success") {
                common.alert(response.data.message);
                setShows(false)
            } else {
                common.alert(response.data.error, 'error');
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const filterOrder = () => {
        let orderId = $('#filter_order_id').val();
        if (orderId.length == 0) {
            getOrders(0);
        } else if (orderId.length >= 16) {
            getOrders(orderId);
        }
    }

    return (
       <React.Fragment>
            <div className="container-fluid">
                <div className="row">
                <ol className="col-12 breadcrumb bg-transparent">
                    <li className="breadcrumb-item"><Link to="./" className="text-dark">Home</Link></li>
                    <li className="breadcrumb-item "><Link to="/my-account" className="text-dark">Your Account</Link></li>
                    <li className="breadcrumb-item active"><Link to="#" className="text-dark">Your Order</Link></li>
                </ol>  
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-4">
                        <h4>Your Order</h4>
                    </div>
                    <div className="col-md-8">
                        <form className="form-inline float-right">
                            <div className="input-group"> 
                                <input type="text" className="form-control" name="your order name" id="filter_order_id" defaultValue={attributes.order_id != "null" ? attributes.order_id : null} placeholder="Order Search" onChange={filterOrder}/>
                                {/* <div className="input-grorp-append">
                                    <button type="button" className="btn btn-success" onClick={filterOrder}>Search Order</button>
                                </div> */}
                            </div>
                        </form>
                    </div>
                </div>
                

                <div className="row userodernav1">
                    <div className="col-md-12">
                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item">
                            <Link className="nav-link active" id="Orders-tab" data-toggle="tab" to="#Orders" role="tab" aria-controls="Orders" aria-selected="true">Orders</Link>
                            </li>
                                        </ul>
                        <div className="tab-content" id="myTabContent">
                            <div className="tab-pane fade show active" id="Orders" role="tabpanel" aria-labelledby="Orders-tab">
                                {
                                    orders.length == 0 ?
                                    <div className="card userordercard1">
                                        <div className="card-header">
                                            <div className="row">
                                                <h3>No orders yet</h3>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    orders.map((order) => {
                                        var logisticsPrice = order.logistics_order ? parseInt(order.logistics_order.order_price) : 0;
                                        var totalPrice = logisticsPrice;
                                        var walletAmount = 0;
                                        if (order.order_items.length > 0) {
                                            order.order_items.map((item) => {
                                                totalPrice =  totalPrice + parseInt(item.order_price);
                                                walletAmount = walletAmount + parseInt(item.wallet_amount);
                                            });
                                        }
                                        return (
                                            <div className="card userordercard1">
                                                <div className="card-header">
                                                    <div className="row">
                                                        <div className="col-md-3">
                                                            <p className="text-uppercase text-muted userordercardheader1">order time</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{new Date(order.created_at).toString()}</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p className="text-uppercase text-muted userordercardheader1">Order Price</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{totalPrice - logisticsPrice}</p><br/>
                                                            <p className="text-uppercase text-muted userordercardheader1">Used From Wallet</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{walletAmount}</p><br/>
                                                            <p className="text-uppercase text-muted userordercardheader1">Logistics Price</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{logisticsPrice}</p><br/>
                                                            <p className="text-uppercase text-muted userordercardheader1">Total Price</p>
                                                            <p className="text-uppercase text-muted userordercardheader1">{totalPrice - walletAmount}</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p className="text-uppercase text-muted userordercardheader1 float-right">Order No #{order.order_id}</p>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <p className="text-uppercase text-muted userordercardheader1 float-right">Order Name #{order.name}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="card-body">
                                                    <div className='row'>
                                                        <div className='col-md-6'>
                                                            {
                                                                order.order_items.map((orderItems) => {
                                                                    return (
                                                                        <div className='row' style={{cursor: 'pointer'}}onClick={() => showOrderProgressModal(orderItems)}>
                                                                            <div className="col-md-4">
                                                                                <img className="userorderpageimg1" src={envConfig.api_url + (orderItems.product_item || orderItems.seller_service ? orderItems.product_item ? orderItems.product_item.product_image.split(',')[0] : orderItems.seller_service.service_image : "")} alt="product"/>
                                                                            </div>
                                                                            <div className="col-md-8">
                                                                                <p className="text-muted userordercardheader1">sold by: TKGHUB</p>
                                                                                <p className="text-muted userordercardheader1">Quantity: {orderItems.order_quantity}</p>
                                                                                <p className="text-danger userordercardheader1 ">Total Price: <i className="fas fa-rupee-sign">{orderItems.order_price}</i></p>
                                                                            </div>
                                                                            <button  onClick={() => handleShow(orderItems.id)} className='btn btn-outline-success w-50 btn-sm usercardbtn1'>Give feedback</button>
                                                                            <button  onClick={() => handleShowQuery(orderItems.id)} className='btn btn-outline-danger w-50 btn-sm usercardbtn1'>Any Query?</button>
                                                                        </div>
                                                                        
                                                                    );
                                                                })
                                                            }
                                                        </div>
                                                        <div className="col-md-6 d-flex flex-column">
                                                            <button className="btn btn-outline-success w-50 btn-sm usercardbtn1" onClick={() => generateInvoice(order.id)}>Invoice Generate</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div className="tab-pane fade" id="Buy Again" role="tabpanel" aria-labelledby="Buy Again-tab">Seller Score is calculated by TKGHUB based on customer feedback and seller's performance...</div>
                            <div className="tab-pane fade" id="Open Order" role="tabpanel" aria-labelledby="Open Order-tab">Seller Score is calculated by TKGHUB based on customer feedback and seller's performance...</div>
                            <div className="tab-pane fade" id="Cancelled Order" role="tabpanel" aria-labelledby="Cancelled Order-tab">Seller Score is calculated by TKGHUB based on customer feedback and seller's performance...</div>
                        </div>
                    </div>
                </div>

                
                    <Modal show={show} onHide={handleClose} >
                        <Modal.Header closeButton >
                            <Modal.Title>Give your feedback here</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <FormGroup>
                            <Label for="feedback_type">Select type</Label>
                            <select id="feedback_type">
                            <option value="0">Select</option>
                            {
                                feedbackType.map((type) => {
                                return (
                                    <>
                                    <option>{type}</option>
                                    </>
                                )
                                })
                            }
                            </select>
                        </FormGroup>
                            <FormGroup>
                                <Label for="newEntry">Enter a feedback</Label>
                                <Input type='text' id="feedback"/>
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                                <Button className='submit' onClick={sendFeedBack}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    <Modal show={shows} onHide={handleCloses} >
                        <Modal.Header closeButton >
                            <Modal.Title>Right your Query</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                        <FormGroup>
                            <Label for="Query_type">Select type</Label>
                            <select id="Query_type">
                            <option value="0">Select</option>
                            {
                                feedbackType.map((type) => {
                                return (
                                    <>
                                    <option>{type}</option>
                                    </>
                                )
                                })
                            }
                            </select>
                        </FormGroup>
                            <FormGroup>
                                <Label for="newEntry">Enter Query </Label>
                                <Input type='text' id="query"/>
                            </FormGroup>
                        </Modal.Body>
                        <Modal.Footer>
                                <Button className='submit' onClick={sendQuery}>
                                Submit
                            </Button>
                        </Modal.Footer>
                    </Modal>

                    <Modal show={orderProgressModal} onHide={() => showOrderProgressModal([])} size='lg'>
                        <Modal.Header closeButton >
                            <Modal.Title>Order Progress</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='row'>
                                <div className="col-md-6 d-flex flex-column">
                                    <div class="order-track">
                                    {
                                        orderItemsData.order_trackings ? orderItemsData.order_trackings.map((track) => {
                                            if (track.status == 'cancelled') {
                                                return (
                                                    <div class="order-track-step">
                                                        <div class="order-track-status">
                                                            <span class="order-track-status-dot"></span>
                                                            <span class="order-track-status-line"></span>
                                                        </div>
                                                        <div class="order-track-text">
                                                            <p class="order-track-text-stat">{track.remarks.replaceAll("_", " ")}</p>
                                                            <span class="order-track-text-sub">{track.created_at}</span>
                                                        </div>
                                                    </div>
                                                );
                                            } else {
                                                return (
                                                    <div class="order-track-step">
                                                        <div class="order-track-status">
                                                            <span class="order-track-status-dot"></span>
                                                            <span class="order-track-status-line"></span>
                                                        </div>
                                                        <div class="order-track-text">
                                                            <p class="order-track-text-stat">{track.remarks.replaceAll("_", " ")}</p>
                                                            <span class="order-track-text-sub">{track.created_at}</span>
                                                        </div>
                                                    </div>
                                                );
                                            }
                                        }) : ""
                                    }
                                    {
                                        orderItemsData.order_status == 'in_progress' && orderItemsData.order_status != 'delivered' && orderItemsData.order_status != 'cancelled' ? 
                                        <div class="order-track-step">
                                            <div class="order-track-status">
                                                <span class="order-track-status-dot bg-warning"></span>
                                                <span class="order-track-status-line"></span>
                                            </div>
                                            <div class="order-track-text">
                                                <p class="order-track-text-stat">Delivered</p>
                                                <span class="order-track-text-sub">Not done yet</span>
                                            </div>
                                        </div> : ''
                                    }
                                    </div>
                                </div>
                                <div className="col-md-6 d-flex flex-column">
                                    {
                                        orderItemsData.order_status != 'cancelled' && orderItemsData.order_status != 'returned' && orderItemsData.order_status == "delivered"? 
                                        <button className="btn btn-outline-danger w-50 btn-sm usercardbtn1" onClick={() => changeOrderStatusOperations(orderItemsData.id, 'cancelled')}>Cancel Order</button>
                                        : ''
                                    }
                                    {
                                        orderItemsData.order_status != 'returned' ? 
                                        <>
                                        <button className="btn btn-outline-danger w-50 btn-sm usercardbtn1" onClick={() => changeOrderStatusOperations(orderItemsData.id, 'returned')}>Return Order</button>
                                        <button className="btn btn-outline-warning w-50 btn-sm usercardbtn1" onClick={() => changeOrderStatusOperations(orderItemsData.id, 'replaced')}>Replace Order</button>
                                        </>
                                        : ""
                                    }
                                    {
                                        orderItemsData.order_status == 'delivered' ? 
                                        <>
                                        <br/>
                                        <label>Satisfied with the item ? No Return ?</label>
                                        <select className='form-control' onChange={(e) => updateNoReturn(e, orderItemsData.id)} defaultValue={orderItemsData.no_return} disabled={orderItemsData.no_return != -1 ? true : false}>
                                            <option value={-1}>Please Select One Option</option>
                                            <option value={1}>Yes</option>
                                            <option value={0}>No</option>
                                        </select></> : ""
                                    }
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
            </div>
        </React.Fragment>
    )
}
export default YourOrdersView;