import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Html5QrcodeScanner } from "html5-qrcode";
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import './footer.css';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function Footer(props) {
  const [showQRScanner, setShowQRScanner] = useState(false);
  const [scanResult, setScanResult] = useState(null);
  const [modal, setModal] = useState(false);

  const location = useLocation();

  useEffect(() => {
    if (showQRScanner) {
      const element = document.getElementById('reader');
      if (element) {
        const scanner = new Html5QrcodeScanner('reader', {
          qrbox: {
            width: 250,
            height: 250,
          },
          fps: 5,
        });

        scanner.render(success, error);

        function success(result) {
          scanner.clear();
          setScanResult(result);
          setShowQRScanner(false); // Hide scanner after successful scan
          handleOfflineOrder(result); // Call function with scan result
        }

        function error(err) {
          console.warn(err);
        }

        return () => {
          scanner.clear();
        };
      } else {
        console.warn("Element with id 'reader' not found.");
      }
    }
    window.scrollTo(0, 0);
  }, [location, showQRScanner]);

  const toggleQRScanner = () => {
    setShowQRScanner(!showQRScanner);
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const handleOfflineOrder = async (result) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async (position) => {
        const {latitude, longitude} = position.coords;
        const parameters = {
          action: "create",
          order_status: "in_progress",
          user_id: localStorage.getItem("userId"),
          seller_id: result,
          lat: latitude,
          lon: longitude
        };
        
        let response = await common.apiRequest('post', '/orders/crud-offline-sale', parameters);
        if (response.data.code === "success") {
          common.alert(response.data.message);
        } else {
          common.alert(response.data.error, 'error');
        }
        toggleModal();
      },
      (error) => {
        common.alert('Error getting user location', 'error');
      });
    } else {
      common.alert('Geolocation is not supported', 'error');
    }
  };

  const MyBackgroundImage = "assets/img/up-arrow.png";
  const MyBackgroundImageQr = "assets/img/qr-code.png";

  return (
    <React.Fragment>
      <div className='Qr-scan'>
        <Link to="#" onClick={toggleModal}><img src={MyBackgroundImageQr} className="sacn-img" alt="..." /></Link>
      </div>
      <div className='arrow-up'>
        <Link to="#"><img src={MyBackgroundImage} className="arrow-img" alt="..." /></Link>
      </div>
      <Modal isOpen={modal} toggle={toggleModal} size="lg">
        <ModalHeader toggle={toggleModal}>Do offline vendor search here</ModalHeader>
        <ModalBody>
          <div className="search-container mt-1">
            <Button className="btn btn-warning search_button-QR" onClick={toggleQRScanner}><i className="fa fa-qrcode"></i></Button>
            <div className="Qrcode" style={{ display: showQRScanner ? 'block' : 'none' }}>
              <div id="reader"></div>
            </div>
          </div>
          <div className="search-container mt-1">
          <h2>OR</h2>

          </div>
          <div className="search-container mt-1">
            <input 
              type="text" 
              id="regIdInput" 
              placeholder="Enter 20-digit Reg ID"
              maxLength="20"
            />
            <Button 
              className="btn btn-primary" 
              onClick={() => {
                const regId = document.getElementById('regIdInput').value;
                if (regId.length === 20) {
                  handleOfflineOrder(regId);
                } else {
                  common.alert('Please enter a valid 20-digit Reg ID', 'error');
                }
              }}
            >
              Submit
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <footer className="section bg-footer">
  <div className="container">
    <div className="row">
      <div className="footer-col">
        <h6 className="footer-heading text-uppercase text-white">About Us</h6>
        <ul className="list-unstyled footer-link mt-4">
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.about_us }} className="text-white footer-text">About</Link></li>
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.contact_us }} className="text-white footer-text">Contact Us</Link></li>
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.payments }} className="text-white footer-text">Payments</Link></li>
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.shipping }} className="text-white footer-text">Shipping</Link></li>
        </ul>
      </div>
      <div className="footer-col">
        <h6 className="footer-heading text-uppercase text-white">Let us help you?</h6>
        <ul className="list-unstyled footer-link mt-4">
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.cancellation_and_returns }} className="text-white footer-text">Cancellation & Return</Link></li>
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.terms_of_use }} className="text-white footer-text">Terms Of Use</Link></li>
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.security }} className="text-white footer-text">Security</Link></li>
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.privacy }} className="text-white footer-text">Privacy</Link></li>
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.legal_disclamer }} className="text-white footer-text">Legal Disclaimer</Link></li>
        </ul>
      </div>
      <div className="footer-col">
        <h6 className="footer-heading text-uppercase text-white">Help</h6>
        <ul className="list-unstyled footer-link mt-4">
          <li><Link to="/footer/cms-data" state={{ value: props.cmsData.faq }} className="text-white footer-text">FAQ</Link></li>
          <li><Link to="/my-account" className="text-white footer-text">Your Account</Link></li>
          <li><Link to="/orders/null" className="text-white footer-text">Return Order</Link></li>
          <li><Link to="https://play.google.com/store/apps/details?id=com.srrr.srrrdev" className="text-white footer-text">App download</Link></li>
        </ul>
      </div>
      <div className="footer-col">
        <h6 className="footer-heading text-uppercase text-white">Contact Us</h6>
        <p className="contact-info mt-4">Contact us if you need help with anything</p>
        <p className="contact-info">info@rajaramraj.in</p>
        <div className="mt-5">
          <ul className="list-inline">
            <li className="list-inline-item"><Link to="https://www.facebook.com/share/hUahg3twbtCsP5fD/?mibextid=qi2Omg" target="_blank" className="text-white footer-text"><i className="fab facebook footer-social-icon fa-facebook-f"></i></Link></li>
            <li className="list-inline-item"><Link to="https://x.com/shreerajaramraj?t=N6MA8YlgzAj-Lzg3UWuknA&s=09" target="_blank" className="text-white footer-text"><i className="fab twitter footer-social-icon fa-twitter"></i></Link></li>
            <li className="list-inline-item"><Link to="#"><i className="fab google footer-social-icon fa-google"></i></Link></li>
            <li className="list-inline-item"><Link to="https://www.instagram.com/shreerajaramraj?igsh=MTEzOW4xZmg5bzR4bg==" target="_blank" className="text-white footer-text"><i className="fab apple footer-social-icon fa-instagram"></i></Link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div className="text-center mt-5">
    <p className="footer-alt mb-0 f-14">2024 © Srrr, All Rights Reserved</p>
  </div>
</footer>


    </React.Fragment>
  );
}

export default Footer;
