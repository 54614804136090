// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.shopping_cart{
  margin-top: 15%;
}


.userodernav1{
    margin-top: 5%;
  }
  .userordercard1{
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .userordercardheader1{
    margin-top:0%;
    margin-bottom: 0%;
  }
  .userorderpageimg1{
    margin-top: 10px;
    width:100px ;
    height:120px;
  }
  .usercardbtn1{
    margin-left: auto;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  @media screen and (max-width: 350px) {
    .shopping_cart{
      margin-top: 25%;
    }
    
  }
  /* .gradient-custom {
    
    background: #6a11cb;
    
    
    background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));
    
   
    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))
    } */

    .trash .fa-trash{
      margin-left: 10px;
      margin-right: 10px;
    
    }

    .trash .fa-heart{
      margin-left: 10px;
      margin-right: 10px;
    }

    .btn-dangers{
    background-color: #dc4c64 !important;
    color: #fff;
    box-shadow: 0 4px 9px -4px #d1485f;
   
}
    .btn-dangers:hover{
      background-color: #d1485f !important;
    }

    .ahover.button-cart{
      color: #fff;
      box-shadow: 0 4px 9px -4px #ffcc00;
    }
    .ahover a:hover { 
      text-decoration: none !important;
    } `, "",{"version":3,"sources":["webpack://./src/components/cart/cart.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;;AAGA;IACI,cAAc;EAChB;EACA;IACE,cAAc;IACd,iBAAiB;EACnB;EACA;IACE,aAAa;IACb,iBAAiB;EACnB;EACA;IACE,gBAAgB;IAChB,YAAY;IACZ,YAAY;EACd;EACA;IACE,iBAAiB;IACjB,eAAe;IACf,kBAAkB;EACpB;EACA;IACE;MACE,eAAe;IACjB;;EAEF;EACA;;;;;;;;;OASK;;IAEH;MACE,iBAAiB;MACjB,kBAAkB;;IAEpB;;IAEA;MACE,iBAAiB;MACjB,kBAAkB;IACpB;;IAEA;IACA,oCAAoC;IACpC,WAAW;IACX,kCAAkC;;AAEtC;IACI;MACE,oCAAoC;IACtC;;IAEA;MACE,WAAW;MACX,kCAAkC;IACpC;IACA;MACE,gCAAgC;IAClC","sourcesContent":[".shopping_cart{\n  margin-top: 15%;\n}\n\n\n.userodernav1{\n    margin-top: 5%;\n  }\n  .userordercard1{\n    margin-top: 5%;\n    margin-bottom: 5%;\n  }\n  .userordercardheader1{\n    margin-top:0%;\n    margin-bottom: 0%;\n  }\n  .userorderpageimg1{\n    margin-top: 10px;\n    width:100px ;\n    height:120px;\n  }\n  .usercardbtn1{\n    margin-left: auto;\n    margin-top: 5px;\n    margin-bottom: 5px;\n  }\n  @media screen and (max-width: 350px) {\n    .shopping_cart{\n      margin-top: 25%;\n    }\n    \n  }\n  /* .gradient-custom {\n    \n    background: #6a11cb;\n    \n    \n    background: -webkit-linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1));\n    \n   \n    background: linear-gradient(to right, rgba(106, 17, 203, 1), rgba(37, 117, 252, 1))\n    } */\n\n    .trash .fa-trash{\n      margin-left: 10px;\n      margin-right: 10px;\n    \n    }\n\n    .trash .fa-heart{\n      margin-left: 10px;\n      margin-right: 10px;\n    }\n\n    .btn-dangers{\n    background-color: #dc4c64 !important;\n    color: #fff;\n    box-shadow: 0 4px 9px -4px #d1485f;\n   \n}\n    .btn-dangers:hover{\n      background-color: #d1485f !important;\n    }\n\n    .ahover.button-cart{\n      color: #fff;\n      box-shadow: 0 4px 9px -4px #ffcc00;\n    }\n    .ahover a:hover { \n      text-decoration: none !important;\n    } "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
