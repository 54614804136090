import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import { Table, Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';
import moment from 'moment';
import { useCSVDownloader } from 'react-papaparse';

import $ from 'jquery';
const common = require('../utils/Common');
const envConfig = require('../config/envConfig');

const OfflineOrders = () => {
    let navigate = useNavigate();
    const [offlineOrdersData, setOfflineOrdersData] = useState([]);
    const { CSVDownloader, Type } = useCSVDownloader();

    const getOfflineOrdersData = async () => {
        let data = {
            'action': 'read',
            'fromDate': $('#Date-from').val(),
            'toDate': $('#Date-to').val(),
            'user_id': localStorage.getItem('userId')
        }
        let response = await common.apiRequest('post', '/orders/crud-offline-sale', data);
        if (response.data.code === 'success') {
            setOfflineOrdersData(response.data.data);
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const downloadData = offlineOrdersData.flatMap((data, index) => (
        data.offline_sale_product_data?.map((product, productIndex) => ({
            S_No: index + 1,
            Date: data.created_at,
            Invoice_No: data.order?.invoice?.invoice_no || "N/A",
            Seller_Name: data.seller?.first_name || "N/A",
            Seller_User_ID: data.seller?.reg_id || "N/A",
            Items_Particular: product.product_description,
            Taxable_Amount: product.taxable_supply,
            Total_Amount: product.sale_amount,
            Delivery_status: data.order_status,
            Feedback: "N/A",
            Query: "N/A"
        })) || []
    ));

    function loadScript(src) {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    }

    async function displayRazorpay(orderData) {
        let orderAmount = 0;
        for (let i = 0; i < orderData.offline_sale_product_data?.length; i++) {
            orderAmount += parseInt(orderData.offline_sale_product_data[i]['sale_amount']);
        }
        const res = await loadScript(
            "https://checkout.razorpay.com/v1/checkout.js"
        );
        if (!res) {
            common.alert("Razorpay SDK failed to load. Are you online?", 'error');
            return;
        }
        var data = {
            'amount': orderAmount
        }
        const result = await common.apiRequest('post', '/payment/orders', data);
        if (!result) {
            common.alert("Server error. Are you online?", 'error');
            return;
        }
        const { amount, id: order_id, currency } = result.data.data;
        const receipt_id = result.data.receiptId;
        const options = {
            key: envConfig.razorpay_key_id,
            amount: amount.toString(),
            currency: currency,
            name: "Shree Raja Ram Raj Biz",
            description: "Place Order",
            image: {},
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    orderCreationId: order_id,
                    razorpayPaymentId: response.razorpay_payment_id,
                    razorpayOrderId: response.razorpay_order_id,
                    razorpaySignature: response.razorpay_signature,
                    receiptId: receipt_id
                };
                const result = await common.apiRequest('post', '/payment/success', data);
                common.alert(result.data.data.msg, 'success');
                placeOrder(result.data.paymentId, orderData, orderAmount);
            },
            theme: {
                color: "#61dafb",
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const placeOrder = async (paymentId, orderData, orderAmount) => {
        var orderItems = [];
        var order = {
            'user_id': orderData.user_id,
            'address_id': orderData.user_address_id,
            'order_price': orderAmount,
            'logistics_vendor_service_id': 0,
            'logistics_vendor_id': 0,
            'logistics_vendor_price': 0,
            'payment_id': paymentId,
            'order_type': 'offline_product',
            'order_status': 'in_progress',
            'logistic_orders': []
        }
        for (let i = 0; i < orderData.offline_sale_product_data?.length; i++) {
            let tempData = {
                'offline_sale_id': orderData.offline_sale_product_data[i].id,
                'seller_id': orderData.seller_id,
                'product_id': 0,
                'order_quantity': orderData.offline_sale_product_data[i].quantity,
                'order_price': orderData.offline_sale_product_data[i].sale_amount,
                'service_id': 0,
                'order_status': "in_progress",
                'logistics_vendor_id': 0
            }
            orderItems.push(tempData);
        }
        var data = {
            'order': order,
            'orderItems': orderItems,
            'offline_order_id': orderData.id
        }
        let response = await common.apiRequest('post', '/orders/place-orders', data);
        if (response.data.code == "success") {
            common.alert(response.data.message);
            navigate('/orders/null');
        }
    }

    useEffect(() => {
        getOfflineOrdersData();
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <br />
                    <h2><center>Offline Orders</center></h2>
                    <br />
                </div>
                <CSVDownloader
                    type={Type.Button}
                    filename={'offline_orders'}
                    bom={true}
                    config={{}}
                    data={downloadData}
                >
                    <Button color="primary">Download CSV</Button>
                </CSVDownloader>
            </div>
            <div className='row tables'>
                <div className='col-md-12 '>
                    <div className="col-md-12">
                        <Form className='row'>
                            <FormGroup className='col-md-4 row'>
                                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                                <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getOfflineOrdersData} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
                            </FormGroup>
                            <FormGroup className='col-md-4 row'>
                                <Label className=" col-md-2 col-form-label" for="Date-to">To:</Label>
                                <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getOfflineOrdersData} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='table-responsive table-container'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Date</th>
                                    <th>Invoice No</th>
                                    <th>Seller Name</th>
                                    <th>Seller User ID</th>
                                    <th>Items/Particular</th>
                                    <th>Taxable Amount</th>
                                    <th>Total Amount</th>
                                    <th>Delivery status</th>
                                    <th>Feedback</th>
                                    <th>Query</th>
                                    <th>Make Payment</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    offlineOrdersData.map((data, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{index + 1}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{data.created_at}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{data.order?.invoice?.invoice_no || "N/A"}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{data.seller?.first_name || "N/A"}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{data.seller?.reg_id || "N/A"}</td>
                                                <td>{data.offline_sale_product_data?.[0]?.product_description || "N/A"}</td>
                                                <td>{data.offline_sale_product_data?.[0]?.taxable_supply || "N/A"}</td>
                                                <td>{data.offline_sale_product_data?.[0]?.sale_amount || "N/A"}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{data.order_status}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{"N/A"}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>{"N/A"}</td>
                                                <td rowSpan={data.offline_sale_product_data?.length || 1}>
                                                    {
                                                        data.status === 1 ?
                                                            <button className='btn btn-primary btn-sm' onClick={() => displayRazorpay(data)}>Make Payment</button> : data.status === 0 ? "Entry From Seller Pending" :
                                                                "Payment Already Done"
                                                    }
                                                </td>
                                            </tr>
                                            {
                                                data.offline_sale_product_data?.slice(1).map((product, productIndex) => (
                                                    <tr key={productIndex}>
                                                        <td>{product.product_description || "N/A"}</td>
                                                        <td>{product.taxable_supply || "N/A"}</td>
                                                        <td>{product.sale_amount || "N/A"}</td>
                                                    </tr>
                                                ))
                                            }
                                        </React.Fragment>
                                    ))
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </>
    );
};

export default OfflineOrders;
