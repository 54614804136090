import React, { useEffect, Component, useState } from 'react';
import { Button,CardBody,Card,Form,FormGroup,Label,Input, CardHeader} from 'reactstrap';
import $ from 'jquery';
import { Link, useNavigate } from 'react-router-dom';
const common = require('../utils/Common');


const ForgotUser = (props) => {
	const [users, setUsers] = useState([]);
	let navigate = useNavigate();
	const ForgetUser = async (e) => {
		e.preventDefault();
		let data = {
			"panNo" : $('#pan_no').val(),
			"forgotUserId" : 1
		}
		if ($('#pan_no').val() == '') {
			$('#error').html('Please fill email');
			return;
		}
		let response = await common.apiRequest('post', '/users/forgot-password-or-user-id', data);
		if (response.status == 200) {
			if (response.data.code == 'success') {
				$('#error').html('');
				setUsers(response.data.data.split(','));
			} else {
				setUsers([]);
				$('#error').html(response.data.error);
			}
		} else {
			setUsers([]);
			$('#error').html(response);
		}
	}

	if (localStorage.getItem('token')) {
		navigate('/home');
	}

	return (
		<div className='container-fluid'>
	        <div className='cards'>
	            <Card className='cardbody col-md-3 col-sm-7'>
	                <CardBody>
	                    <CardHeader className='card-headers'>Forgot User ID</CardHeader>
	                        <div className='btm-border'></div>
	                        <Form onSubmit={ForgetUser} className='forms'>
	                            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
	                                <Label className="me-sm-2" for="pan_no">Pan No</Label>
	                                <Input type="text" className='inputs' id="pan_no" placeholder="Enter PAN No" />
	                            </FormGroup>
								{
									users.length > 0 ?
										users.map((regId) => {
											return(
												<>
													<span className='mt-4'>{regId}</span><br/>
												</>
											)
										})
									: ''
								}
	                            <p className="text-danger" id="error"></p>
	                            <Button type="submit" className='submit-button mb-2'>Submit</Button><br/>
	                            <Link to="/login">Login using user id</Link><br/>
								<Link to="/signup">Register as new user</Link><br/>
	                        </Form>
	                </CardBody>
	            </Card>
	        </div>
	    </div>
	);
}

export default ForgotUser;