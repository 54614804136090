import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink } from "react-csv";
import { Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';
import { AgGridReact } from '@ag-grid-community/react';
import { ModuleRegistry } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';

import $ from 'jquery';
import envConfig from '../../config/envConfig';
const common = require('../../utils/Common');

ModuleRegistry.registerModules([ClientSideRowModelModule]);

const SellerServices = () => {
    let navigate = useNavigate();
    const [servicesData, setServicesData] = useState([]);
    const [editservicesData, setEditServicesData] = useState([]);
    const [pincodeModal, setPincodeModal] = useState(false);
    const [pincodes, setPincodes] = useState([]);
    const [modal, setModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const toggle = () => setModal(!modal);
    const toggleEditModal = () => setEditModal(!editModal);

    const getServiceData = async () => {
        let data = {
            'action': 'read',
            'seller_id': localStorage.getItem('sellerId')
        }
        let response = await common.apiRequest('post', '/seller/crud-seller-services', data);
        if (response.data.code === 'success') {
            setServicesData(response.data.data);
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const csvData = servicesData.map(data => ({
        ServiceName: data.service_name,
        ParentCategory: data.parent_category.category_name,
        SubCategory: data.sub_category.category_name,
        ServiceType: data.service_type,
        ServiceDescription: data.service_description,
        ServiceSKU: data.service_sku,
        ServiceHSNCode: data.service_by,
        GSTRate: data.hsn_code,
        TaxableValue: data.gst_rate,
        SupplyFromState: data.taxable_value,
        SupplyFromGSTNo: data.supply_from_state,
        OperatedStatePincodesPrices: data.supply_from_gst_no,
    }));

    const uploadServiceImage = async (files, serviceId) => {
        var fileData = new FormData();
        for (const key of Object.keys(files)) {
            fileData.append('service_images', files[key]);
            fileData.append('id', serviceId);
        }
        var response = await common.apiRequest('post', '/seller/update-service-images', fileData);
        common.alert(response.data.message);
        $('#service_image').val('');
        window.location.reload(true);
        window.location.href = "/seller/services";
    }
        
    useEffect(() => {
        getServiceData();
    }, []);

    const togglePincodeModal = () => {
        setPincodeModal(!pincodeModal);
    }

    const viewPincodes = async (pincodes) => {
        if (pincodes) {
            pincodes = pincodes.split('--');
            pincodes[0] = pincodes[0].replaceAll('-', '');
            pincodes[pincodes.length - 1] = pincodes[pincodes.length - 1].replaceAll('-', '');
            let data = {
                'pincodes': pincodes,
            }
            let response = await common.apiRequest('post', '/seller/get-pincodes', data);
            if (response.data.code === 'success') {
                togglePincodeModal();
                setPincodes(response.data.data);
            } else {
                common.alert(response.data.error, 'error');
            }
        }
    }

    const showEditDetails = (Editdata) => {
        setEditServicesData(Editdata)
        toggleEditModal();
    }

    const addDetail = async () => {
        var parameters = {
            'id': editservicesData.id,
            'action': 'update',
            'service_type': $('#service_type').val(),
            'service_description': $('#service_description').val(),
            'price': $('#price').val(),
            'service_sku': $('#service_sku').val(),
            'service_by': $('#service_by').val(),
            'gst_rate': $('#gst_rate').val(),
            'taxable_value': $('#taxable_value').val(),
            'supply_from_state': $('#supply_from_state').val(),
            'supply_gst_no': $('#supply_gst_no').val(),
            'logistics_delivery_type': $('#logistics_delivery_type').val(),
            'range_in_grams_from': $('#range_in_grams_from').val(),
            'range_in_grams_to': $('#range_in_grams_to').val(),
            'zone_a': $('#zone_a').val(),
            'zone_b': $('#zone_b').val(),
            'zone_c': $('#zone_c').val(),
            'zone_d': $('#zone_d').val(),
            'zone_e': $('#zone_e').val(),
            'zone_f': $('#zone_f').val(),
            'min_delivery_time': $('#min_delivery_time').val(),
            'max_delivery_time': $('#max_delivery_time').val(),
            'additional_grams': $('#additional_grams').val(),
            'additional_price_add_in_every_zone': $('#additional_price_add_in_every_zone').val(),
            'active': 1
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-services', parameters);
        if (response.status == 200) {
            if (response.data.code == "success") {
                common.alert(response.data.message);
            } else {
                common.alert(response.data.error, 'error');
            }
            navigate('/SHome');
        }
    }

    const deleteService = async (data) => {
        var parameters = {
            'id':  data.id,
            "service_id": data.seller_service_pincodes.service_id,
            'action': 'delete',
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-services', parameters);
        if (response.status == 200) {
            if (response.data.code == "success") {
                common.alert(response.data.message);
            } else {
                common.alert(response.data.error, 'error');
            }
            navigate('/seller/services');
        }
        getServiceData()
    }

    const columnDefs = useMemo(() => [
        {
            field: "service_name",
            headerName: "Service Name",
            checkboxSelection: true,
            editable: true,
        },
        { field: "parent_category.category_name", headerName: "Parent Category" },
        { field: "sub_category.category_name", headerName: "Sub Category" },
        { field: "service_type", headerName: "Service Type" },
        {
            field: "service_image",
            headerName: "Service Image",
            cellRenderer: (params) => {
                return params.value ? (
                    <div>
                        <img src={envConfig.api_url + params.value} style={{height: '100px', width: '100px'}}/>
                        <input type="file" className="mt-3" accept="image/*" onChange={(e) => uploadServiceImage(e.target.files, params.data.id)} />
                    </div>
                ) : (
                    <input type="file" accept="image/*" onChange={(e) => uploadServiceImage(e.target.files, params.data.id)} />
                );
            }
        },
        { field: "service_description", headerName: "Service Description" },
        { field: "price", headerName: "Service Price" },
        { field: "service_sku", headerName: "Service SKU" },
        { field: "service_by", headerName: "Service By" },
        { field: "gst_rate", headerName: "GST Rate" },
        { field: "taxable_value", headerName: "Taxable Value" },
        { field: "supply_from_state", headerName: "Supply From State" },
        { field: "supply_from_gst_no", headerName: "Supply From GST No" },
        { field: "logistics_delivery_type", headerName: "Logistics Delivery Type" },
        { field: "range_in_grams_from", headerName: "Range In Grams From" },
        { field: "range_in_grams_to", headerName: "Range In Grams To" },
        { field: "zone_a", headerName: "Zone A" },
        { field: "zone_b", headerName: "Zone B" },
        { field: "zone_c", headerName: "Zone C" },
        { field: "zone_d", headerName: "Zone D" },
        { field: "zone_e", headerName: "Zone E" },
        { field: "zone_f", headerName: "Zone F" },
        { field: "min_delivery_time", headerName: "Minimum Delivery Time" },
        { field: "max_delivery_time", headerName: "Maximum Delivery Time" },
        { field: "additional_grams", headerName: "Additional Grams" },
        { field: "additional_price_add_in_every_zone", headerName: "Additional Price Add In Every Zone" },
        {
            field: "active",
            headerName: "Approve",
            cellRenderer: (params) => params.value == "1" ? "approve" : "not approve"
        },
        {
            headerName: "Pincodes",
            cellRenderer: (params) => (
                <button className='btn btn-info btn-sm' onClick={() => viewPincodes(params.data.seller_service_pincodes.length > 0 ? params.data.seller_service_pincodes[0].pincode : null)}>View Pincodes</button>
            )
        },
        {
            headerName: "Edit",
            cellRenderer: (params) => (
                <button className='btn btn-primary btn-sm' onClick={() => showEditDetails(params.data)}>Edit</button>
            )
        },
        {
            headerName: "Delete",
            cellRenderer: (params) => (
                <button className='btn btn-primary btn-sm' onClick={() => deleteService(params.data)}>Delete</button>
            )
        }
    ], []);

    const defaultColDef = useMemo(() => ({
        filter: 'agTextColumnFilter',
        floatingFilter: true,
    }), []);

    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <h3>Seller Services</h3>
                </div>
                <CSVLink filename={"Seller Services"} className="btn button-product" target="_blank" data={csvData}>Download Csv</CSVLink>
            </div>
            <div className='row tables'>
                <div className='col-md-12'>
                    <div className="ag-theme-quartz" style={{ height: 600, width: '100%' }}>
                        <AgGridReact
                            rowData={servicesData}
                            columnDefs={columnDefs}
                            defaultColDef={defaultColDef}
                            rowSelection="multiple"
                            suppressRowClickSelection={true}
                            pagination={true}
                            paginationPageSize={10}
                            paginationPageSizeSelector={[10, 25, 50]}
                        />
                    </div>
                </div>
            </div>
            <Modal isOpen={pincodeModal} toggle={togglePincodeModal} size="md">
                <ModalHeader toggle={togglePincodeModal}>Pincodes List</ModalHeader>
                <ModalBody>
                    {
                        pincodes.map((pincode) => {
                            return (
                                <>
                                    {pincode.pincode}&nbsp;&nbsp;
                                </>
                            )
                        })
                    }
                </ModalBody>      
            </Modal>
            
            <Modal isOpen={editModal} toggle={toggleEditModal} size="lg">
                <ModalHeader toggle={toggleEditModal}>Edit Product Details</ModalHeader>
                <ModalBody>
                            {Object.keys(editservicesData).length > 0 ?(
                                    <Form>
                                            <FormGroup>
                                                <Label for="newEntry">service_name</Label>
                                                <Input
                                                    type='text' id="service_name" defaultValue={editservicesData.service_name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new service_type</Label>
                                                <Input
                                                    type='text' id="service_type" defaultValue={editservicesData.service_type}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">service_description</Label>
                                                <Input
                                                    type='text' id="service_description" defaultValue={editservicesData.service_description}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new price</Label>
                                                <Input
                                                    type='text' id="price" defaultValue={editservicesData.price}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new service_sku</Label>
                                                <Input
                                                    type='text' id="service_sku" defaultValue={editservicesData.service_sku}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new service_by</Label>
                                                <Input
                                                    type='text' id="service_by" defaultValue={editservicesData.service_by}
                                                />
                                            </FormGroup>
                                            
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new gst_rate</Label>
                                                <Input
                                                    type='text' id="gst_rate" defaultValue={editservicesData.gst_rate}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new taxable_value</Label>
                                                <Input
                                                    type='text' id="taxable_value" defaultValue={editservicesData.taxable_value}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new supply_from_state</Label>
                                                <Input
                                                    type='text' id="supply_from_state" defaultValue={editservicesData.supply_from_state}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new supply_from_gst_no</Label>
                                                <Input
                                                    type='text' id="supply_from_gst_no" defaultValue={editservicesData.supply_from_gst_no}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new logistics_delivery_type</Label>
                                                <Input
                                                    type='text' id="logistics_delivery_type" defaultValue={editservicesData.logistics_delivery_type}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new range_in_grams_from</Label>
                                                <Input
                                                    type='text' id="range_in_grams_from" defaultValue={editservicesData.range_in_grams_from}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new range_in_grams_to</Label>
                                                <Input
                                                    type='text' id="range_in_grams_to" defaultValue={editservicesData.range_in_grams_to}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone_a</Label>
                                                <Input
                                                    type='text' id="zone_a" defaultValue={editservicesData.zone_a}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone_d</Label>
                                                <Input
                                                    type='text' id="zone_d" defaultValue={editservicesData.zone_d}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone_c</Label>
                                                <Input
                                                    type='text' id="zone_c" defaultValue={editservicesData.zone_c}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone_d</Label>
                                                <Input
                                                    type='text' id="zone_d" defaultValue={editservicesData.zone_d}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone_e</Label>
                                                <Input
                                                    type='text' id="zone_e" defaultValue={editservicesData.zone_e}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new zone_f</Label>
                                                <Input
                                                    type='text' id="zone_f" defaultValue={editservicesData.zone_f}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new min_delivery_time</Label>
                                                <Input
                                                    type='text' id="min_delivery_time" defaultValue={editservicesData.min_delivery_time}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new max_delivery_time</Label>
                                                <Input
                                                    type='text' id="max_delivery_time" defaultValue={editservicesData.max_delivery_time}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new additional_grams</Label>
                                                <Input
                                                    type='text' id="additional_grams" defaultValue={editservicesData.additional_grams}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new additional_price_add_in_every_zone</Label>
                                                <Input
                                                    type='text' id="additional_price_add_in_every_zone" defaultValue={editservicesData.additional_price_add_in_every_zone}
                                                />
                                            </FormGroup>
                                            <Button className='submit'  onClick={addDetail}>
                                              Submit
                                          </Button>
                                    </Form>
                                ):""}

                            </ModalBody>
                                 
            </Modal>
        </>
    );
};

export default SellerServices;