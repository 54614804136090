// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container{
  margin-bottom:5%;
}

#form-header{
  margin-top:5%;
  text-align:center;
}
.productdetails_img{
  width: 100%;
  height: auto;
}
#form-tagline{
  background: #ff6d00;
  border-top-left-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
  color:#ffffff;
  margin-top:5%;
  padding: 4%;
  text-align:left;
}

#form-tagline .fa{
  margin-bottom:15%;
}

#form-tagline h2 {
  margin-bottom:15%;
}

#form-content{
  background: #f4f4f4;
  border-top-right-radius: 0.5rem;
    border-bottom-right-radius:0.5rem;
  margin-top:5%;
  padding: 3%;
}
.form-control {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  height: 100%; 
}
.form-group{
  margin-top:5%;
}

.contact{
    padding: 4%;
    height: 400px;
}

.control-label{
  font-size:16px;
    font-weight:600;
}

div .submit-buttons{
  margin-top:3%;
  text-align:right;
}

.submit{
  white-space: normal;
  width:auto;
  background: #ff6d00 !important;
  color: #ffffff;
  font-weight: 600;
  /* width: 25%; */
}
`, "",{"version":3,"sources":["webpack://./src/components/Delivery/css/deliverydetails.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,aAAa;EACb,iBAAiB;AACnB;AACA;EACE,WAAW;EACX,YAAY;AACd;AACA;EACE,mBAAmB;EACnB,8BAA8B;IAC5B,iCAAiC;EACnC,aAAa;EACb,aAAa;EACb,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,mBAAmB;EACnB,+BAA+B;IAC7B,iCAAiC;EACnC,aAAa;EACb,WAAW;AACb;AACA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,aAAa;AACf;;AAEA;IACI,WAAW;IACX,aAAa;AACjB;;AAEA;EACE,cAAc;IACZ,eAAe;AACnB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,UAAU;EACV,8BAA8B;EAC9B,cAAc;EACd,gBAAgB;EAChB,gBAAgB;AAClB","sourcesContent":[".container{\n  margin-bottom:5%;\n}\n\n#form-header{\n  margin-top:5%;\n  text-align:center;\n}\n.productdetails_img{\n  width: 100%;\n  height: auto;\n}\n#form-tagline{\n  background: #ff6d00;\n  border-top-left-radius: 0.5rem;\n    border-bottom-left-radius: 0.5rem;\n  color:#ffffff;\n  margin-top:5%;\n  padding: 4%;\n  text-align:left;\n}\n\n#form-tagline .fa{\n  margin-bottom:15%;\n}\n\n#form-tagline h2 {\n  margin-bottom:15%;\n}\n\n#form-content{\n  background: #f4f4f4;\n  border-top-right-radius: 0.5rem;\n    border-bottom-right-radius:0.5rem;\n  margin-top:5%;\n  padding: 3%;\n}\n.form-control {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  white-space: nowrap;\n  height: 100%; \n}\n.form-group{\n  margin-top:5%;\n}\n\n.contact{\n    padding: 4%;\n    height: 400px;\n}\n\n.control-label{\n  font-size:16px;\n    font-weight:600;\n}\n\ndiv .submit-buttons{\n  margin-top:3%;\n  text-align:right;\n}\n\n.submit{\n  white-space: normal;\n  width:auto;\n  background: #ff6d00 !important;\n  color: #ffffff;\n  font-weight: 600;\n  /* width: 25%; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
