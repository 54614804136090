import React, { useState,useEffect} from 'react';
import { Link, useParams, useNavigate, NavLink, useLocation } from 'react-router-dom';
import { Table, Button, Modal, ModalHeader, ModalBody } from 'reactstrap';
const envConfig = require('../../config/envConfig');

const common = require('../../utils/Common');

function ServiceCardView() {
    const [serviceData, setServiceData] = useState([]);
    console.log(serviceData);
    const [inCart, setInCart] = useState([]);
    const attributes = useParams();
    const location = useLocation();
    let navigate = useNavigate();

    const fetchData = async () => {
        let data = {
            action: "read",
            source: "web",
            user_id: localStorage.getItem('userId')
        }
        if (attributes.category_id == 0) {
            data.search_string = location.state ? location.state : "";
        } else {
            data.category_id = attributes.category_id;
        }
        
        try {
          let response = await common.apiRequest('post', '/seller/crud-seller-services' , data);
          if (response.data.code == "success") {
            const data = response.data.data
           
                setServiceData(data);
   
          } else {
            common.alert(response.data.error, 'error');
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        }
    }

    useEffect(() => {
        fetchData();
        checkCart();   
    }, []);

    const addToCart = async (serviceId) => {
        let cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
        let newCartItem = {
          'product_id': 0,
          'product_item_id': 0,
          'quantity_purchased': 1,
          'user_id': localStorage.getItem('userId'),
          'supply_pincode': 0,
          'service_id': serviceId,
          'type': 'service',
          'action': 'create',
          'active': 0
        };
      
        let response = await common.apiRequest('post', '/products/add-remove-cart-items', newCartItem);
        let data = (response.data) ? response.data : [];
        newCartItem.cart_id = data.cart_id;
        delete newCartItem.user_id;
        delete newCartItem.action;
        cartItems.push(newCartItem);
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
      
        // Create a new array and update the state
        setInCart((prevInCart) => [...prevInCart, serviceId]);
        common.alert(data.message, 'success');
      };
      

      const removeFromCart = async (serviceId) => {
        let cartItems = JSON.parse(localStorage.getItem('cartItems'));
        if (cartItems) {
          const cartItemToRemove = cartItems.find(o => o.service_id == serviceId);
          cartItemToRemove.action = "delete";
          let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
          let data = (response.data) ? response.data : [];
      
          const updatedCartItems = cartItems.filter(item => item.service_id != serviceId);
          localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      
          // Filter out the removed service ID from the inCart state
          setInCart((prevInCart) => prevInCart.filter(id => id !== serviceId));
          common.alert(data.message, 'success');
        }
      };
      

    const checkCart = () => {
        var inCartItems = [];
        var cartItems = JSON.parse(localStorage.getItem('cartItems'));
        cartItems = cartItems.filter(o => {
            if (o.service_id != 0) {
                inCartItems.push(o.service_id);
            }
        })
        setInCart(inCartItems);
    }
    const movePage = (path, data) => {
        navigate(path,{state:data});
    }
    return (
        <React.Fragment>
            
                <div className="container">
                <div className="row service_card_top">
                    <div className="col-md-8">
                        <h3>Our Services Providers</h3>
                        <hr/>
                    </div>
                </div>

                {/* <!-- cart cards start --> */}
                <div className="row">
                    <div className="col-md-8">
                        {
                            serviceData.length > 0 ?
                            serviceData.map((data) => {
                                return (
                                    <>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <img className="userorderpageimg1" src={envConfig.api_url + data.service_image} alt="product"/>
                                            </div>
                                            <div className="col-md-10">
                                                <Link to={`/service/details/${data.id}`}>{data.user.user_business.business_name}</Link>
                                                <p className="text-danger float-right userordercardheader1 "><i className="fas fa-rupee-sign">{data.price}</i>
                                                </p>
                                                <p className="text-muted userordercardheader1">{data.service_name}</p>                               
                                                <p className="text-muted userordercardheader1">By {data.service_by}</p>
                                             
                                                <p><i className="fas fa-star productlist1">4.5</i></p>
                                                {
                                                    !inCart.includes(data.id) ? 
                                                    <button className="btn btn-info btn-sm btn-block" onClick={() => addToCart(data.id)}>Add To Cart</button>
                                                    :
                                                    <button className="btn btn-info btn-sm btn-block" onClick={() => removeFromCart(data.id)}>Remove From Cart</button>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )
                            })
                            : <h3><center>No Services Found</center></h3>
                        }
                        <hr/>
                    </div>
                </div>

                </div>
{/* <!-- service card  end--> */}

        </React.Fragment>
    )
}
export default ServiceCardView;