import React, { useState, useEffect } from 'react';
import { Input, FormGroup, Button, Label } from 'reactstrap';
import $ from 'jquery';
import CircularProgressBar from '../personal/CircularProgressBar';

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

const SellerInfo = () => {
    const [sellerData, setSellerData] = useState(null);
    const [isOtpVerified, setIsOtpVerified] = useState(false);
    const [useMobileNo,setUseMobileNo] = useState();
    const [completionPercentage, setCompletionPercentage] = useState(0);

    useEffect(() => {
        getSeller();
    }, []);
    useEffect(() => {
        if (sellerData && sellerData.length > 0) {
            calculateCompletionPercentage(sellerData[0]);
        }
    }, [sellerData]);
    
    const calculateCompletionPercentage = (sellerData) => {
        if (!sellerData) return;
    
        const fields = [
            'business_address',
            'business_address',
            'pan_no',
            'email',
            'mobile',
            'GST_no',
            'qualification',
            'occupation',
            'bank_account_no',
            'bank_name',
            'ifsc_code',
            'nominee_name',
            'relation_with_nominee',
            'nominee_dob',
            'nominee_percent',
            'nature_of_business',
            'business_name',
            'Point_Contact'
        ];
    
        const filledFields = fields.filter(field => sellerData[field] && sellerData[field].trim() !== '');
        const percentage = Math.round((filledFields.length / fields.length) * 100);
    
        setCompletionPercentage(percentage);
    };
    
    const getSeller = async () => {
        let data = {
            'seller_id': localStorage.getItem('sellerId'),
            'action': "read"
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-profile', data);
        if (response.data.code === 'success') {
            let data = response.data.data ? response.data.data : [];
            setSellerData(data);
            if (data.length > 0) {
                calculateCompletionPercentage(data[0]);
            }
        } else {
            setSellerData([]);
        }
    };

    const updateSellerinfo = async () => {
        let data = {
            'seller_id': localStorage.getItem('sellerId'),
            'action': "update",
            'business_person_name': $('#business_person_name').val(),
            'business_address': $('#business_address').val(),
            'email': $('#email').val(),
            'mobile': $('#mobile').val(),
            'qualification': $('#qualification').val(),
            'occupation': $('#occupation').val(),
            'bank_account_no': $('#bank_account_no').val(),
            'bank_name': $('#bank_name').val(),
            'ifsc_code': $('#ifsc_code').val(),
            'nominee_name': $('#nominee_name').val(),
            'relation_with_nominee': $('#relation_with_nominee').val(),
            'nominee_dob': $('#nominee_dob').val(),
            'nominee_percent': $('#nominee_percent').val(),
            'nature_of_business': $('#nature_of_business').val(),
            'business_name': $('#business_name').val(),
            'Point_Contact': $('#Point_Contact').val(),
            'cheque_copy': $('#cheque_copy').val(),
            'gst_proof_copy': $('#gst_proof_copy').val(),
        };
        let response = await common.apiRequest('post', '/seller/crud-seller-profile', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            getSeller();
        } else {
            setSellerData([]);
        }
    };

    if (!sellerData) {
        return <div>Loading...</div>; // Show a loading message while data is being fetched
    }

    if (sellerData.length === 0) {
        return <div>No seller data available.</div>; // Show a message if there's no seller data
    }

    const seller = sellerData[0];

    const verifyOtp = async (type) => {
        if (type === "sms" && $('#mobile_otp').val() === "") {
            common.alert("Please enter OTP received on SMS", 'error');
            return false;
        }
        let otpData = {
            "phone": useMobileNo,
            "channel": type,
            "code": $('#mobile_otp').val()
        };
        let response = await common.apiRequest('post', '/users/verify-otp', otpData);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            setIsOtpVerified(true);
            return true;
        } else {
            common.alert(response.data.error, 'error');
            return false;
        }
    }
    const sandOTP = async () => {
        var parameters = {
            'action': 'read',
            'seller_id': localStorage.getItem('userId'),
        };
        let response = await common.apiRequest('post', '/users/UserAddesOTPverify', parameters);
        common.alert(response.data.message)
        let data = (response.data && response.data.data) ? response.data.data : [];
        setUseMobileNo(data);
        setIsOtpVerified(false);
        // toggle();
    } 
    return (
        <>
               <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center mb-4">
                        <CircularProgressBar percentage={completionPercentage} />
                    </div>
                    <div className="col-12 text-center">
                        <h2>Profile Completion</h2>
                    </div>
                </div>
            </div>
            <div className='chnagepasscard'>
                <div className="card" style={{ width: "40%" }}>
                    <div className="card-body">
                    <FormGroup>
                    <Label for="newEntry">Name</Label>
                    <Input  defaultValue={seller.user_business.business_person_name}
                        type='text' id="business_person_name" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Address</Label>
                    <Input defaultValue={seller.user_business.business_address}
                        type='text' id="business_address" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Pan No</Label>
                    <Input defaultValue={seller.pan_no}
                        type='text' id="Pan_no" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Email</Label>
                    <Input defaultValue={seller.user_business.email}
                        type='text' id="email" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Mobile No</Label>
                    <Input defaultValue={seller.user_business.mobile}
                        type='text' id="mobile" 
                    />
                </FormGroup>
                 <FormGroup>
                    <Label for="newEntry">GST No</Label>
                    <Input defaultValue={seller.seller_addresses[0].gst_no}
                        type='text' id="GST_no" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Qualification</Label>
                    <Input defaultValue={seller.user_business.qualification}
                        type='text' id="qualification" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Occupation</Label>
                    <Input defaultValue={seller.user_business.occupation}
                        type='text' id="occupation" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Bank Account N.</Label>
                    <Input defaultValue={seller.user_business.bank_account_no}
                        type='text' id="bank_account_no" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Bank Name</Label>
                    <Input defaultValue={seller.user_business.bank_name}
                        type='text' id="bank_name" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">RTGS/NEFT/IFSC Code of Bank</Label>
                    <Input defaultValue={seller.user_business.ifsc_code}
                        type='text' id="ifsc_code" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Nominee Name</Label>
                    <Input  defaultValue={seller.user_business.nominee_name}
                        type='text' id="nominee_name" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Relation with Nominee</Label>
                    <Input defaultValue={seller.user_business.relation_with_nominee}
                        type='text' id="relation_with_nominee" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Nominee Date of Birth</Label>
                    <Input  defaultValue={seller.user_business.nominee_dob}
                        type='text' id="nominee_dob" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Nominee % of Share</Label>
                    <Input defaultValue={seller.user_business?seller.user_business.nominee_percent:"100"}
                        type='text' id="nominee_percent" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Nature of Business</Label>
                    <Input defaultValue={seller.user_business.nature_of_business}
                        type='text' id="nature_of_business" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Business Name</Label>
                    <Input defaultValue={seller.user_business.business_name}
                        type='text' id="business_name" 
                    />
                </FormGroup>
                <FormGroup>
                    <Label for="newEntry">Point of Contact</Label>
                    <Input defaultValue={seller.user_business.Point_Contact}
                        type='text' id="Point_Contact" 
                    />
                </FormGroup>
                <Button onClick={() => sandOTP()} className="btn btn-outline-primary btn-sm">
                                            send OTP
                </Button>
                {!isOtpVerified && (
                        <div className='otp_verify'>
                            
                            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                <Label className="me-sm-2" for="mobile_otp">Mobile OTP</Label>
                                <Input type="number" className='inputs' id="mobile_otp" placeholder="1234" />
                                <Button type='button' id="mobile_otp_verify_button" className='submit-button' onClick={() => verifyOtp('sms')}>Verify OTP</Button>
                            </FormGroup>
                        </div>
                    )}
                                                {isOtpVerified && (

                        <Button className='submit' onClick={updateSellerinfo}>
                            Submit
                        </Button>
                         )}
                    </div>
                </div>
            </div>
        </>
    );
}

export default SellerInfo;
