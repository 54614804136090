import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Modal, ModalHeader, ModalBody, Input, FormGroup, Button, Label, Form, ModalFooter } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
const common = require('../../utils/Common');
const axios = require('axios');

function SellerProfile() {
    let navigate = useNavigate();
    const editAddress = "";
    const [useMobileNo,setUseMobileNo] = useState();
    const [userData, setUserData] = useState([]);
    const [businessuserAddress, setUserBusinessAddress] = useState([]);
    const [modal, setModal] = useState(false);
    const [address, setAddress] = useState(editAddress ? editAddress : {});
    const [userLocation, setUserLocation] = useState(null);
    const [isOtpVerified, setIsOtpVerified] = useState(false);

    const toggle = () => setModal(!modal);
    const handleChange = (e) => {
        setAddress({ ...address, [e.target.id]: e.target.value });
    }

    useEffect(() => {
        getBusinessUserAddress();
    }, []);

    const getBusinessUserAddress = async () => {
        var parameters = {
            'action': 'read',
            'seller_id': localStorage.getItem('userId'),
        };
        let response = await common.apiRequest('post', '/users/seller-address', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setUserBusinessAddress(data);
    }

    const fetchCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(async (position) => {
                const { latitude, longitude } = position.coords;
                setUserLocation({ latitude, longitude });
                await axios({
                    method: 'GET',
                    url: 'https://apis.mapmyindia.com/advancedmaps/v1/907c23d61775c5c5d7c650856e8bf8e4/rev_geocode?lat=' + latitude + '&lng=' + longitude,
                }).then((result) => {
                    setAddress({
                        'address': result.data.results[0]['formatted_address'],
                        'state': result.data.results[0]['state'],
                        'pincode': result.data.results[0]['pincode'],
                        'sub_district': result.data.results[0]['subDistrict'],
                        'district': result.data.results[0]['district'],
                        'village_locality_name': result.data.results[0]['village'] + " " + result.data.results[0]['subLocality'] + " " + result.data.results[0]['locality'],
                    });
                });
            },
                (error) => {
                    console.error('Error getting user location : ', error);
                });
        } else {
            console.error('Geolocation is not supported');
        }
    }

    const addAddressDetail = async () => {
        var parameters = {
            'seller_id': localStorage.getItem('userId'),
            'address': $('#address').val(),
            'village_locality_name': $('#village_locality_name').val(),
            'sub_district': $('#sub_district').val(),
            'district': $('#district').val(),
            'state': $('#state').val(),
            'pincode': $('#pincode').val(),
            'gst_no': $('#gst_no').val(),
            "action": editAddress ? "update" : "create",
        };
        if (editAddress) {
            parameters.id = editAddress.id;
        }

        let response = await common.apiRequest('post', '/users/seller-address', parameters);
        if (response.data.code === "success") {
            common.alert(response.data.message);
        } else {
            console.error('Error:', response.data.error);
            common.alert(response.data.error, 'error');
        }
        window.location.href = "/seller/profile";
    }


    const deleteBusinessAddress = async (id) => {
        let data = {
            "id": id,
            "action": "delete"
        };
        let response = await common.apiRequest('post', '/users/seller-address', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
        } else {
            common.alert(response.data.error, 'error');
        }
        window.location.href = "/seller/profile";
    }

    const verifyOtp = async (type) => {
        if (type === "sms" && $('#mobile_otp').val() === "") {
            common.alert("Please enter OTP received on SMS", 'error');
            return false;
        }
        let otpData = {
            "phone": useMobileNo,
            "channel": type,
            "code": $('#mobile_otp').val()
        };
        let response = await common.apiRequest('post', '/users/verify-otp', otpData);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            setIsOtpVerified(true);
            return true;
        } else {
            common.alert(response.data.error, 'error');
            return false;
        }
    }

    const addAddress = async (address) => {
        // var parameters = {
        //     'action': 'read',
        //     'seller_id': localStorage.getItem('userId'),
        // };
        // let response = await common.apiRequest('post', '/users/UserAddesOTPverify', parameters);
        // common.alert(response.data.message)
        // let data = (response.data && response.data.data) ? response.data.data : [];
        // setUseMobileNo(data);
        setAddress(address);
        // setIsOtpVerified(false);
        toggle();
    }
    const sandOTP = async () => {
        var parameters = {
            'action': 'read',
            'seller_id': localStorage.getItem('userId'),
        };
        let response = await common.apiRequest('post', '/users/UserAddesOTPverify', parameters);
        common.alert(response.data.message)
        let data = (response.data && response.data.data) ? response.data.data : [];
        setUseMobileNo(data);
        setIsOtpVerified(false);
        // toggle();
    } 
    return (
        <React.Fragment>
            <div className="container addselpage">
                <h2>My Business Addresses</h2>
                <div className="row offset-md-1">
                    <div className="col-md-4">
                        <Button className="btn bg-transparent m-0 p-0" onClick={() => addAddress({ editAddress: "" })}>
                            <div className="card addselpage2">
                                <div className="card-body mtcard">
                                    <i className="fas fa-plus-square fa-3x"></i>
                                    <h5 className="card-title">Add address</h5>
                                </div>
                            </div>
                        </Button>
                    </div>
                    {businessuserAddress.map((address) => (
                        <div className="col-md-4" key={address.id}>
                            <div className="card addselpage1">
                                <div className="card-body">
                                    <p className="my-0 py-0">{address.address}</p>
                                    <p className="my-0 py-0">{address.village_locality_name}</p>
                                    <p className="my-0 py-0">{address.sub_district}</p>
                                    <p className="my-0 py-0">{address.district}</p>
                                    <p className="my-0 py-0">{address.state}</p>
                                    <p className="my-0 py-0">{address.pincode}</p>
                                    <p className="my-0 py-0">{address.gst_no}</p>
                                    <div className="card-footer bg-transparent">
                                        <Button onClick={() => addAddress(address)} className="btn btn-outline-primary btn-sm">
                                            <i className="fas fa-pencil-alt"></i>
                                        </Button>
                                        <Button onClick={() => deleteBusinessAddress(address.id)} className="btn btn-outline-danger btn-sm">
                                            <i className="fas fa-trash-alt"></i>
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Modal isOpen={modal} toggle={toggle} size="xl">
                <ModalHeader toggle={toggle}>Add address</ModalHeader>
                <ModalBody>
                        <Form className='address-form'>
                            <div className='location-icon'>
                                <Button className='btn btn-primary' onClick={fetchCurrentLocation}>
                                    <i className='fas fa-map-marker'></i>
                                </Button>
                            </div>
                            <FormGroup>
                                <Label for="address">Address</Label>
                                <Input type='text' id="address" value={address.address ? address.address : ''} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="village_locality_name">Village Locality Name</Label>
                                <Input type='text' id="village_locality_name" value={address.village_locality_name ? address.village_locality_name : ''} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="sub_district">Sub-District</Label>
                                <Input type='text' id="sub_district" value={address.sub_district ? address.sub_district : ''} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="district">District</Label>
                                <Input type='text' id="district" value={address.district ? address.district : ''} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="state">State</Label>
                                <Input type='text' id="state" value={address.state ? address.state : ''} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="pincode">Pincode</Label>
                                <Input type='text' id="pincode" value={address.pincode ? address.pincode : ''} onChange={handleChange} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="gst_no">GST No</Label>
                                <Input type='text' id="gst_no" value={address.gst_no ? address.gst_no : ''} onChange={handleChange} />
                            </FormGroup>
                            <Button onClick={() => sandOTP(address)} className="btn btn-outline-primary btn-sm">
                                            send OTP
                            </Button>
                            {!isOtpVerified && (
                        <div className='otp_verify'>
                            
                            <FormGroup className="mb-2 me-sm-2 mb-sm-0">
                                <Label className="me-sm-2" for="mobile_otp">Mobile OTP</Label>
                                <Input type="number" className='inputs' id="mobile_otp" placeholder="1234" />
                                <Button type='button' id="mobile_otp_verify_button" className='submit-button' onClick={() => verifyOtp('sms')}>Verify OTP</Button>
                            </FormGroup>
                        </div>
                    )}
                            {isOtpVerified && (

                            <Button className='submit' onClick={addAddressDetail}>
                                Submit
                            </Button>
                            )}
                        </Form>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

export default SellerProfile;
