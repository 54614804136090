// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.maincardBox{
    justify-content: center;
    align-items: center;
    display: flex;
}

.userA{
    justify-content: center;
    align-items: center;
    display: flex;
    margin-top: 60px;
    margin-bottom: 30px;
}
.BuyerTitle{
    font-size: 30px;
    font-weight: 700;
    color: black;
}


.App {
    text-align: center;
  }
  
  .profile-container {
    position: relative;
    width: 200px;
    height: 200px;
    margin: 20px auto;
  }
  
  .profile-pic {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  
  .upload-button-label {
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
    cursor: pointer;
  }
  
  .upload-button {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3b5998; /* Facebook blue */
    color: white;
    border-radius: 25px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
.reff-button{
  width: 70px;
  height: 37px;
  background-color: #ffc107 !important;
  border-color:  #ffc107 !important;
}`, "",{"version":3,"sources":["webpack://./src/components/useraccount/userA.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,uBAAuB;IACvB,mBAAmB;IACnB,aAAa;IACb,gBAAgB;IAChB,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,YAAY;AAChB;;;AAGA;IACI,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,aAAa;IACb,iBAAiB;EACnB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;IACjB,kBAAkB;EACpB;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,2BAA2B;IAC3B,eAAe;EACjB;;EAEA;IACE,qBAAqB;IACrB,kBAAkB;IAClB,yBAAyB,EAAE,kBAAkB;IAC7C,YAAY;IACZ,mBAAmB;IACnB,eAAe;IACf,iBAAiB;IACjB,eAAe;EACjB;;AAEF;EACE,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,iCAAiC;AACnC","sourcesContent":[".maincardBox{\n    justify-content: center;\n    align-items: center;\n    display: flex;\n}\n\n.userA{\n    justify-content: center;\n    align-items: center;\n    display: flex;\n    margin-top: 60px;\n    margin-bottom: 30px;\n}\n.BuyerTitle{\n    font-size: 30px;\n    font-weight: 700;\n    color: black;\n}\n\n\n.App {\n    text-align: center;\n  }\n  \n  .profile-container {\n    position: relative;\n    width: 200px;\n    height: 200px;\n    margin: 20px auto;\n  }\n  \n  .profile-pic {\n    width: 100%;\n    height: 100%;\n    object-fit: cover;\n    border-radius: 50%;\n  }\n  \n  .upload-button-label {\n    position: absolute;\n    bottom: 10px;\n    left: 50%;\n    transform: translateX(-50%);\n    cursor: pointer;\n  }\n  \n  .upload-button {\n    display: inline-block;\n    padding: 10px 20px;\n    background-color: #3b5998; /* Facebook blue */\n    color: white;\n    border-radius: 25px;\n    font-size: 16px;\n    font-weight: bold;\n    cursor: pointer;\n  }\n  \n.reff-button{\n  width: 70px;\n  height: 37px;\n  background-color: #ffc107 !important;\n  border-color:  #ffc107 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
