import Body from '../body/Body'
import React ,{ Component } from 'react'

export default class SHome extends Component {
    render() {
        return (
            <div className="row">
                <Body/>
            </div>
        )
    }
}
