import React from 'react'
import MenuCard from './MenuCard';
import ProductsViaCategory from './productsViaCategory';
import Filterpage from './Filterpage';
const common = require('../../../utils/Common');

class MainView extends React.Component {
    constructor(props) {
        super(props);
        this.productData = this.productData.bind(this);
        this.state = {
            products : [],
            productsViaCategory: []
        }
    }

    componentDidMount() {
        this.productData();
    }
    
    productData = async () => {
        let parameters = {
            'user_id': localStorage.getItem('userId')
        }
        let response = await common.apiRequest('post', '/products/get-products', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        this.setState({
            products: data.products ? data.products : [],
            productsViaCategory: data.productsViaCategory ? data.productsViaCategory : []
        });
    }
    render() {
        return (
            <>
                <MenuCard products ={this.state.products} />
                {/* <ProductsViaCategory products ={this.state.productsViaCategory} />   */}
            </>
        )
    }   
}

export default MainView;

