import React, { useEffect, useState } from 'react';
import './report.css';
import { useCSVDownloader } from 'react-papaparse';
import jsPDF from "jspdf";
import "jspdf-autotable";
const common = require('../../../utils/Common');

export default function VendorServicesDetails () {

  const [reportData, setReportData] = useState([]);
  const [reportKeys, setReportKeys] = useState([]);
  const { CSVDownloader } = useCSVDownloader();
  const csvData = reportData.map((dataItem) => {
    const csvRow = {};
    reportKeys.forEach((key) => {
      csvRow[key] = dataItem[key];
    });
    return csvRow;
  });
  const exportPDF = () => {
    const unit = "pt";
    const size = "A1"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 10;
    const doc = new jsPDF(orientation, unit, size);
    doc.setFontSize(15);
    const title = "service report";
    const headers = reportKeys.map(key => {
        return key.charAt(0).toUpperCase() + key.slice(1);
    });

    const data = reportData.map(dataItem => {
        return reportKeys.map(key => dataItem[key]);
    });

    let content = {
        startY: 50,
        head: [headers],
        body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf");
};

  useEffect(() => {
    getReportData();
  }, []);

  const getReportData = async () => {
    var parameters = {
      'seller_id' : localStorage.getItem('sellerId'),
      'report_name' : 'service_reports'
    };
    let response = await common.apiRequest('post', '/reports/get-reports', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    setReportKeys(data.keys);
    setReportData(data.reportData);
  }

  return (
    <>
    <div className="row report-margin">
        <div className='col-md-6'>
            <h3>Service Reports</h3>
        </div>
        <div className='col-md-6 row'>
        <CSVDownloader className="csvDownload"
        filename={'service report'}
        data={() => csvData}
      >
        Download Csv
      </CSVDownloader>
      <button className="btn csvDownload" onClick={exportPDF}>Download pdf</button>
        </div>
      
    </div>
    <div className='row'>
      <div className='col-md-12'>
        <table class="orders-table table-responsive table-container">
          <thead>
            <tr>
              <th>Sl No.</th>
              {
                reportKeys.map((keys) => {
                  return (
                    <>
                      <th style={{"text-transform": "capitalize"}}>{keys.replaceAll('_', ' ')}</th>
                    </>
                  )
                })
              }
            </tr>
          </thead>
          <tbody>
            {
              reportData.map((data) => {
                return (
                  <>
                    <tr>
                    <td>---</td>
                    {
                      reportKeys.map((key) => {
                        return (
                            <>
                                <td>{data[key]}</td>
                            </>
                        )
                      })
                    }
                    </tr>
                  </>
                )
              })
            }
          </tbody>
        </table>
      </div>
    </div>
    </>
  )
}
