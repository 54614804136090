import React, { Component } from 'react'

export default class VendorProductsDetails extends Component {
  render() {
    return (
      <>
            <table class="orders-table report-margin table-container">
  <thead>
    <tr>
      <th>product_type</th>
      <th>size</th>
      <th>label</th>
      <th>Item Weight</th>
      <th>Appox Item Weight with packing</th>
      <th>HSN Code</th>
      <th>GST Rate %</th>
      <th>Product Taxable Value</th>
      <th>Supply from State Code</th>
      <th>Supply from GST No.</th>
      <th>Legal Disclaimer</th>
     <th>Warranty or Guarantee</th>
     <th>Use or Utility of Products</th>
     <th>After Sales Services</th>
     <th>FAQ</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>
      </>
    )
  }
}
