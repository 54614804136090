import React ,{ useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import { CSVLink, CSVDownload } from "react-csv";
import $ from 'jquery';
import jsPDF from "jspdf";
import "jspdf-autotable";
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

export default function SellerOrders() {
    const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
    const [deliveryUsers, setDeliveryUsers] = useState([]);
    const [orderStatuses, setOrderStatuses] = useState([]);
    useEffect(() => {
		getOrders();
        getDeliveryUsers();
	}, []);

    const getOrders = async () => {
        let data = {
            'seller_id' : localStorage.getItem('sellerId')
        }
        let response = await common.apiRequest('post', '/seller/get-orders', data);
        if (response.data.code == 'success') {
            setOrders(response.data.data);
            setOrderStatuses(await common.getSystemConfig('product_service_order_status'));
        } else {
            setOrders([]);
        }
    }

    const getDeliveryUsers = async () => {
        let data = {
            'action': 'read',
            'deliver_manager_id' : localStorage.getItem('sellerId')
        }
        let response = await common.apiRequest('post', '/delivery/crud-delivery-users', data);
        if (response.data.code == 'success') {
            setDeliveryUsers(response.data.data);
        } else {
            setDeliveryUsers([]);
        }
    }

    const assignDeliver = async (orderId, deliverId) => {
        let data = {
            'action': 'update',
            'id': orderId,
            'deliver_id' : parseInt(deliverId)
        }
        let response = await common.apiRequest('post', '/delivery/assign-deassign-orders', data);
        if (response.data.code == 'success') {
            common.alert(response.data.message);
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const changeOrderStatus = async (orderId, orderStatus, orderPrice, userId, previousStatus) => {
        if (previousStatus == "process_amount_to_wallet") {
            common.alert('Status cannot be changed now', 'error');
            return;
        }
        let data = {
            'action': 'update',
            'order_id': orderId,
            'order_status' : orderStatus,
            'order_price': orderPrice,
            'user_id': userId
        }
        let response = await common.apiRequest('post', '/orders/change-order-status-operations', data);
        if (response.data.code == 'success') {
            common.alert(response.data.message.replaceAll("_", " "));
            getOrders();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const generateInvoice = async (sellerId, orderId, invoiceType) => {
        let response = await common.getInvoiceData(sellerId, orderId, invoiceType);
        if (response.length == 0) {
            common.alert('No invoice', 'error');
            return;
        }
        navigate(
            '/generate-invoice',
            {
              state: {
                invoiceData: response
              }
            }
        )
    }

    const csvData = orders.map(order => ({
        OrderId: order.order_id,
        Name: order.name,
        Description: order.description,
        OrderPrice: order.order_price,
        OrderQuantity: order.order_quantity,
        OrderStatus: order.order_status,
        OrderCompletedOn: order.order_completed_on,
        OrderCancelledOn: order.order_cancelled_on,
        PaymentId: order.payment_id,
        PaymentStatus: order.payment_status,
      }));
      const exportPDF = () => {
        const unit = "pt";
        const size = "A1"; 
        const orientation = "portrait"; 
    
        const marginLeft = 10;
        const doc = new jsPDF(orientation, unit, size);
    
        doc.setFontSize(15);
    
        const title = "Order report";
        const headers = [["order_id", "Name","Description","OrderPrice","OrderQuantity","OrderStatus","OrderCompletedOn","PaymentId","PaymentStatus"]];
    
        const data = orders.map(elt => [elt.order_id, elt.name,elt.description,elt.order_price,elt.order_quantity,elt.order_status,elt.order_completed_on,
        elt.order_cancelled_on,elt.payment_id,elt.payment_status]);
        let content = {
          startY: 50,
          head: headers,
          body: data
        };
    
        doc.text(title, marginLeft, 40);
        doc.autoTable(content);
        doc.save("report.pdf")
      };
    return (
        <>
         <div className="row">
            <div className='col-md-6'>
                <h3>Orders</h3>
            </div>
            <div className='col-md-6'>
            <CSVLink filename={"Orders"} className="btn button-product" target="_blank" data={csvData}>Download Xml</CSVLink>
            <button className="btn button-product" onClick={exportPDF}>Download pdf</button>
            </div>
        </div>
        <div className='row'>
            <div className='col-md-12'>
                <div className='table-responsive table-container'>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                        <th>Order ID</th>
                        <th>Order Type</th>
                        <th>Seller/Logistics/Both Order</th>
                        <th>Product Name</th>
                        <th>Product Description</th>
                        <th>User Invoice No</th>
                        <th>User Return Invoice No</th>
                        <th>Seller Invoice No</th>
                        <th>Logistics Seller Invoice No</th>
                        <th>Order Price</th>
                        <th>Tax Rate</th>
                        <th>Taxable Amount</th>
                        <th>IGST Rate</th>
                        <th>IGST Amount</th>
                        <th>SGST/UTGST Rate</th>
                        <th>SGST/UTGST Amount</th>
                        <th>CGST Rate</th>
                        <th>CGST Amount</th>
                        <th>Order Quantity</th>
                        <th>Order Status</th>
                        <th>Order Completed On</th>
                        <th>Order Cancelled On</th>
                        <th>Payment ID</th>
                        <th>Payment Status</th>
                        <th>Assign Delivery Person</th>
                        <th>Generate Invoice</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            orders.map((order)=> {
                                return (
                                <tr>
                                    <td>{order.order.order_id}</td>
                                    <td>{order.product_item ? "Online Product Sale" : order.seller_service ? "Online Service Sale" : "Offline Product Sale"}</td>
                                    <td>{(order.logistics_vendor_id == localStorage.getItem('userId') && order.seller_id == localStorage.getItem('userId')) || (order.offline_sale_product_datum && order.offline_sale_product_datum.offline_sale && order.offline_sale_product_datum.offline_sale.seller_id == localStorage.getItem("userId")) ? "Both" : order.logistics_vendor_id == localStorage.getItem('userId') ? "Logistics" : "Seller"} Order</td>
                                    <td>{order.product_item ? order.product_item.product.name : order.seller_service ? order.seller_service.service_name : order.offline_sale_product_datum ? order.offline_sale_product_datum.product_description : "NA"}</td>
                                    <td>{order.product_item ? order.product_item.product.description : order.selelr_service ? order.seller_service.service_description : order.offline_sale_product_datum ? order.offline_sale_product_datum.product_description : "NA"}</td>
                                    <td>
                                        {order.order && order.order.invoices
                                        ? (order.order.invoices.find(invoice => invoice.invoice_type === 'product') || { invoice_no: "NA" }).invoice_no : "NA"}
                                    </td>
                                    <td>
                                        {order.order && order.order.invoices
                                        ? (order.order.invoices.find(invoice => invoice.invoice_type === 'return_order') || { invoice_no: "NA" }).invoice_no : "NA"}
                                    </td>
                                    <td>
                                        {order.order && order.order.invoices
                                        ? (order.order.invoices.find(invoice => invoice.invoice_type === 'seller_product') || { invoice_no: "NA" }).invoice_no : "NA"}
                                    </td>
                                    <td>
                                        {order.order && order.order.invoices
                                        ? (order.order.invoices.find(invoice => invoice.invoice_type === 'logistics') || { invoice_no: "NA" }).invoice_no : "NA"}
                                    </td>
                                    <td>{order.order_price}</td>
                                    <td>{order.tax_percent}</td>
                                    <td>{order.taxable_amount}</td>
                                    <td>{order.igst_percent}</td>
                                    <td>{order.igst}</td>
                                    <td>{order.sgst_percent}</td>
                                    <td>{order.sgst}</td>
                                    <td>{order.cgst_percent}</td>
                                    <td>{order.cgst}</td>
                                    <td>{order.order_quantity}</td>
                                    <td>
                                        <>
                                            <select id="order_status" value={order.order_status} className="form-control" onChange={(e) => changeOrderStatus(order.id, e.target.value, order.order_price, order.order.user_id, order.order_status)} style={{width: '130px'}}>
                                                {
                                                    orderStatuses && orderStatuses.product && (order.product_item || order.offline_sale_product_datum) ? Object.entries(orderStatuses.product).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    ))
                                                    :
                                                    orderStatuses && orderStatuses.service && order.seller_service ? Object.entries(orderStatuses.service).map(([key, value]) => (
                                                        <option key={key} value={key}>{value}</option>
                                                    )) : null
                                                }
                                            </select>
                                        </>
                                    </td>
                                    <td>{order.order_completed_on}</td>
                                    <td>{order.order_cancelled_on}</td>
                                    <td>{order.order.payment_detail ? order.order.payment_detail.razorpay_payment_id : ""}</td>
                                    <td>{order.order.payment_detail ? "Success" : "Failed"}</td>
                                    <td>
                                        {
                                            ((order.logistics_vendor_id == localStorage.getItem('userId')) || (order.product_item && order.product_item.self_deliver && order.seller_id == localStorage.getItem('userId')) || (order.seller_service && order.seller_service.seller_id == localStorage.getItem("userId")) || (order.offline_sale_product_datum && order.offline_sale_product_datum.offline_sale && order.offline_sale_product_datum.offline_sale.seller_id == localStorage.getItem("userId"))) ? 
                                            <>
                                                <select id="deliver_id" value={order.deliver_id} className='form-control' onChange={(e) => assignDeliver(order.id, e.target.value)}>
                                                    <option value="0">Select Delivery User</option>
                                                    {
                                                        deliveryUsers.map((user) => {
                                                            return (
                                                                <option value={user.id}>{user.first_name + " " + user.last_name + " (" + user.reg_id + ")"}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            </>
                                             : "NA"
                                        }
                                    </td>
                                    <button className="btn btn-outline-success w-50 btn-sm usercardbtn1" onClick={() => generateInvoice(order.seller_id, order.order.id, 'seller_product')}>Invoice Generate</button>
                                    <button className="btn btn-outline-success w-50 btn-sm usercardbtn1" onClick={() => generateInvoice(order.logistics_vendor_id, order.order.id, 'logistics')}>Logistics Invoice</button>
                                </tr>
                                );
                            })
                        }
                    </tbody>
                </Table>
                </div>
            </div>
        </div>
        </>
    )
}
