import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CSVLink, CSVDownload } from "react-csv";
import { Table, Modal, ModalBody, ModalHeader,Input, FormGroup, Button, Label,Form } from 'reactstrap';

import $ from 'jquery';
import envConfig from '../../config/envConfig';
const common = require('../../utils/Common');
const moment = require('moment');

const OfflineSales = () => {
    let navigate = useNavigate();
    const [offlineSalesData, setOfflineSalesData] = useState([]);
    const [modal, setModal] = useState(false);
    const [editData, setEditData] = useState({});
    const [productCount, setProductCount] = useState(1);
    const [productDetailsModal, setproductDetailsModal] = useState(false);
    const [productDetails, setproductDetails] = useState([]);

    const toggle = (data, type = 'enter') => {
        if (type == "enter") {
            setModal(!modal);
            setEditData(data);
        } else if (type == "view") {
            setproductDetailsModal(!productDetailsModal);
            setproductDetails(data);
        }
    }
    
    const getOfflineSalesData = async () => {
        let data = {
            'action': 'read',
            'seller_id': localStorage.getItem('sellerId')
        }
        let response = await common.apiRequest('post', '/orders/crud-offline-sale', data);
        if (response.data.code === 'success') {
            setOfflineSalesData(response.data.data);
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const updateOfflineSalesData = async () => {
        let productData = [];
        for(let i = 1; i <= productCount; i++) {
            let tempData = {
                'offline_sale_id': editData.id,
                'product_description': $('#product_description_' + i).val(),
                'hsn_code': $('#hsn_code_' + i).val(),
                'quantity': $('#quantity_' + i).val(),
                'price': $('#unit_price_' + i).val(),
                'sale_amount': $('#invoice_amount_' + i).val(),
                'discount': $('#discount_' + i).val(),
                'taxable_supply': $('#taxable_supply_' + i).val(),
                'igst_rate': 0,
                'igst_amount': 0,
                'cgst_rate': $('#cgst_rate_' + i).val(),
                'cgst_amount': $('#cgst_amount_' + i).val(),
                'sgst_utgst_rate': $('#sgst_rate_' + i).val(),
                'sgst_utgst_amount': $('#sgst_amount_' + i).val(),
            }
            productData.push(tempData);
        }
        let data = {
            'action': 'update_enter_details',
            'productData': productData
        }
        let response = await common.apiRequest('post', '/orders/crud-offline-sale', data);
        if (response.data.code === 'success') {
            common.alert(response.data.message);
            toggle(0);
            getOfflineSalesData();
        } else {
            common.alert(response.data.error, 'error');
        }
    }

    const calculateAmountsAndTaxes = (id) => {
        let quantity = $('#quantity_'+id).val() ? parseInt($('#quantity_'+id).val()) : 0;
        let unitPrice = $('#unit_price_'+id).val() ? parseFloat($('#unit_price_'+id).val()) : 0;
        let discount = $('#discount_'+id).val() ? parseFloat($('#discount_'+id).val()) : 0;
        let gst = $('#gst_'+id).val() ? parseFloat($('#gst_'+id).val()) : 0;
        let taxableValue = quantity * ((unitPrice-discount)/(100+gst)) * 100;
        let cgstRate = gst/2;
        let cgstAmount = (taxableValue * cgstRate)/100;
        let sgstRate = gst/2;
        let sgstAmount = (taxableValue * sgstRate)/100;
        let invoiceValue = taxableValue + cgstAmount + sgstAmount;
        $('#taxable_supply_'+id).val(parseFloat(taxableValue.toFixed(2)));
        $('#cgst_rate_'+id).val(parseFloat(cgstRate.toFixed(2)));
        $('#cgst_amount_'+id).val(parseFloat(cgstAmount.toFixed(2)));
        $('#sgst_rate_'+id).val(parseFloat(sgstRate.toFixed(2)));
        $('#sgst_amount_'+id).val(parseFloat(sgstAmount.toFixed(2)));
        $('#invoice_amount_'+id).val(parseFloat(invoiceValue.toFixed(2)));
    }

    const addMoreProductDetails = (count) => {
        count++;
        setProductCount(count);
        $('#product_details').append(`<tr>
            <td><input type="text" /></td>
            <td><input type="text" id="product_description_${count}"/></td>
            <td><input type="text" id="hsn_code_${count}"/></td>
            <td><input type="number" id="quantity_${count}"/></td>
            <td><input type="number" id="unit_price_${count}"/></td>
            <td><input type="number" id="discount_${count}"/></td>
            <td><input type="number" id="gst_${count}"/></td>
            <td><input type="number" id="taxable_supply_${count}" disabled /></td>
            <td className="nowrap">
                <input type="number" id="cgst_rate_${count}" disabled />&nbsp;
                <input type="number" id="cgst_amount_${count}" disabled />
            </td>
            <td className="nowrap">
                <input type="number" id="sgst_rate_${count}" disabled />&nbsp;
                <input type="number" id="sgst_amount_${count}" disabled />
            </td>
            <td className="nowrap">
                <input type="number" id="invoice_amount_${count}" disabled />
            </td>
        </tr>`);

        $(`#quantity_${count}, #unit_price_${count}, #discount_${count}, #gst_${count}`).on('input', function() {
            calculateAmountsAndTaxes(count);
        });
    }
        
    
    useEffect(() => {
        getOfflineSalesData();
    }, []);

    return (
        <>
            <div className="row">
                <div className="col-md-10">
                    <h3>Offline Sales</h3>
                </div>
            </div>
            <div className='row tables'>
                <div className='col-md-12 '>
                    <div className='table-responsive table-container'>
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>S.No</th>
                                    <th>Order Id</th>
                                    <th>User Invoice No</th>
                                    <th>Seller Invoice No</th>
                                    <th>Buyer User Id</th>
                                    <th>Buyer Name</th>
                                    <th>Buyer Email</th>
                                    <th>User PAN</th>
                                    <th>Seller User Id</th>
                                    <th>Seller Name</th>
                                    <th>Seller Email</th>
                                    <th>Seller PAN</th>
                                    <th>Seller GST No</th>
                                    <th>Products</th>
                                    <th>Total Taxable Supply</th>
                                    <th>Total Sale Amount</th>
                                    <th>Delivery Status</th>
                                    <th>Feedback</th>
                                    <th>Query</th>
                                    <th>Enter/View Product Details</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    offlineSalesData.map((data, index) => {
                                        const totalTaxableSupply = data.offline_sale_product_data ? data.offline_sale_product_data.reduce((sum, product) => sum + product.taxable_supply, 0) : 0;
                                        const totalSaleAmount = data.offline_sale_product_data ? data.offline_sale_product_data.reduce((sum, product) => sum + product.sale_amount, 0) : 0;
                                        const feedbacks = data.offline_sale_product_data && data.offline_sale_product_data.order_item && data.offline_sale_product_data.order_item.feedbacks;
                                        const feedbackToShow = feedbacks && feedbacks.length > 1 ? feedbacks[feedbacks.length - 1]['user_feedback'] : (feedbacks && feedbacks.length === 1 ? feedbacks[0]['user_feedback'] : "No feedback available");
                                        const querys = data.offline_sale_product_data && data.offline_sale_product_data.order_item && data.offline_sale_product_data.order_item.querys;
                                        const queryToShow = querys && querys.length > 1 ? querys[querys.length - 1]['user_query'] : (querys && querys.length === 1 ? querys[0]['user_query'] : "No query available");
                                        const products = data.offline_sale_product_data;
                                        const productToShow = products && products.length > 1 ? "As per invoice" : (products && products.length === 1 ? products[0]['product_description'] : "No query available");
                                        return (
                                            <>
                                                <tr key={index}>
                                                    <td>{index + 1}</td>
                                                    <td>{data.order ? data.order.order_id : "Product details submission or payment need to be done yet" }</td>
                                                    <td>
                                                        {data.order && data.order.invoices
                                                        ? (data.order.invoices.find(invoice => invoice.invoice_type === 'product') || { invoice_no: "" }).invoice_no : "Product details submission or payment need to be done yet"}
                                                    </td>
                                                    <td>
                                                        {data.order && data.order.invoices
                                                        ? (data.order.invoices.find(invoice => invoice.invoice_type === 'seller_product') || { invoice_no: "" }).invoice_no : "Product details submission or payment need to be done yet"}
                                                    </td>
                                                    <td>{data.user ? data.user.reg_id : ""}</td>
                                                    <td>{data.user ? data.user.first_name + " " + data.user.last_name : ""}</td>
                                                    <td>{data.user ? data.user.email : ""}</td>
                                                    <td>{data.user ? data.user.pan_no : ""}</td>
                                                    <td>{data.seller ? data.seller.reg_id : ""}</td>
                                                    <td>{data.seller ? data.seller.first_name + " " + data.seller.last_name : ""}</td>
                                                    <td>{data.seller ? data.seller.email : ""}</td>
                                                    <td>{data.seller ? data.seller.pan_no : ""}</td>
                                                    <td>{data.seller_address ? data.seller_address.gst_no : ""}</td>
                                                    <td>{productToShow}</td>
                                                    <td>{totalTaxableSupply}</td>
                                                    <td>{totalSaleAmount}</td>
                                                    <td>{data.order ? 'Done' : 'Pending'}</td>
                                                    <td>{feedbackToShow}</td>
                                                    <td>{queryToShow}</td>
                                                    <td>
                                                        {
                                                            data.status == 0 ? <button className='btn btn-primary btn-sm' onClick={() => toggle(data)}>Enter Details</button> : <button className='btn btn-primary btn-sm' onClick={() => toggle(data.offline_sale_product_data, 'view')}>View Details</button>
                                                        }
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                }
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
            
            <Modal isOpen={modal} toggle={() => toggle({}, 'enter')} size="lg" expand="md">
                <ModalHeader toggle={() => toggle({}, 'enter')}>Enter Product Details</ModalHeader>
                <ModalBody>
                    <div className='bill-customer' id="testId">
                        <div className='invoice'>
                            <div className='invoice-head col-md-12'>
                                    <h2>Tax Invoice / Bill of Supply</h2>
                            </div>
                            <div className='customer-details'>
                                <div className='customer-details-head'>
                                    <h2>Customer Details:</h2>
                                </div>
                                <div className='bill-details row'>
                                    <div className='billing-address col-md-6'>
                                        <div className='billing-address-head'><h4>Billing Address :</h4></div>
                                        <div className='billing-address-details'>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Name :</label><div className='col-md-6'>{editData && editData.user ? editData.user.first_name + " " + editData.user.last_name : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Address :</label><div className='col-md-6'>{editData && editData.user_address ? editData.user_address.address : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Pincode :</label><div className='col-md-6'>{editData && editData.user_address ?  editData.user_address.pincode: ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>state :</label><div className='col-md-6'>{editData && editData.user_address ? editData.user_address.state : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Pan No :</label><div className='col-md-6'>{editData && editData.user ? editData.user.pan_no : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>E mail :</label><div className='col-md-6'>{editData && editData.user ? editData.user.email : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Mobile No :</label><div className='col-md-6'>{editData && editData.user ? editData.user.mobile_phone : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='shipping-address col-md-6'>
                                    <div className='shipping-address-head'><h4>Shipping Address :</h4></div>
                                    <div className='shipping-address-details'>
                                    <div className='user-address row'>
                                            <label className='col-md-6'>Name :</label><div className='col-md-6'>{editData && editData.user ? editData.user.first_name + " " + editData.user.last_name : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Address :</label><div className='col-md-6'>{editData && editData.user_address ? editData.user_address.address : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Pincode :</label><div className='col-md-6'>{editData && editData.user_address ?  editData.user_address.pincode: ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>State :</label><div className='col-md-6'>{editData && editData.user_address ? editData.user_address.state : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>E mail :</label><div className='col-md-6'>{editData && editData.user ? editData.user.email : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Mobile No :</label><div className='col-md-6'>{editData && editData.user ? editData.user.mobile_phone : ''}</div>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <div className='customer-details'>
                                <div className='customer-details-head'>
                                    <h4>Supply by:</h4>
                                </div>
                                <div className='bill-details row'>
                                    <div className='billing-address col-md-6'>
                                        {/* <div className='billing-address-head'><h4>Shipping By :</h4></div> */}
                                        <div className='billing-address-details'>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Name :</label><div className='col-md-6'>{editData && editData.seller && editData.seller.user_business ? editData.seller.user_business.business_name : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Address :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.address : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Pincode :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.pincode : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>GSTIN :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.gst_no : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>State :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.state : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Pan No :</label><div className='col-md-6'>{editData && editData.seller && editData.seller.user_business ? editData.seller.user_business.pan_no : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Place of supply :</label><div className='col-md-6'>{editData && editData.seller_address ? editData.seller_address.state : ''}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Place of delivery :</label><div className='col-md-6'>{editData && editData.user_address ? editData.user_address.state : ''}</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='shipping-address col-md-6'>
                                    <div className='shipping-address-details'>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Invoice Number :</label><div className='col-md-6'>Will Generate After Adding Order Details</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Invoice Date :</label><div className='col-md-6'>{moment().format("YYYY-MM-DD HH:mm:ss")}</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Order ID (SRRR Number) :</label><div className='col-md-6'>Will Generate After Adding Order Details</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Mode of Payment  :</label><div className='col-md-6'>Offline</div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Amount received  :</label><div className='col-md-6'>Rs. </div>
                                            </div>
                                            <div className='user-address row'>
                                            <label className='col-md-6'>Date & Time :</label><div className='col-md-6'>{moment().format("YYYY-MM-DD HH:mm:ss")}</div>
                                            </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                            <br/>
                            <div className='invoice-table'>
                                    <div className='invoice-table-head'>
                                        <h4>Goods wise Details:</h4>
                                    </div>
                                    <div className='invoice-table-body'>
                                    <table class="orders-table report-margin table-responsive">
                                        <thead>
                                            <tr>
                                            <th>S. No.</th>
                                            <th>Product Description</th>
                                            <th>HSN / SAC Code</th>
                                            <th>Qty</th>
                                            <th>Unit Price</th>
                                            <th>Discount</th>
                                            <th>GST %</th>
                                            <th>Taxable Value</th>
                                            <th>CGST
                                                <th>Rate %</th>
                                                <th>Amount</th>
                                            </th>
                                            <th>SGST / UTGST
                                                <th>Rate %</th>
                                                <th>Amount</th>
                                            </th>
                                            <th>Invoice Value</th>
                                            </tr>
                                        </thead>
                                        <tbody id="product_details">
                                            <tr>
                                                <td><input type="text" /></td>
                                                <td><input type="text" id="product_description_1"/></td>
                                                <td><input type="text" id="hsn_code_1"/></td>
                                                <td><input type="number" id="quantity_1" onInput={() => calculateAmountsAndTaxes(1)}/></td>
                                                <td><input type="number" id="unit_price_1" onInput={() => calculateAmountsAndTaxes(1)}/></td>
                                                <td><input type="number" id="discount_1" onInput={() => calculateAmountsAndTaxes(1)}/></td>
                                                <td><input type="number" id="gst_1" onInput={() => calculateAmountsAndTaxes(1)}/></td>
                                                <td><input type="number" id="taxable_supply_1"  disabled /></td>
                                                <td className="nowrap">
                                                    <input type="number" id="cgst_rate_1" disabled />&nbsp;
                                                    <input type="number" id="cgst_amount_1" disabled />
                                                </td>
                                                <td className="nowrap">
                                                    <input type="number" id="sgst_rate_1" disabled />&nbsp;
                                                    <input type="number" id="sgst_amount_1" disabled />
                                                </td>
                                                <td className="nowrap">
                                                    <input type="number" id="invoice_amount_1" disabled />
                                                </td>
                                            </tr>
                                        </tbody>
                                        <button type='button' className='btn btn-sm btn-primary' onClick={() => addMoreProductDetails(productCount)}>Add More</button>
                                    </table>
                                </div>
                            </div>
                            <div className='footer-data row'>
                                <b className='col-md-6'>Whether GST payable under reverse charge  ?</b>
                                <h6 className='col-md-6'>No</h6>
                            </div>
                            <div className='footer-data row'>
                                <div className='col-md-12 row'>
                                    <h5 className='col-md-6'>Remarks:</h5>
                                    <div className='col-md-6 row'>
                                        <label className='col-md-2'>for</label>
                                        <div className='col-md-4'>
                                            <b>Name:</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='footer-data row'>
                                <div className='col-md-12 row'>
                                    <h5 className='col-md-6'>Remarks:</h5>
                                    <div className='col-md-6'>
                                        <div className='Sign-image-vendor'>Sign image of vendor AS</div>
                                        <div className='Authorised-Signatory'>Authorised  Signatory</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className='btn btn-success btn-sm' onClick={updateOfflineSalesData}>Submit</button>
                </ModalBody>
            </Modal>

            <Modal isOpen={productDetailsModal} toggle={() => toggle([], 'view')} size="lg" expand="md">
                <ModalHeader toggle={() => toggle([], 'view')}>Product Details</ModalHeader>
                <ModalBody>
                <div className='row tables'>
                    <div className='col-md-12 '>
                        <div className='table-responsive table-container'>
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Product Description</th>
                                        <th>HSN Code</th>
                                        <th>Quantity</th>
                                        <th>Unit Price</th>
                                        <th>Discount</th>
                                        <th>GST %</th>
                                        <th>Taxable Supply</th>
                                        <th>SGST/UTGST Rate</th>
                                        <th>SGST/UTGST Amount</th>
                                        <th>CGST Rate</th>
                                        <th>CGST Amount</th>
                                        <th>Invoice Amount</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        productDetails.map((product) => {
                                            return (
                                                <>
                                                    <tr>
                                                        <td>{product.product_description ? product.product_description : ""}</td>
                                                        <td>{product.hsn_code ? product.hsn_code : ""}</td>
                                                        <td>{product.quantity ? product.quantity : 0}</td>
                                                        <td>{product.price ? product.price : 0}</td>
                                                        <td>{product.discount ? product.discount : 0}</td>
                                                        <td>{product.sgst_utgst_rate && product.cgst_rate ? product.sgst_utgst_rate + product.cgst_rate : 0}</td>
                                                        <td>{product.taxable_supply ? product.taxable_supply : 0}</td>
                                                        <td>{product.sgst_utgst_rate ? product.sgst_utgst_rate : 0}</td>
                                                        <td>{product.sgst_utgst_amount ? product.sgst_utgst_amount : 0}</td>
                                                        <td>{product.cgst_rate ? product.cgst_rate : 0}</td>
                                                        <td>{product.cgst_amount ? product.cgst_amount : 0}</td>
                                                        <td>{product.sale_amount ? product.sale_amount : 0}</td>
                                                    </tr>
                                                </>
                                            );
                                        })
                                    }
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
                </ModalBody>
            </Modal>
        </>
    );
};

export default OfflineSales;