import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/deliverynav.css'
const common = require('../../utils/Common');
const DeliveryNav = () => {
  const [isToggled, setIsToggled] = useState(false);

  const handleToggle = () => {
    setIsToggled((prevToggled) => !prevToggled);
  };

  let navigate = useNavigate();

  const logoutUser = async () => {
    if (localStorage.getItem('delivery_token')) {
      let data = {
        "access_token" : localStorage.getItem('delivery_token')
      }
      let response = await common.apiRequest('post', '/delivery/logout', data, 'delivery');
      if (response.status == 200) {
        if (response.data.code == 'success') {
          localStorage.removeItem('delivery_token');
          localStorage.removeItem("delivery_userId");
          localStorage.removeItem("delivery_userType");
          common.alert(response.data.message);
        } else {
          common.alert(response.data.error, 'error');
        }
      }
      else {
        common.alert('Something went wrong', 'error');
      }
    }
    navigate('/delivery/login');
  }

  return (
    <>
      <div id="wrapper" className={isToggled ? 'toggled' : ''}>
        <aside id="sidebar-wrapper">
          <div className="sidebar-brand">
            <h2>Logo</h2>
          </div>
          <ul className="sidebar-nav">
            <li className="active">
              <a href="/delivery/home"><i className="fa fa-home"></i>Home</a>
            </li>
            <li>
              <Link to="/delivery/products"><i className="fa fa-user"></i>Product</Link>
            </li>
          </ul>
        </aside>

        <div id="navbar-wrapper">
          <nav className="navbar navbar-inverse">
            <div className="container-fluid">
              <div className="navbar-header">
                <a href="#" className="navbar-brand" onClick={handleToggle}><i className="fa fa-bars"></i></a>
              </div>
            </div>
          </nav>
        </div>
        <button className='btn btn-warning float-right' onClick={() => logoutUser()}>Logout</button>
      </div>
    </>
  );
};

export default DeliveryNav;
