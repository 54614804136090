import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Button, CardBody, Card, Form, FormGroup, Label, Input, CardHeader, Col} from 'reactstrap';
const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

 function UserAccountView() {
    const [subProductImages, setSubProductImages] = useState([]);
    const user_id = localStorage.getItem('userId');

    const uploadSubProductImages = async (id) => {
        var fileData = new FormData();

        for (const key of Object.keys(subProductImages)) {
          fileData.append('use_profile_image', subProductImages[key]);
          fileData.append('id', id);
        }
        var response = await common.apiRequest('post', '/users/update-Profile-Images', fileData);
        common.alert(response.data.message);
        setSubProductImages([]);
        $('#use_profile_image').val('');
        // navigate('/seller/products');
    }
    return (
       <React.Fragment>
            {/* <!-- bread crumb --> */}
    <div className="container-fluid">
        <div className="row">
          <ol className="col-12 breadcrumb bg-transparent">
            <li className="breadcrumb-item "><Link to="/home">Home</Link></li>
            <li className="breadcrumb-item active">My Account</li>
          </ol>  
        </div>
      </div> 
      {/* <!-- bread crumb ends--> */}
      

    {/* <!-- user account start --> */}
    <div className="container mt-5">
        <div className="App">
      <h1>user info</h1>
    </div>
        <div className="row user1">
            <div className="col-md-4">
                <Link to="/orders/null" className="btn w-100">
                    <div className="card ">
                        <div className="card-body">
                            <div className="card-title">
                                <i className="fas fa-2x fa-cash-register"></i>
                                <h4>Your Order</h4>
                                <small className="text-muted">Track,Return or Buy.</small>
                            </div>                           
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-md-4">
                <Link to="/offline-orders" className="btn w-100">
                    <div className="card ">
                        <div className="card-body">
                            <div className="card-title">
                                <i className="fas fa-2x fa-cash-register"></i>
                                <h4>Offline Order</h4>
                                <small className="text-muted">Track & Pay.</small>
                            </div>                           
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-md-4">
                <Link to="/PersonalView" className="btn w-100">
                    <div className="card ">
                        <div className="card-body">
                            <div className="card-title">
                                <i className="fas fa-2x fa-user-secret"></i>
                                <h4>Change Personal Info</h4>
                                <small className="text-muted">Password, Email, Mobile.NO</small>
                            </div>                           
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-md-4">
                <Link to="address" className="btn w-100">
                    <div className="card ">
                        <div className="card-body">
                            <div className="card-title">
                                <i className="fas fa-2x fa-map-marker-alt"></i>
                                <h4>Address</h4>
                                <small className="text-muted">Deliver address ,Change address </small>
                            </div>                           
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-md-4">
                <Link to="/userServiceAvliable" className="btn w-100">
                    <div className="card ">
                        <div className="card-body">
                            <div className="card-title">
                                <i className="fab fa-2x fa-servicestack"></i>
                                <h4>service</h4>
                                <small className="text-muted">Avail Services</small>
                            </div>                           
                        </div>
                    </div>
                </Link>
            </div>

            <div className="col-md-4">
                <Link to="/FeedBackView" className="btn w-100">
                    <div className="card ">
                        <div className="card-body">
                            <div className="card-title">
                                <i className="fas fa-2x fa-clipboard"></i>
                                <h4>Feedback</h4>
                                <small className="text-muted">Feedback Info or Your Feedback</small>
                            </div>                           
                        </div>
                    </div>
                </Link>
            </div>
        </div>

        <div className="row user1">
            <div className="col-md-4">
                <Link to="/QueryView" className="btn w-100">
                    <div className="card ">
                        <div className="card-body">
                            <div className="card-title">
                                <i className="fas fa-2x fa-comment-dots"></i>
                                <h4>query</h4>
                                <small className="text-muted">Connect With Us Through Chat</small>
                            </div>                           
                        </div>
                    </div>
                </Link>
            </div>
            
            <div className="col-md-4">
                <Link to="/UserRefeanceDetails" className="btn w-100">
                    <div className="card ">
                        <div className="card-body">
                            <div className="card-title">
                                <i className="fab fa-2x fa-dropbox"></i>
                                <h4>Refer</h4>
                                <small className="text-muted">Check your referance details</small>
                            </div>                           
                        </div>
                    </div>
                </Link>
            </div>

           
           <div className="col-md-4">
               <Link to="/QRCode" className="btn w-100">
                   <div className="card ">
                       <div className="card-body">
                           <div className="card-title">
                               <i className="fab fa-2x fa-dropbox"></i>
                               <h4>QR</h4>
                               <small className="text-muted">Here is your qr</small>
                           </div>                           
                       </div>
                   </div>
               </Link>
            </div>
            <div className="col-md-4">
               <Link to="/UserDocs" className="btn w-100">
                   <div className="card ">
                       <div className="card-body">
                           <div className="card-title">
                               <i className="fab fa-2x fa-dropbox"></i>
                               <h4>Pan</h4>
                               <small className="text-muted">Here is your pan</small>
                           </div>                           
                       </div>
                   </div>
               </Link>
            </div>

            <div className="col-md-4">
                <Link to="wallet" className="btn w-100">
                    <div className="card ">
                        <div className="card-body">
                            <div className="card-title">
                                <i className="fas fa-2x fa-cash-register"></i>
                                <h4>Wallet</h4>
                                <small className="text-muted">Wallet Transactions </small>
                            </div>                           
                        </div>
                    </div>
                </Link>
            </div>
        
        <div className="row user1">
           
           
       </div>
        </div>
       
    </div>
    {/* <!-- user account end --> */}

       </React.Fragment>
    )
}
export default UserAccountView ;