// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.addselpage{
    margin-top: 3%;
    margin-bottom: 3%;
  }
  .addselpage1{
    width: 100%;
    height: 90%;
    margin-top: 4%;
    margin-bottom: 4%;
  }
  .addselpage2{
    width: 310px; 
    height: 295px;
    margin-top: 4%;
    margin-bottom: 4%;
  }
  .mtcard{
    margin-top: 25%;
  }
  
  `, "",{"version":3,"sources":["webpack://./src/components/seller-portal/seller_address/address.css"],"names":[],"mappings":"AAAA;IACI,cAAc;IACd,iBAAiB;EACnB;EACA;IACE,WAAW;IACX,WAAW;IACX,cAAc;IACd,iBAAiB;EACnB;EACA;IACE,YAAY;IACZ,aAAa;IACb,cAAc;IACd,iBAAiB;EACnB;EACA;IACE,eAAe;EACjB","sourcesContent":[".addselpage{\n    margin-top: 3%;\n    margin-bottom: 3%;\n  }\n  .addselpage1{\n    width: 100%;\n    height: 90%;\n    margin-top: 4%;\n    margin-bottom: 4%;\n  }\n  .addselpage2{\n    width: 310px; \n    height: 295px;\n    margin-top: 4%;\n    margin-bottom: 4%;\n  }\n  .mtcard{\n    margin-top: 25%;\n  }\n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
