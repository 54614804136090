import React, { useEffect, useState } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom';
import './mainproduct.css'
import $ from 'jquery';
import { BrowserRouter as Router, Route, useLocation } from 'react-router-dom';

const common = require('../../utils/Common');
const envConfig = require('../../config/envConfig');

function MainProductView() {
  let navigate = useNavigate();
  const attributes = useParams();
  var [productDesc, setProductDesc] = useState(0);
  var [addedToCart, setAddToCart] = useState(0);
  var [variantsName, setvariantsName] = useState([]);
  var [variantsValue, setvariantsValue] = useState([]);
  var [description, setDescription] = useState([]);
  var [feedbackStars, setFeedbackStars] = useState(0);
  var [allReviews, setAllReviews] = useState([]);
  var [productImage, setProductImage] = useState("");
  var [sameSellerData, setSameSellerData] = useState("");
  console.log(sameSellerData)
  var [newCat, setNewCat] = useState("");
  var [newimage, setNewimage] = useState("");
  var [subproductsd, setSubProductsd] = useState("");

  useEffect(() => {
    getProductDesciption();
    // sameSellerItems();
    checkCart();
    getAllReviews();
  }, [newCat]);


  const getProductDesciption = async () => {
    var parameters = {
      "product_id": attributes.product_id,
      "product_item_id": attributes.product_item_id
    };
    let response = await common.apiRequest('post', '/products/get-product', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    let image = data.map((newImage)=>{
      return(
        newImage.product_image
      )
    })
    setNewimage(image)
    
    setProductDesc(data[0]);
    setProductImage(newimage.length>0?newimage[0].split(','):"");
    let vName = data[0].variation_name ? data[0].variation_name.split(',') : [];
    let vValue = data[0].variation_value ? data[0].variation_value.split(',') : [];
    let desc = data[0].description ? data[0].description.split('.') : [];
    setNewCat(data[0].category_id)
    setvariantsName(vName);
    setvariantsValue(vValue);
    setDescription(desc);
    setSubProductsd(data);
    sameSellerItems();

    }
    const [mainImage, setMainImage] = useState(); // Initially set to the first image
    const handleThumbnailClick = (index) => {
      setMainImage(productImage[index]); // Change main image based on the index of clicked thumbnail
    };
  const sameSellerItems = async () => {
    var parameters = {
      "action":"read",
      "category_id":newCat,
      "seller_id":productDesc.seller_id,
      "product_id": attributes.product_id,
      "product_item_id": attributes.product_item_id
    };
    let response = await common.apiRequest('post', '/products/same-Seller-Items', parameters);
    let data = (response.data) ? response.data.data : [];
    setSameSellerData(data);
  }




  const addToCart = async (e) => {
    // e.preventDefault();
    var cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
    let newCartItem = {
      'product_id' : attributes.product_id,
      'product_item_id' : attributes.product_item_id,
      'quantity_purchased' : parseInt(document.getElementById('quantity').value),
      'user_id' : localStorage.getItem('userId'),
      'supply_pincode': productDesc.supply_pincode,
      'service_id': 0,
      'type': 'product',
      'action' : 'create',
      'self_deliver': productDesc.self_deliver,
      'active': 0
    }
    let response = await common.apiRequest('post', '/products/add-remove-cart-items', newCartItem);
    let data = (response.data) ? response.data : [];
    newCartItem.cart_id = data.cart_id;
    delete newCartItem.user_id;
    delete newCartItem.action;
    cartItems.push(newCartItem);
    localStorage.setItem('cartItems', JSON.stringify(cartItems));
    setAddToCart(1);
    common.alert(data.message, 'success');
    return;
  }
  const buyNow = async (e) => {
    if (checkCart(true)) {
      navigate('/cart');
    } else {
      addToCart();
    }
  }

  const removeFromCart = async (e) => {
    e.preventDefault();
    var cartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (cartItems) {
      const cartItemToRemove = cartItems.find(o => o.product_item_id == attributes.product_item_id);
      cartItemToRemove.action = "delete";
      let response = await common.apiRequest('post', '/products/add-remove-cart-items', cartItemToRemove);
      let data = (response.data) ? response.data : [];
      const updatedCartItems = cartItems.filter(item => {
        return item.product_item_id != attributes.product_item_id;
      });
      localStorage.setItem('cartItems', JSON.stringify(updatedCartItems));
      setAddToCart(0);
      common.alert(data.message, 'success');
      return;
    }
  }

  const checkCart = (checkForBuyNow = false) => {
    var cartItems = JSON.parse(localStorage.getItem('cartItems'));
    if (cartItems && cartItems.find(o => o.product_item_id == attributes.product_item_id)) {
      setAddToCart(1);
      if (checkForBuyNow) {
        return true;
      }
    }
  }

  const changeFeedbackStars = (value) => {
    setFeedbackStars(value);
    for (let i = 1; i <= 5; i++) {
      document.getElementById(i + 'star').style.color = '#ddd';
    }
    for (let i = 1; i <= value; i++) {
      document.getElementById(i + 'star').style.color = '#ffd700';
    }
  }

  const postFeedback = async (e) => {
    e.preventDefault();
    var parameters = {
      "product_item_id": attributes.product_item_id,
      "rating": feedbackStars,
      "review": document.getElementById('feedback').value,
      "user_id": localStorage.getItem('userId')
    };
    let response = await common.apiRequest('post', '/products/post-product-review', parameters);
    common.alert(response.data.message);
    document.getElementById('feedback').value = "";
    changeFeedbackStars(0);
    getAllReviews();
  }

  const getAllReviews = async () => {
    var parameters = {
      "product_id": attributes.product_item_id
    };
    let response = await common.apiRequest('post', '/products/get-product-review', parameters);
    if (response.data.data) {
      var first = 0; var second = 0; var third = 0; var fourth = 0; var fifth = 0;
      response.data.data.map((review) => {
        if (review.rating == 5) {
          fifth++;
        } else if (review.rating == 4) {
          fourth++;
        } else if (review.rating == 3) {
          third++;
        } else if (review.rating == 2) {
          second++;
        } else if (review.rating == 1) {
          first++;
        }
      });
      let total = response.data.data.length;
      if (total != 0) {
        first = (first * 100) / total;
        second = (second * 100) / total;
        third = (third * 100) / total;
        fourth = (fourth * 100) / total;
        fifth = (fifth * 100) / total;
      }
      document.getElementById('total-five-star').style.width = fifth + '%';
      document.getElementById('total-four-star').style.width = fourth + '%';
      document.getElementById('total-three-star').style.width = third + '%';
      document.getElementById('total-two-star').style.width = second + '%';
      document.getElementById('total-one-star').style.width = first + '%';
      setAllReviews(response.data.data);
    }
  }

  const checkAvailability = async () => {
    var pincodeArray;
    if (parseInt($('#pincode').val()) == productDesc.supply_pincode) {
      pincodeArray = [parseInt(productDesc.supply_pincode)];
    } else {
      pincodeArray = [parseInt($('#pincode').val()), parseInt(productDesc.supply_pincode)];
    }
    var data = {
      pincodes: pincodeArray,
      logistics_delivery_type: ['Normal Delivery', 'Express Delivery']
    }
    let response = await common.apiRequest('post', '/products/check-product-delivery-vendors', data);
    common.alert(response.data.code == "success" ? response.data.message : response.data.error, response.data.code == "success" ? "success" : "info");
  }
  function ScrollToTop() {
    const { pathname } = useLocation();
  
    React.useEffect(() => {
      window.scrollTo(0, 0);
    }, [pathname]);
  
    return null;
  }

  return (
    <>
    
      <div className="container-fluid">
     
      <ScrollToTop />
      {/* Your routes go here */}
    
        <div className="row">
          <div className="col">
            <div className="breadcrumbs d-flex flex-row align-items-center">
              <ul>
                <li><Link to="/">Home</Link></li>
                <li><Link to="/Filterpage"><i className="fa fa-angle-right" aria-hidden="true"></i>Men's</Link></li>
                <li className="active"><Link to="#"><i className="fa fa-angle-right" aria-hidden="true"></i>Single Product</Link></li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className='py-5'>
        <div className='container-fluid'>
          <div className='row'>
            <div className='col-lg-6'>
              
              <div className="border rounded-4 mb-3 d-flex justify-content-center">
                <Link data-fslightbox="mygalley" className="rounded-4" target="_blank" data-type="image" to="">
                  <img style={{ maxWidth: "100%", maxHeight: "60vh", margin: "auto" }} className="rounded-4 fit" src={envConfig.api_url + (mainImage?mainImage:productImage[0])} />
                </Link>
              </div>
              <div className="d-flex justify-content-center mb-3">
              {productImage.length>0?productImage.map((image, index) => (
                      <img width="60" height="60" className="rounded-2" key={index} src={envConfig.api_url + image}  onClick={() => handleThumbnailClick(index)} />
                )):""}
              </div>
              
              
              
            </div>
            
            <div className='col-lg-6'>
              <div className="ps-lg-3">
                <h4 className="title text-dark">
                {productDesc.name}
                </h4>
                <div className="d-flex flex-row my-3">
                  <div className="text-warning star mb-1">
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fa fa-star"></i>
                    <i className="fas fa-star-half-alt"></i>
                    <span className="ms-1">
                      4.5
                    </span>
                  </div>
                  <span className="text-muted star"><i className="fas fa-shopping-basket fa-sm mx-1"></i>154 orders</span>
                  <span className="text-success ms-2">In stock</span>
                </div>

                <div className="mb-3">
                  <span className="h5"><i className="fas fa-rupee-sign"/>{productDesc.price}</span>
                  <span className="text-muted">/per peace</span>
                </div>

                <p>
                  Modern look and quality demo item is a streetwear-inspired collection that continues to break away from the conventions of mainstream fashion. Made in Italy, these black and brown clothing low-top shirts for
                  men.
                </p>

                <div className="row">
                  <dt className="col-3">Deliverd By:</dt>
                  <dd className="col-9">{(new Date(new Date().getTime() + (5 * 24 * 60 * 60 * 1000)).toString().slice(0, 15))}</dd>

                  <dt className="col-3">Fastest Delivery:</dt>
                  <dd className="col-9">Tomorrow</dd>

                  <dt className="col-3">Emi:</dt>
                  <dd className="col-9">Starts witch 5000/- Per month</dd>
                </div>

                <hr />
              <div className="row mb-5 ml-1">
                  <div className="col-md-3 col-6">
                    <label className="mb-2 d-block">Size</label>
                    <select className="size-select border border-secondary" style={{ height: "35px" }}>
                      <option>Small</option>
                      <option>Medium</option>
                      <option>Large</option>
                    </select>
                  </div>
                <div className="col-md-3 col-6">
                  <label className='mb-2 d-block' for="quantity">Quantity</label>
                  <select className="size-select border border-secondary" style={{ height: "35px" }} id="quantity">
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                  </select>
                </div>
                <div className='col-md-6 mt-4'>
                  <input className="col-md-3 size-select border border-secondary mr-1" style={{ height: "35px" }} type="text" id="pincode" placeholder='Enter Pincode'/>
                  <button className="col-md-3 btn-check btn-warning shadow-0" onClick={checkAvailability}>Check</button>
                </div>
                </div>
            
                <div className='row'>
                    <div className='col-md-4 mt-5'>
                        {
                            !addedToCart ? 
                            <Link to="#" className="btn1 btn-primary shadow-0" onClick={addToCart}> <i className="me-1 fa fa-shopping-basket"></i> Add to cart </Link>
                            :
                            <Link to="#" className="btn1 btn-primary shadow-0" onClick={removeFromCart}> <i className="me-1 fa fa-shopping-basket"></i> Remove From Cart</Link>
                        }
                    </div>
                    <div className='col-md-4 mt-5'>
                        <Link to="/cart" className="btn1 btn-warning shadow-0" onClick={buyNow}> Buy now </Link>
                    </div>
                    <div className='col-md-4 mt-5'>
                        <Link to="/" className="btn1 btn-warning shadow-0"> <i className="me-1 fa fa-shopping-basket"></i> More shopping </Link>
                    </div>
                </div>

                
              </div>
            </div>
          </div>
        </div>
      </div> 
<div className="bg-light border-top py-4">
  <div className="container-fluid">
    <div className="row gx-4">
      <div className="col-lg-12 mb-4">
        <div className="border rounded-2 px-3 py-2 bg-white">
          <ul className="nav nav-pills nav-justified mb-3" id="ex1" role="tablist">
            <li className="nav-item d-flex" role="presentation">
              <a className="nav-link d-flex align-items-center justify-content-center w-100 active" id="ex1-tab-1" data-toggle="tab" href="#ex1-pills-1" role="tab" aria-controls="ex1-pills-1" aria-selected="true">Specification</a>
            </li>
            <li className="nav-item d-flex" role="presentation">
              <a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-2" data-toggle="tab"  href="#ex1-pills-2" role="tab" aria-controls="ex1-pills-2" aria-selected="false">Buyer Feedback</a>
            </li>
            <li className="nav-item d-flex" role="presentation">
              <a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-3" data-toggle="tab" href="#ex1-pills-3" role="tab" aria-controls="ex1-pills-3" aria-selected="false">Shipping info</a>
            </li>
            <li className="nav-item d-flex" role="presentation">
              <a className="nav-link d-flex align-items-center justify-content-center w-100" id="ex1-tab-4" data-toggle="tab" href="#ex1-pills-4" role="tab" aria-controls="ex1-pills-4" aria-selected="false">Seller profile</a>
            </li>
          </ul>
          <div className="tab-content" id="ex1-content">
            <div className="tab-pane fade show active" id="ex1-pills-1" role="tabpanel" aria-labelledby="ex1-tab-1">
              <p>
                great technology
              </p>
              <div className="row mb-2">
                <div className="col-12 col-md-6">
                  <ul className="list-unstyled mb-0">
                    <li><i className="fas fa-check text-success me-2"></i>Some great feature name here</li>
                    <li><i className="fas fa-check text-success me-2"></i>Optical heart sensor</li>
                  </ul>
                </div>
                <div className="col-12 col-md-6 mb-0">
                  <ul className="list-unstyled">
                    <li><i className="fas fa-check text-success me-2"></i>Easy fast and ver good</li>
                    <li><i className="fas fa-check text-success me-2"></i>Some great feature name here</li>
                    <li><i className="fas fa-check text-success me-2"></i>Modern style and design</li>
                  </ul>
                </div>
              </div>
             
              <table className="table border mt-3 mb-2">
              {
                  variantsName.map((variation, index) => {
                    return (
                      <>
                        <tr>
                  <th className="py-2">{variation.charAt(0).toUpperCase() + variation.slice(1)}</th>
                  <td className="py-2">{variantsValue[index]}</td>
                </tr>
                      </>
                    );
                  })
                }
               
              </table>
            </div>
            <div className="tab-pane fade mb-2" id="ex1-pills-2" role="tabpanel" aria-labelledby="ex1-tab-2">
            <div className='container'>
          <div className=" row">
          <div className="col-md-12">
            <h4 className="text-black front-weight-bold ">Customer Reviews</h4>
            <div className="dropdown-divider"></div>
          </div>
          <div className="col-md-5">
            <span className="rating">5 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-five-star"></div>
            </div>
            <span className="rating">4 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-four-star"></div>
            </div>
            <span className="rating">3 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-three-star"></div>
            </div>
            <span className="rating">2 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-two-star"></div>
            </div>
            <span className="rating">1 star rating</span>
            <div className="progress">
              <div className="progress-bar bg-warning" role="progressbar" id="total-one-star"></div>
            </div>
          </div>

          <div className="col-md-7">
            <div className='row'>
              <div className='col-md-2'>
                <label for="feedback" className="form-label font-weight-bold">Your Feedback :</label>
              </div>
              <div className='col-md-10'>
                <ul className="list-inline rating-list">
                  <li><i className="fa fa-star pl-3" id="5star" onClick={() => changeFeedbackStars(5)}></i></li>
                  <li><i className="fa fa-star pl-3" id="4star" onClick={() => changeFeedbackStars(4)}></i></li>
                  <li><i className="fa fa-star pl-3" id="3star" onClick={() => changeFeedbackStars(3)}></i></li>
                  <li><i className="fa fa-star pl-3" id="2star" onClick={() => changeFeedbackStars(2)}></i></li>
                  <li><i className="fa fa-star" id="1star" onClick={() => changeFeedbackStars(1)}></i></li>
                </ul>
                <textarea className="form-control" id="feedback" name="feedback" rows="6"></textarea>
                <button type="submit" className="mt-2 btn btn-primary" onClick={postFeedback}>Send Feedback</button>
              </div>
            </div>
          </div>

          <div className='col-md-12 mt-5'>
            <h4 className="text-capitalize">Top Reviews</h4>
            <div className="row mt-3">
              {
                allReviews.map((review) => {
                  let filledStars = [];
                  let unFilledStars = [];
                  for (let i = 1; i <= review.rating; i++) {
                    filledStars.push(1);
                  }
                  if (review.rating != 5) {
                    for (let i = review.rating; i < 5; i++) {
                      unFilledStars.push(1);
                    }
                  }
                  return (
                    <>
                      <div className="col-md-12">
                        <Link to="#" className="text-dark" style={{ textDecoration: "none" }}><img src="/assets/img/empty-avatar.png" width="34"
                          height="34" className="rounded-circle mr-2" />{review.user.first_name} {review.user.last_name}</Link>
                        <br />
                        <div className='mt-2'>
                          {
                            filledStars.map(() => {
                              return (
                                <i className='fa fa-star text-warning pr-2' style={{ fontSize: "22px" }}></i>
                              )
                            })
                          }
                          {
                            unFilledStars.map(() => {
                              return (
                                <i className='fa fa-star-o text-warning pr-2' style={{ fontSize: "22px" }}></i>
                              )
                            })
                          }
                        </div>
                        <p className='mt-2'>{review.review}</p>
                      </div>
                    </>
                  );
                })
              }
            </div>
            <div className="dropdown-divider mt-3"></div>

          </div>
        </div>
     

      </div>
            </div>
            <div className="tab-pane fade mb-2" id="ex1-pills-3" role="tabpanel" aria-labelledby="ex1-tab-3">
             
            </div>
            <div className="tab-pane fade mb-2" id="ex1-pills-4" role="tabpanel" aria-labelledby="ex1-tab-4">
             
            </div>
          </div>
         
        </div>
      </div>
    </div>
         
    <div className="col-lg-12">
  <div className="px-0 border rounded-2 shadow-0">
    <div className="card-body">
      <h5 className="card-title">Similar items</h5>
      {sameSellerData.length > 0 &&
        <div className="row">
          {sameSellerData.map((sameproduct, index) => {
  // Check if sameproduct.product_items is not empty and has at least one item
              if (sameproduct.product_items && sameproduct.product_items.length > 0) {
                return (
                  <div key={index} className="col-lg-4 mb-3">
                    <Link to={`/product/${sameproduct.id}/${sameproduct.product_items[0].id}`} className="text-decoration-none">
                      <div className="d-flex">
                        <a href="#" className="me-3">
                          <img src={envConfig.api_url + productImage} style={{ minWidth: "96px", height: "96px" }} className="img-md img-thumbnail" alt="Product" />
                        </a>
                        <div className="info">
                          <a href="#" className="nav-link mb-1">
                            {sameproduct.name}
                          </a>
                          {/* Check if sameproduct.product_items[0].price exists */}
                          {sameproduct.product_items[0].price && (
                            <strong className="text-dark mx-3"><i className="fas fa-rupee-sign"/> {sameproduct.product_items[0].price}</strong>
                          )}
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              } else {
    // Handle case where sameproduct.product_items is empty
    return null; // or render some fallback UI
  }
})}

        </div>
      }
    </div>
  </div>
</div>

  </div>
</div>

    </>
  )
}
export default MainProductView;
