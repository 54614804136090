// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.featured {
    margin-top: 120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  
  .featuredItem {
    flex: 1 1;
    margin: 0px 20px;
    padding: 30px;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);
  }
  .contenticons{
    width: 35px;
    height: 35px;
    margin-right: 5px;
  }

  .featuredTitle{
      font-size: 20px;
  }
  
  .featuredMoneyContainer{
      margin: 10px 0px;
      display: flex;
      align-items: center;
  }
  
  .featuredMoney{
      font-size: 30px;
      font-weight: 600;
  }
  
  .featuredMoneyRate{
      display: flex;
      align-items: center;
      margin-left: 20px;
  }
  
  .featuredIcon{
      font-size: 14px;
      margin-left: 5px;
      color: green;
  }
  
  .featuredIcon.negative{
      color: red;
  }
  
  .featuredSub{
      font-size: 15px;
      color: gray;
  }`, "",{"version":3,"sources":["webpack://./src/components/seller-portal/content/content.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,WAAW;IACX,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,SAAO;IACP,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,eAAe;IAEf,kDAAkD;EACpD;EACA;IACE,WAAW;IACX,YAAY;IACZ,iBAAiB;EACnB;;EAEA;MACI,eAAe;EACnB;;EAEA;MACI,gBAAgB;MAChB,aAAa;MACb,mBAAmB;EACvB;;EAEA;MACI,eAAe;MACf,gBAAgB;EACpB;;EAEA;MACI,aAAa;MACb,mBAAmB;MACnB,iBAAiB;EACrB;;EAEA;MACI,eAAe;MACf,gBAAgB;MAChB,YAAY;EAChB;;EAEA;MACI,UAAU;EACd;;EAEA;MACI,eAAe;MACf,WAAW;EACf","sourcesContent":[".featured {\r\n    margin-top: 120px;\r\n    width: 100%;\r\n    display: flex;\r\n    justify-content: space-between;\r\n  }\r\n  \r\n  .featuredItem {\r\n    flex: 1;\r\n    margin: 0px 20px;\r\n    padding: 30px;\r\n    border-radius: 10px;\r\n    cursor: pointer;\r\n    -webkit-box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);\r\n    box-shadow: 0px 0px 15px -10px rgba(0, 0, 0, 0.75);\r\n  }\r\n  .contenticons{\r\n    width: 35px;\r\n    height: 35px;\r\n    margin-right: 5px;\r\n  }\r\n\r\n  .featuredTitle{\r\n      font-size: 20px;\r\n  }\r\n  \r\n  .featuredMoneyContainer{\r\n      margin: 10px 0px;\r\n      display: flex;\r\n      align-items: center;\r\n  }\r\n  \r\n  .featuredMoney{\r\n      font-size: 30px;\r\n      font-weight: 600;\r\n  }\r\n  \r\n  .featuredMoneyRate{\r\n      display: flex;\r\n      align-items: center;\r\n      margin-left: 20px;\r\n  }\r\n  \r\n  .featuredIcon{\r\n      font-size: 14px;\r\n      margin-left: 5px;\r\n      color: green;\r\n  }\r\n  \r\n  .featuredIcon.negative{\r\n      color: red;\r\n  }\r\n  \r\n  .featuredSub{\r\n      font-size: 15px;\r\n      color: gray;\r\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
