import React from 'react'
import { Link } from 'react-router-dom'
import './homepage.css'
const CarouselCard = () => {
    const MyBackgroundImage ="assets/img/women.jpg"
    const MyBackgroundImage1 ="assets/img/child.jpg"
    const MyBackgroundImage2 ="assets/img/man.jpg"

    return (
        <>
            <div class="banner">
                <div class="container">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="banner_item align-items-center" style={{backgroundImage: `url(${MyBackgroundImage})`,height:"200px"}}>
                                <div class="banner_category">
                                    <Link to="/Filterpage">women's</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="banner_item align-items-center" style={{backgroundImage: `url(${MyBackgroundImage1})`,height:"200px"}}>
                                <div class="banner_category">
                                    <Link to="#">Childern's</Link>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="banner_item align-items-center" style={{backgroundImage: `url(${MyBackgroundImage2})`,height:"200px"}}>
                                <div class="banner_category">
                                    <Link to="#">men's</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CarouselCard