import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import Papa from 'papaparse';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

/**
 * Flatten a nested object into a flat object with dot notation keys.
 * @param {Object} obj - The object to flatten.
 * @param {string} [prefix=''] - The prefix for nested keys.
 * @returns {Object} - The flattened object.
 */
const flattenObject = (obj, prefix = '') => {
  let result = {};
  for (const key of Object.keys(obj)) {
    const value = obj[key];
    const newKey = prefix ? `${prefix}.${key}` : key;

    if (Array.isArray(value)) {
      // Handle arrays (e.g., product_items) by converting each element into individual fields
      value.forEach((item, index) => {
        if (typeof item === 'object' && item !== null) {
          Object.assign(result, flattenObject(item, `${newKey}[${index}]`));
        } else {
          result[`${newKey}[${index}]`] = item;
        }
      });
    } else if (typeof value === 'object' && value !== null) {
      // Recursively flatten nested objects
      Object.assign(result, flattenObject(value, newKey));
    } else {
      // Handle null and other values
      result[newKey] = value === null ? '' : value; // Replace null with an empty string
    }
  }
  return result;
};

/**
 * Export data as CSV
 * @param {Array<Object>} data - Array of objects to be converted to CSV
 * @param {string} filename - Name of the CSV file
 */
export const exportToCSV = (data, filename) => {
  const flattenedData = data.map(item => flattenObject(item));
  const csv = Papa.unparse(flattenedData);
  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  saveAs(blob, `${filename}.csv`);
};

/**
 * Export data as Excel
 * @param {Array<Object>} data - Array of objects to be converted to Excel
 * @param {string} filename - Name of the Excel file
 */
export const exportToExcel = (data, filename) => {
  const flattenedData = data.map(item => flattenObject(item));
  const ws = XLSX.utils.json_to_sheet(flattenedData);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  XLSX.writeFile(wb, `${filename}.xlsx`);
};

/**
 * Export data as PDF with custom page size
 * @param {Array<Object>} data - Array of objects to be converted to PDF
 * @param {string} filename - Name of the PDF file
 */
export const exportToPDF = (data, filename) => {
  // Define a custom page size (e.g., 1000mm x 2000mm)
  const doc = new jsPDF('p', 'mm', 'a3');

  // Check if data is empty
  if (data.length === 0) {
    console.error('No data to export');
    return;
  }

  // Extract columns and rows
  const columns = Object.keys(data[0]);
  const rows = data.map(item => columns.map(col => item[col] ? String(item[col]) : ''));

  // Define column widths dynamically based on content
  const columnWidths = columns.reduce((acc, col, index) => {
    // Calculate max width needed for each column
    const maxWidth = Math.max(...rows.map(row => doc.getTextWidth(row[index])));
    // Default width and cap it to avoid extremely wide columns
    acc[index] = Math.min(maxWidth + 10, 60);
    return acc;
  }, {});

  // Define table options
  const tableOptions = {
    head: [columns],
    body: rows,
    theme: 'striped',
    styles: {
      fontSize: 10,       // Font size for readability
      cellPadding: 5,     // Cell padding for spacing
      overflow: 'linebreak',  // Wrap text within cells
      halign: 'left',     // Align text to the left
      valign: 'top',      // Align text to the top
    },
    columnStyles: columnWidths,
    margin: { top: 30, bottom: 20, left: 15, right: 15 }, // Adjust margins
    pageBreak: 'auto',  // Automatically handle page breaks
    tableWidth: 'auto', // Ensure table width fits the page
  };

  // Generate the table
  doc.autoTable(tableOptions);

  // Save the PDF
  doc.save(`${filename}.pdf`);
};