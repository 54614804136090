import React, { Component } from 'react'

export default class CustomerProfile extends Component {
  render() {
    return (
     <>
         <table class="orders-table report-margin table-container">
  <thead>
    <tr>
      <th>S. No.</th>
      <th>Particular</th>
      <th>Remarks</th>
      <th>Added Instructions</th>
      <th>Further added Instructions</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
      <td></td>
    </tr>
  </tbody>
</table>
     </>
    )
  }
}
