// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.new-div {
    font-family: "Arial", sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .container_login {
    width: 100%;
    max-width: 400px;
  } 
  
  .login_card {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .login_head {
    text-align: center;
    color: #333;
  }
  
  .login_form {
    display: flex;
    flex-direction: column;
  }
  
  .login_input {
    padding: 10px;
    margin-bottom: 12px;
    border: 1px solid #ddd;
    border-radius: 4px;
    transition: border-color 0.3s ease-in-out;
    outline: none;
    color: #333;
  }
  
  .login_input:focus {
    border-color: #ff8c00; /* Warna orange saat focus */
  }
  
  .login_button {
    background-color: #ff8c00; /* Warna orange cerah */
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease-in-out;
  }
  
  .login_button:hover {
    background-color: #ff7f00; /* Warna orange sedikit lebih gelap saat hover */
  }
  `, "",{"version":3,"sources":["webpack://./src/components/Delivery/css/deliverylogin.css"],"names":[],"mappings":"AAAA;IACI,gCAAgC;IAChC,aAAa;IACb,mBAAmB;IACnB,uBAAuB;EACzB;;EAEA;IACE,WAAW;IACX,gBAAgB;EAClB;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,kBAAkB;IAClB,wCAAwC;EAC1C;;EAEA;IACE,kBAAkB;IAClB,WAAW;EACb;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,sBAAsB;IACtB,kBAAkB;IAClB,yCAAyC;IACzC,aAAa;IACb,WAAW;EACb;;EAEA;IACE,qBAAqB,EAAE,4BAA4B;EACrD;;EAEA;IACE,yBAAyB,EAAE,uBAAuB;IAClD,WAAW;IACX,aAAa;IACb,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,6CAA6C;EAC/C;;EAEA;IACE,yBAAyB,EAAE,gDAAgD;EAC7E","sourcesContent":[".new-div {\n    font-family: \"Arial\", sans-serif;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .container_login {\n    width: 100%;\n    max-width: 400px;\n  } \n  \n  .login_card {\n    background-color: #fff;\n    padding: 20px;\n    border-radius: 8px;\n    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);\n  }\n  \n  .login_head {\n    text-align: center;\n    color: #333;\n  }\n  \n  .login_form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .login_input {\n    padding: 10px;\n    margin-bottom: 12px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n    transition: border-color 0.3s ease-in-out;\n    outline: none;\n    color: #333;\n  }\n  \n  .login_input:focus {\n    border-color: #ff8c00; /* Warna orange saat focus */\n  }\n  \n  .login_button {\n    background-color: #ff8c00; /* Warna orange cerah */\n    color: #fff;\n    padding: 10px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s ease-in-out;\n  }\n  \n  .login_button:hover {\n    background-color: #ff7f00; /* Warna orange sedikit lebih gelap saat hover */\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
