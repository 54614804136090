import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import { Input, FormGroup, Button, Label } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
// import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import CircularProgressBar from './CircularProgressBar';

const common = require('../../utils/Common');

function PersonalView() {
    let navigate = useNavigate();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [userData, setUserData] = useState([]);
    const [completionPercentage, setCompletionPercentage] = useState(0);
    console.log(userData,"userdata")
    const options = [
        { value: 'Name', label: 'User Name' },
        { value: 'Email', label: 'User Email' },
        { value: 'Phone', label: 'User Phone' },
      ];
    useEffect(() => {
        getCategories(0);
    }, []);
    useEffect(() => {
      if (userData.length > 0) {
          calculateCompletionPercentage(userData[0]);
      }
  }, [userData]);
  const calculateCompletionPercentage = (userData) => {
    const fields = [
        'first_name',
        'mobile_phone',
        'email',
        'pan_no',
        'bank_account_no',
        'bank_name',
        'ifsc_code',
        'nominee_dob',
        'nominee_name'
    ];
    
    const filledFields = fields.filter(field => userData[field] && userData[field].trim() !== '');
    const percentage = Math.round((filledFields.length / fields.length) * 100);
    
    setCompletionPercentage(percentage);
};
    const getCategories = async () => {
        var parameters = {
            'action': 'read',
            'id': localStorage.getItem('userId'),
        };
        let response = await common.apiRequest('post', '/users/crudUserDetails', parameters);
        let data = (response.data && response.data.data) ? response.data.data : [];
        setUserData(data);
        if (data.length > 0) {
          calculateCompletionPercentage(data[0]);
      }
    }
    const addDetail = async () => {
        var parameters = {
          'action': 'update',
          'name': $('#name').val(),
          'email': $('#email').val(),
          'phone': $('#phone').val(),
          'bank_account_no': $('#bank_account_no').val(),
          'bank_name': $('#bank_name').val(),
          'ifsc_code': $('#ifsc_code').val(),
          'nominee_dob': $('#nominee_dob').val(),
          'nominee_name': $('#nominee_name').val()
        };
        let response = await common.apiRequest('post', '/users/crudUserDetails', parameters);
        if (response.status == 200) {
          if (response.data.code == "success") {
            common.alert(response.data.message);
            navigate('/my-account');
          } else {
            common.alert(response.data.error, 'error');
          }
        }
      }
    return (
        <React.Fragment>
            {/* <!-- bread crumb --> */}
            <div className="container-fluid">
                <div className="row">
                    <ol className="col-12 breadcrumb bg-transparent">
                        <li className="breadcrumb-item "><Link to="/" className="text-dark" id="bccap">Home</Link></li>
                        <li className="breadcrumb-item "><Link to="/my-account" className="text-dark" id="bccap">user-account</Link></li>
                        <li className="breadcrumb-item active"><Link to="#" className="text-dark" id="bccap">personal-info</Link></li>
                    </ol>
                </div>
            </div>
            {
                        userData.map((category) => {
                            return (
    <div className="container"> 
       <div className="row">
         
         <div id="form-header" className="col-12">
           <h1 id="title">User Details</h1>
         </div>
       </div> 
       <div className="container">
                <div className="row">
                    <div className="col-12 d-flex justify-content-center align-items-center mb-4">
                        <CircularProgressBar percentage={completionPercentage} />
                    </div>
                    <div className="col-12 text-center">
                        <h2>Profile Completion</h2>
                    </div>
                </div>
            </div>
      <div className="row">    
          <div id="form-tagline" className="col-md-4">
              <div className="form-tagline">
                  <i className="fa fa-user fa-5x"></i>
                  <h2>Hello {category.first_name}</h2>
                  <p id="description" className="lead">Thank you For join us</p>
              </div>
          </div>
         
		      <div id="form-content" className="col-md-8">
            
            <form id="survey-form" onsubmit="return false;"> 
                                
                <div className="row form-group">
                   <div className="col-sm-3">
                    <label id="name-label" className="control-label" for="name">Name:</label>
                   </div>
                  
                   <div className="input-group col-sm-9">
                        <span className="input-group-text" id="basic-addon-name"><i className="fa fa-user"></i></span>
                     <div className="form-control">{category.first_name}</div>
                   </div>
                </div>
                 
                <div className="form-group row">
                   <div className="col-sm-3">
                     <label id="email-label" className="control-label" for="email">Phone:</label>
                   </div>
                 
                   <div className="input-group col-sm-9">
                     <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon-mail"><i className="fa fa-phone"></i></span>
                     </div>
                     <div className="form-control">{category.mobile_phone}</div>
                   </div>
                </div>
                 
                <div className="form-group row">
                   <div className="col-sm-3">
                     <label id="number-label" className="control-label" for="email">Email:</label>
                   </div>
                 
                   <div className="input-group col-sm-9">
                     <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon-room"><i className="fa fa-envelope"></i></span>
                     </div>
                     <div className="form-control">{category.email}</div>
                   </div>
                 </div>
                 
                <div className="form-group row">    
                   <div className="col-sm-3">
                     <label className="control-label"for="visit-purpose">Pan:</label>
                   </div>
                   
                   <div className="input-group col-sm-9">
                     <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon-purpose"><i className="fa fa-info"></i></span>
                     </div>
                     
                     <div className="form-control">{category.pan_no}</div>
                     
                   </div>     
                </div>
                 
              <div className="form-group row"> 
                
                   <div className="col-sm-3">
                      <label className="control-label" for="visit-purpose">Bank Acount no:</label>
                   </div>

                   <div className="input-group col-sm-9">
                     <div className="input-group-prepend">
                        <span className="input-group-text" id="basic-addon-purpose"><i className="fa fa-address-book"></i></span>
                     </div>
                     
                     <div className="form-control">{category.bank_account_no}</div>
                     
                   </div>     

              </div>
              <div className="form-group row"> 
                
                <div className="col-sm-3">
                   <label className="control-label" for="visit-purpose">Bank name:</label>
                </div>

                <div className="input-group col-sm-9">
                  <div className="input-group-prepend">
                     <span className="input-group-text" id="basic-addon-purpose"><i className="fa fa-address-book"></i></span>
                  </div>
                  
                  <div className="form-control">{category.bank_name}</div>
                  
                </div>     

            </div>
            <div className="form-group row"> 
                
                <div className="col-sm-3">
                   <label className="control-label" for="visit-purpose">Bank ifsc_code:</label>
                </div>

                <div className="input-group col-sm-9">
                  <div className="input-group-prepend">
                     <span className="input-group-text" id="basic-addon-purpose"><i className="fa fa-phone"></i></span>
                  </div>
                  
                  <div className="form-control">{category.ifsc_code}</div>
                  
                </div>     

            </div>
            <div className="form-group row"> 
                
                <div className="col-sm-3">
                   <label className="control-label" for="visit-purpose">nominee_dob:</label>
                </div>

                <div className="input-group col-sm-9">
                  <div className="input-group-prepend">
                     <span className="input-group-text" id="basic-addon-purpose"><i className="fa fa-info"></i></span>
                  </div>
                  
                  <div className="form-control">{category.nominee_dob}</div>
                  
                </div>     

            </div>
            <div className="form-group row"> 
                
                <div className="col-sm-3">
                   <label className="control-label" for="visit-purpose">nominee_name:</label>
                </div>

                <div className="input-group col-sm-9">
                  <div className="input-group-prepend">
                     <span className="input-group-text" id="basic-addon-purpose"><i className="fa fa-user"></i></span>
                  </div>
                  
                  <div className="form-control">{category.nominee_name}</div>
                  
                </div>     

            </div>
           
              <hr/>  
                 
                
              <div className="form-group row">
                <div className="col-sm-12 submit-buttons">
                  <Button  onClick={handleShow} className='submit'>Change user details</Button>
                </div>
              </div>
                                    <Modal show={show} onHide={handleClose} >
                                        <Modal.Header closeButton >
                                            <Modal.Title>select here to change</Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new Name</Label>
                                                <Input
                                                    type='text' id="name" defaultValue={category.first_name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new email</Label>
                                                <Input
                                                    type='text' id="email" defaultValue={category.email}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter a new phone</Label>
                                                <Input
                                                    type='text' id="phone" defaultValue={category.mobile_phone}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter new Address</Label>
                                                <Input
                                                    type='text' id="bank_account_no" defaultValue={category.bank_account_no}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter new bank name</Label>
                                                <Input
                                                    type='text' id="bank_name" defaultValue={category.bank_name}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter new ifsc</Label>
                                                <Input
                                                    type='text' id="ifsc_code" defaultValue={category.ifsc_code}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter new nominee dob</Label>
                                                <Input
                                                    type='text' id="nominee_dob" defaultValue={category.nominee_dob}
                                                />
                                            </FormGroup>
                                            <FormGroup>
                                                <Label for="newEntry">Enter new nominee name</Label>
                                                <Input
                                                    type='text' id="nominee_name" defaultValue={category.nominee_name}
                                                />
                                            </FormGroup>
                                        </Modal.Body>
                                        <Modal.Footer>
                                            <Link to="/ChangePassword" className='submit' >
                                                Change Password
                                            </Link>
                                             <Button className='submit'  onClick={addDetail}>
                                                Submit
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                               
            </form> 

		    </div>
               
      </div> 
    </div>
       )
    })

}

        </React.Fragment>
    )
}
export default PersonalView;