import React from 'react'
import { Button,Form,Input} from 'reactstrap';
import { Link } from 'react-router-dom';
 function FileExport() {
    return (
       <React.Fragment>
                <div className='file'>
                    <div className='file-head'>
                    <h1>hello</h1>
                    </div>
                    
                </div>

       </React.Fragment>
    )
}

export default FileExport;