import "./content.css";
export default function FeaturedInfo() {
  return (
    <div className="featured">
      <div className="featuredItem">
      <img src="assets/img/revenue.png" className="contenticons"/>
        <span className="featuredTitle">payable amount</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney"><img src="assets/img/money.png" className="contenticons"/> </span>
          <span className="featuredMoneyRate">
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
      <div className="featuredItem">
      <img src="assets/img/sales.png" className="contenticons"/>
        <span className="featuredTitle">Sales</span>
        <div className="featuredMoneyContainer">
          <span className="featuredMoney"><img src="assets/img/money.png" className="contenticons"/> </span>
          <span className="featuredMoneyRate">
          </span>
        </div>
        <span className="featuredSub">Compared to last month</span>
      </div>
    </div>
  );
}