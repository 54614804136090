import React, { useEffect } from 'react';

function NoPage() {
	useEffect(() => {
		document.title = "404"
	}, []);

	return <h2> 404 </h2>
}

export default NoPage;