import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, Modal, ModalBody, ModalHeader, Input, FormGroup, Button, Label, Form } from 'reactstrap';
import './userA.css';
import { useCSVDownloader } from 'react-papaparse';
import moment from 'moment';
import $ from 'jquery';

const common = require('../../utils/Common');

const UserServiceAvliable = () => {
  const [userData, setUserData] = useState([]);
  const { CSVDownloader, Type } = useCSVDownloader();

  useEffect(() => {
    getCategories();
  }, []);

  const getCategories = async () => {
    var parameters = {
      action: 'read',
      fromDate: $('#Date-from').val(),
      toDate: $('#Date-to').val(),
      userId: localStorage.getItem('userId'),
    };
    let response = await common.apiRequest('post', '/users/UserServiceDetails', parameters);
    let data = (response.data && response.data.data) ? response.data.data : [];
    setUserData(data);
  };

  const downloadData = userData.map((data, index) => ({
    S_No: index + 1,
    Date: data.created_at,
    Invoice_No: data.invoice?.invoice_no || "N/A",
    Seller_Name: data.user?.first_name || "N/A",
    taxable_value: data.order_items[0]?.seller_service?.taxable_value || "N/A",
    Taxable_Amount: data.order_items[0]?.seller_service?.price || "N/A",
    Delivery_status: data.order_status || "N/A",
    Feedback: data.order_items[0]?.feedbacks[0]?.user_feedback || "N/A",
    Feedback_Reply: data.order_items[0]?.feedbacks[0]?.feedback_replys[0]?.feedback_reply || "N/A",
    Query: data.order_items[0]?.query?.query || "N/A",
    Query_Reply: data.order_items[0]?.query?.query_reply || "N/A"
  }));

  return (
    <>
      <div className="row">
        <div className="col-md-10">
          <br />
          <h2><center>Offline Orders</center></h2>
          <br />
        </div>
        <CSVDownloader
          type={Type.Button}
          filename={'offline_orders'}
          bom={true}
          config={{}}
          data={downloadData}
        >
          <Button color="primary">Download CSV</Button>
        </CSVDownloader>
      </div>
      <div className='row tables'>
        <div className='col-md-12 '>
          <div className="col-md-12">
            <Form className='row'>
              <FormGroup className='col-md-4 row'>
                <Label className="col-md-2 col-form-label" for="Date-from">From:</Label>
                <input type='date' defaultValue={moment().startOf('month').format('YYYY-MM-DD')} onChange={getCategories} className="form-control col-md-5" id="Date-from" name="Date-from"></input>
              </FormGroup>
              <FormGroup className='col-md-4 row'>
                <Label className="col-md-2 col-form-label" for="Date-to">To:</Label>
                <input type='date' defaultValue={moment().endOf('month').format('YYYY-MM-DD')} onChange={getCategories} className="form-control col-md-5" id="Date-to" name="Date-to"></input>
              </FormGroup>
            </Form>
          </div>
          <div className='table-responsive table-container'>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Date</th>
                  <th>Invoice No</th>
                  <th>Service</th>
                  <th>Seller Name</th>
                  <th>Service Taxable Amount</th>
                  <th>Service Total Amount</th>
                  <th>Service status</th>
                  <th>Feedback</th>
                  <th>Feedback reply</th>
                  <th>Query</th>
                  <th>Query reply</th>
                </tr>
              </thead>
              <tbody>
                {
                  userData.flatMap((data, index) =>
                    data.order_items.map((item, itemIndex) => (
                      <tr key={`${index}-${itemIndex}`}>
                        <td>{index + 1}</td>
                        <td>{data.created_at}</td>
                        <td>{data.invoice?.invoice_no || "N/A"}</td>
                        <td>{item.seller_service?.service_name || "N/A"}</td>
                        <td>{data.user?.first_name || "N/A"}</td>
                        <td>{item.seller_service?.taxable_value || "N/A"}</td>
                        <td>{item.seller_service?.price || "N/A"}</td>
                        <td>{item.order_status || "N/A"}</td>
                        <td>{item.feedbacks?.[0]?.user_feedback || "N/A"}</td>
                        <td>{item.feedbacks?.[0]?.feedback_replys?.[0]?.feedback_reply || "N/A"}</td>
                        <td>{item.querys?.[0]?.user_query || "N/A"}</td>
                        <td>{item.querys?.[0]?.querys_replys?.[0]?.query_reply || "N/A"}</td>
                      </tr>
                    ))
                  )
                }
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserServiceAvliable;
